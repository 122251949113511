import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useContext,
} from 'react';
import {
    Row,
    Col,
    Card,
    Collapse,
    Button,
    Form,
    Badge,
    ButtonGroup,
    Dropdown,
    Image,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce, lowerCase } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input/input';
import HighlightedText from 'components/HighlightedText';
import PageHeader from 'components/PageHeader';
import SmartForm from 'components/SmartForm/SmartForm';
import   SmartTable   from 'components/SmartTable/SmartTable';
import { yup } from 'lib/yup';

import Constants from '../../../constants';
import useConfirmModal from 'hooks/useConfirmModal';
import useFormModal from 'hooks/useFormModal';
import useToast from 'hooks/useToast';
import { AuthContext } from '../../../context/AuthContext';

 
const statusOptionLabel = ({ color, labelKey }) => (
    <div className="d-flex align-items-center">
        <span className={`legend-indicator bg-${color}`} />
        <FormattedMessage id={`app.common.${labelKey}`} />
    </div>
);

const typeOptionLabel = ({ color, labelKey }) => (
    <div className="d-flex align-items-center">
        <span className={`legend-indicator bg-${color}`} />
        <FormattedMessage id={`app.common.${labelKey}`} />
    </div>
);

function Users() {
 
     
    const tableRef = useRef();
    const filterFormRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filtersIsVisible, setFiltersIsVisible] = useState(false);
    const [filters, setFilters] = useState({});
    const { formatMessage } = useIntl();
    const { user, update, updateRole } = useContext(AuthContext);

    const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
    const { form } = useFormModal();
    const { showToast } = useToast();

    const onAddNew = async () => {
        const fields = [
            {
                cols: [
                    {
                        key: 'email',
                        schema: yup.string().email().required(),
                    },
                ],
            },
        ];

        const isSuccess = await form({
            title: 'newUser',
            confirmLabel: 'create',
            requestUrl: '/users/add',
            size: 'md',
            fields,
        });

        if (isSuccess) {
            tableRef.current.reload();

            showToast({
                type: 'success',
                autohide: true,
                title: <FormattedMessage id="app.common.success" />,
                message: (
                    <FormattedMessage
                        id="app.common.theXWasCreatedSuccessfully"
                        values={{
                            x: lowerCase(formatMessage({ id: 'app.common.user' })),
                        }}
                    />
                ),
            });
        }
    };

    const onEditRow = useCallback(
        async (item) => {
            const fields = [
                {
                    cols: [
                        {
                            key: 'photo',
                            apiKey: 'pp',
                            type: 'image',
                            options: { controller: { variant: 'circle' } },
                            schema: yup.mixed(),
                        },
                    ],
                },
                {
                    cols: [
                        {
                            key: 'firstname',
                            apiKey: 'name',
                            schema: yup.string().nullable().required(),
                        },
                        {
                            key: 'lastname',
                            apiKey: 'surname',
                            schema: yup.string().nullable().required(),
                        },
                    ],
                },
                {
                    cols: [
                        {
                            key: 'email',
                            apiKey: 'email',
                            schema: yup.string().email().required(),
                        },
                        {
                            key: 'userType',
                            apiKey: 'user_type',
                            type: 'react-select',
                            options: {
                                controller: {
                                    props: {
                                        options: Object.values(Constants.User.Types),
                                        getOptionValue: (option) => `${option.id}`,
                                        formatOptionLabel: typeOptionLabel,
                                        isClearable: false,
                                        isMulti: false,
                                        isSearchable: false,
                                    },
                                },
                            },
                            schema: yup.string().required(),
                        },
                    ],
                },
                {
                    cols: [
                        {
                            key: 'phone',
                            apiKey: 'phone',
                            type: 'phone',
                            schema: yup.string().nullable(),
                        },
                        {
                            key: 'dateOfBirth',
                            apiKey: 'dob',
                            type: 'flatpickr',
                            options: {
                                controller: {
                                    formatter: (value) =>
                                        moment(value).format(Constants.DateFormats.API),
                                },
                            },
                            schema: yup.string().nullable(),
                        },
                    ],
                },
            ];

            const {
                id,
                pp: photo,
                name: firstname,
                surname: lastname,
                email,
                phone,
                dob: dateOfBirth,
                user_type: userType,
            } = item;
            const defaultValues = {
                photo,
                firstname,
                lastname,
                email,
                phone,
                dateOfBirth,
                userType: `${userType}`,
            };

            const data = await form({
                title: 'edit',
                confirmLabel: 'save',
                requestUrl: '/users/update',
                requestParams: { id },
                size: 'lg',
                fields,
                defaultValues,
            });

            if (data) {
                tableRef.current.reload();

                if (user.id === data.Result.id) {
                    const payload = {
                        user: data.Result,
                    };
                    update(payload);

                    const payloadRole = {
                        role:
                            Constants.User.Types[data.Result.user_type]?.id ||
                            Constants.User.Types[16].id,
                    };

                    updateRole(payloadRole);
                }

                showToast({
                    type: 'success',
                    autohide: true,
                    title: <FormattedMessage id="app.common.success" />,
                    message: (
                        <FormattedMessage
                            id="app.common.theXWasUpdatededSuccessfully"
                            values={{
                                x: lowerCase(formatMessage({ id: 'app.common.user' })),
                            }}
                        />
                    ),
                });
            }
        },
        [form, formatMessage, showToast, user.id, update, updateRole]
    );

    const onDeleteRow = useCallback(
        async (item) => {
            const isSuccess = await confirm({
                message: (
                    <FormattedMessage
                        id="app.common.areYouSureToDeleteTheXY"
                        values={{
                            x: item.email,
                            y: lowerCase(formatMessage({ id: 'app.common.user' })),
                        }}
                    />
                ),
                requestUrl: '/users/delete',
                requestParams: { id: item.id },
            });
            if (isSuccess) {
                tableRef.current.reload();

                showToast({
                    type: 'success',
                    autohide: true,
                    title: <FormattedMessage id="app.common.success" />,
                    message: (
                        <FormattedMessage
                            id="app.common.theXWasDeletedSuccessfully"
                            values={{
                                x: lowerCase(formatMessage({ id: 'app.common.user' })),
                            }}
                        />
                    ),
                });
            }
        },
        [confirm, formatMessage, showToast]
    );

    const columns = [
        {
            Header: <FormattedMessage id="app.common.name" />,
            accessor: 'pp',
            Cell: useCallback(
                ({
                    cell: {
                        value,
                        row: { original },
                    },
                }) => (
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-circle">

                        </div>
                        <div className="ms-3">
                            <div className="d-flex align-items-center">
                                <div className="d-block h5 text-inherit mb-0">
                                    <HighlightedText
                                        text={`${original.name || ''} ${original.surname || ''}`}
                                        searchText={filters?.search || null}
                                    />
                                </div>
                                {original.user_type === Constants.User.Types[15].id && (
                                    <small>
                                        <Badge bg={Constants.User.Types[15].color} className="ms-1">
                                            <FormattedMessage
                                                id={`app.common.${Constants.User.Types[15].labelKey}`}
                                            />
                                        </Badge>
                                    </small>
                                )}
                            </div>
                            <span className="d-block fs-5 text-body">{original.email}</span>
                        </div>
                    </div>
                ),
                [filters?.search]
            ),
        },
        {
            Header: <FormattedMessage id="app.common.phone" />,
            accessor: 'phone',
            Cell: useCallback(
                ({ cell: { value } }) => (
                    <PhoneInput
                        value={value}
                        readOnly
                        onChange={() => { }}
                        className="bg-transparent border-0 text-body"
                    />
                ),
                []
            ),
        },
        {
            Header: <FormattedMessage id="app.common.dateOfBirth" />,
            accessor: 'dob',
            Cell: useCallback(
                ({ cell: { value } }) =>
                    moment(value).format(Constants.DateFormats.APP.Moment.Common),
                []
            ),
        },
        {
            Header: <FormattedMessage id="app.common.createdDate" />,
            accessor: 'created_date',
            Cell: useCallback(
                ({ cell: { value } }) =>
                    moment(value).format(Constants.DateFormats.APP.Moment.Common),
                []
            ),
        },
        {
            Header: <FormattedMessage id="app.common.status" />,
            accessor: 'status',
            Cell: useCallback(
                ({ cell: { value } }) => (
                    <>
                        <span
                            className={`legend-indicator bg-${Constants.User.Status[value].color}`}
                        />
                        <FormattedMessage
                            id={`app.common.${Constants.User.Status[value].labelKey}`}
                        />
                    </>
                ),
                []
            ),
        },
        {
            accessor: 'id',
            Cell: useCallback(
                ({
                    cell: {
                        row: { original },
                    },
                }) => (
                    <Dropdown align="end">
                        <ButtonGroup>
                            <Button
                                variant="white"
                                size="sm"
                                onClick={() => {
                                    onEditRow(original);
                                }}
                            >
                                <i className="bi-pencil me-1" />
                                <FormattedMessage id="app.common.edit" />
                            </Button>
                            <ButtonGroup>
                                <Dropdown.Toggle
                                    variant="white"
                                    size="sm"
                                    className="btn-icon dropdown-toggle-empty"
                                />
                            </ButtonGroup>
                        </ButtonGroup>
                        <Dropdown.Menu className="m-0" renderOnMount>
                            <Dropdown.Header>
                                <FormattedMessage id="app.common.actions" />
                            </Dropdown.Header>

                            <Dropdown.Item
                                onClick={() => {
                                    onDeleteRow(original);
                                }}
                            >
                                <i className="bi-trash dropdown-item-icon text-danger" />
                                <span className="text-danger">
                                    <FormattedMessage id="app.common.delete" />
                                </span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ),
                [onEditRow, onDeleteRow]
            ),
        },
    ];

    const filterFields = useMemo(
        () => [
            {
                cols: [
                    {
                        key: 'userType',
                        apiKey: 'user_type',
                        type: 'react-select',
                        options: {
                            controller: {
                                props: {
                                    options: Object.values(Constants.User.Types),
                                    getOptionValue: (option) => `${option.id}`,
                                    formatOptionLabel: typeOptionLabel,
                                    isClearable: true,
                                    isMulti: false,
                                    isSearchable: false,
                                },
                            },
                        },
                        schema: yup.string().nullable(),
                    },
                    {
                        key: 'status',
                        type: 'react-select',
                        options: {
                            controller: {
                                props: {
                                    options: Object.values(Constants.User.Status),
                                    getOptionValue: (option) => `${option.id}`,
                                    formatOptionLabel: statusOptionLabel,
                                    isClearable: true,
                                    isMulti: false,
                                    isSearchable: false,
                                },
                            },
                        },
                        schema: yup.string().nullable(),
                    },
                ],
            },
        ],
        []
    );

    const parseSearchParams = useCallback(() => {
        const params = {};
        searchParams.forEach((value, key) => {
            let isArray = false;
            filterFields.forEach((row) => {
                row.cols.forEach((col) => {
                    if (
                        col.key === key &&
                        (col.options?.controller?.props?.isMulti || col.key === 'dateRange')
                    ) {
                        isArray = true;
                    }
                });
            });

            if (!params[key]) {
                params[key] = !isArray ? value : [value];
            } else {
                params[key] = [
                    ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
                    value,
                ];
            }
        });
        return params;
    }, [searchParams, filterFields]);

    const handleSearch = (text) => {
        const params = parseSearchParams(true);
        if (text) {
            params.search = text;
        } else {
            delete params.search;
        }
        setSearchParams(params);
    };

    const onSearch = debounce((text) => {
        handleSearch(text);
    }, 100);

    useEffect(() => {
        const params = parseSearchParams(true);
        const nParams = params.search ? { search: params.search } : {};
        filterFields.forEach((row) => {
            row.cols.forEach((col) => {
                if (params[col.key]) {
                    nParams[col.apiKey || col.key] = params[col.key];
                }
            });
        });
        setFilters(nParams);
    }, [parseSearchParams, filterFields]);

    useEffect(() => {
        const params = parseSearchParams();
        if (params.search) {
            delete params.search;
        }
        filterFormRef.current.reset(params);
    }, [parseSearchParams]);

    return (
        <div className="content container">
            <PageHeader
                className="d-block"
                title="users"
                breadcrumbData={{
                    current: 'users',
                }}
            >
                <Button onClick={onAddNew}>
                    <i className="bi-plus me-1" />
                    <FormattedMessage id="app.common.newUser" />
                </Button>
            </PageHeader>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="card-header-content-md-between">
                            <div className="mb-2 mb-md-0">
                                <div className="input-group input-group-merge input-group-borderless">
                                    <div className="input-group-prepend input-group-text">
                                        <i className="bi-search" />
                                    </div>
                                    <Form.Control
                                        type="search"
                                        placeholder={formatMessage({
                                            id: 'app.common.searchUsers',
                                        })}
                                        defaultValue={parseSearchParams().search || ''}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                onSearch(e.target.value);
                                            } else {
                                                handleSearch(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                                <div id="datatableCounterInfo" style={{ display: 'none' }}>
                                    <div className="d-flex align-items-center">
                                        <span className="fs-5 me-3">
                                            <span id="datatableCounter">0</span> Selected
                                        </span>

                                        <a className="btn btn-outline-danger btn-sm" href="#!">
                                            <i className="bi-trash" /> Delete
                                        </a>
                                    </div>
                                </div>

                                <Button
                                    variant="white"
                                    size="sm"
                                    className="dropdown-toggle"
                                    onClick={() => {
                                        setFiltersIsVisible(!filtersIsVisible);
                                    }}
                                >
                                    <i className="bi-filter me-1" />
                                    <FormattedMessage id="app.common.filters" />
                                    {Object.keys(filters).filter((key) => key !== 'search')
                                        .length > 0 && (
                                            <Badge
                                                bg="soft-dark"
                                                className="text-dark rounded-circle ms-1"
                                            >
                                                {
                                                    Object.keys(filters).filter((key) => key !== 'search')
                                                        .length
                                                }
                                            </Badge>
                                        )}
                                </Button>
                            </div>
                        </Card.Header>

                        <Collapse in={filtersIsVisible}>
                            <div id="filter-collapse">
                                <Card.Body>
                                    <SmartForm
                                        ref={filterFormRef}
                                        fields={filterFields}
                                        submitButtonText="applyFilters"
                                        clearButtonText="clearFilters"
                                        isFilterForm
                                        disableApiKey
                                        onSubmit={(formData) => {
                                            const params = {};
                                            if (formData) {
                                                Object.keys(formData).forEach((key) => {
                                                    if (formData[key]) {
                                                        params[key] = formData[key];
                                                    }
                                                });
                                            }

                                            if (parseSearchParams().search) {
                                                params.search = parseSearchParams().search;
                                            }

                                            setSearchParams(params);
                                        }}
                                    />
                                </Card.Body>
                            </div>
                        </Collapse>

                        <SmartTable
                            ref={tableRef}
                            columns={columns}
                            requestUrl="/users/List"
                            filters={filters}
                            hoverable
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Users;
