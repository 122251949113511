/* eslint-disable no-bitwise */

export const getRGBAColorFromHex = (hex, alpha) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(', ')}, ${
        alpha || 1
      })`;
    }
    throw new Error('Bad Hex');
  };
  
  export const getHexColorFromRGBA = (color) => {
    if (/^rgb/.test(color)) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
      let hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0], 10) << 16) +
        (parseInt(rgba[1], 10) << 8) +
        parseInt(rgba[2], 10)
      )
        .toString(16)
        .slice(1)}`;
  
      // added alpha param if exists
      if (rgba[4]) {
        const alpha = Math.round(0o1 * 255);
        const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
        hex += hexAlpha;
      }
  
      return hex;
    }
    return color;
  };
  
  export const getShadeHexColorFromHex = (color, amount) => {
    let nColor = color;
    let usePound = false;
  
    if (nColor[0] === '#') {
      nColor = nColor.slice(1);
      usePound = true;
    }
  
    const num = parseInt(nColor, 16);
  
    let r = (num >> 16) + amount;
  
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
  
    let b = ((num >> 8) & 0x00ff) + amount;
  
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
  
    let g = (num & 0x0000ff) + amount;
  
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
  
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  };
  