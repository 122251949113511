export default class MemoryManager {
    map = new Map();
  
    get(key) {
      return this.map.get(key);
    }
  
    set(key, value) {
      return this.map.set(key, value);
    }
  
    clear() {
      this.map.clear();
    }
  }
  