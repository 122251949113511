/* eslint-disable eqeqeq */
//satış için
import {
	useStoreRoot,
	useStoreState,
	useStoreStateSetPromise,
	useStoreStateSetValue,
	useStoreStateValue,
	useStoreValue
} from "@scena/react-store";
import LanguageSwitcherPositionEnd from "components/Theme/LayoutComponents/LanguageSwitcherPositionEnd";
import { isNumber } from "lodash";
import PropTypes from "prop-types";
import {
	createRef,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import {
	Button,
	ButtonGroup,
	Modal,
	Offcanvas,
	OverlayTrigger,
	Tooltip
} from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { SceneItem } from "scenejs";
import SimpleBar from "simplebar-react";
import Viewport from "./editorComponents/Viewport";
import {
	checkInput,
	getParentScenaElement,
	isArrayEquals,
	keyChecker,
	prefix
} from "./utils/utils";

import ActionManager from "./managers/ActionManager.jsx";
import HistoryManager from "./managers/HistoryManager.jsx";
import KeyManager from "./managers/KeyManager.jsx";
import LayerManager, { createLayer } from "./managers/LayerManager.jsx";
import MemoryManager from "./managers/MemoryManager.jsx";

// import { TestIcon, AltTestIcon } from './iconsForMap';

import { $alt, $meta, $shift, $space } from "./stores/keys.jsx";
import {
	$actionManager,
	$editor,
	$historyManager,
	$horizontalGuides,
	$iframeUrl,
	$infiniteViewer,
	$keyManager,
	$layerManager,
	$layers,
	$memoryManager,
	$moveable,
	$searchResults,
	$searchResultsIndex,
	$selectedBoothAreaData,
	$selectedLayers,
	$selecto,
	$showSellModal,
	$unitType,
	$verticalGuides,
	$zoom
} from "./stores/stores";

import { ThemeContext } from "../../../../context/ThemeContext";
import Carpet from "./defaultComponents/Carpet";
import { GuidesManager } from "./editorComponents/GuidesManager.jsx";
import { InfiniteViewerManager } from "./editorComponents/InfiniteViewerManager";
import { MoveableManager } from "./editorComponents/MoveableManager.jsx";
import { SelectoManager } from "./editorComponents/SelectoManager";
import { readFiles } from "./managers/FileManager.jsx";
import { registerHistoryTypes } from "./managers/histories/histories.jsx";

import LeftToolbar from "./uis/LeftToolbar";
import TopToolbar from "./uis/TopToolBar";

import {
	ALLOW_DROP_FILES,
	BOOTH_STATUS_TYPES,
	DEFAULT_BLOCK_SPACE_CHILD_STYLE,
	DEFAULT_BLOCK_SPACE_SIZE,
	DEFAULT_BLOCK_SPACE_STYLE,
	DEFAULT_BOOTH_CHILD_STYLE,
	DEFAULT_BOOTH_SIZE,
	DEFAULT_BOOTH_STYLE,
	DEFAULT_CARPET_CHILD_STYLE,
	DEFAULT_CARPET_SIZE,
	DEFAULT_CARPET_STYLE,
	DEFAULT_CIRCLE_STYLE,
	DEFAULT_ICON_SIZE,
	DEFAULT_ICON_STYLE,
	DEFAULT_OTHER_SPACE_CHILD_STYLE,
	DEFAULT_OTHER_SPACE_SIZE,
	DEFAULT_OTHER_SPACE_STYLE,
	DEFAULT_RECTANGLE_STYLE,
	DEFAULT_TEXT_EMPTY_VALUE,
	DEFAULT_TEXT_SIZE,
	DEFAULT_TEXT_STYLE,
	DEFAULT_VIEWPORT_HEIGHT,
	DEFAULT_VIEWPORT_WIDTH,
	LAYER_TYPES,
	RULER_SIZE,
	ZOOM_MAX,
	ZOOM_MIN,
	ZOOM_STEP
} from "./consts";
import BlockSpace from "./defaultComponents/BlockSpace";
import Booth from "./defaultComponents/Booth";
import Icon from "./defaultComponents/Icon";
import OtherSpace from "./defaultComponents/OtherSpace";
import Text from "./defaultComponents/Text";
import PreviewLeftToolbar from "./uis/PreviewLeftToolbar";
import RightToolbar from "./uis/RightToolbar";

import useBlockerConfirm from "hooks/useAxiosConfirm";
import useConfirmModal from "hooks/useConfirmModal";
import useToast from "hooks/useToast";
import { useParams } from "react-router-dom";

function EditorManager({
  width,
  height,
  background,
  data: initialData,
  onSave,
  onUnlock,
  isPreview,
  isRestricted,
  isNew
}) {
  const zoomScale = Math.max(DEFAULT_VIEWPORT_WIDTH / width, 10);
  const rulerSize = isPreview ? 0 : RULER_SIZE;

  const { isMobilVersion } = useParams();

  const [zoomStepper, setZoomStepper] = useState(1);
  const { showToast } = useToast();

  const { theme } = useContext(ThemeContext);
  const { formatMessage } = useIntl();

  const root = useStoreRoot();
  const editorRef = useRef();

  const historyManager = useMemo(() => new HistoryManager(editorRef), []);
  const actionManager = useMemo(() => new ActionManager(), []);
  const memoryManager = useMemo(() => new MemoryManager(), []);
  const layerManager = useMemo(() => new LayerManager(), []);
  const keyManager = useMemo(
    () => new KeyManager(root, actionManager),
    [root, actionManager]
  );
  function closeIframe() {
    setiframe("");
    showSellModalSetter(false);
  }
  window.addEventListener("message", (event) => {
    if (event.data === "kardestamam") {
      closeIframe();
      showToast({
        title: "İlk Sözleşme Oluşturuldu",
        message: "Kardeş Sözleşmesi için Firmayı Seçin",
        autohide: false,
        type: "success",
      });
    }
    if (event.data === "reload") {
      closeIframe();
    }
  });

  const horizontalGuidesRef = useRef();
  const verticalGuidesRef = useRef();
  const infiniteViewerRef = useRef();
  const moveableRef = useRef();
  const selectoRef = useRef();
  const viewportRef = useRef();
  const editorElementRef = useRef();

  // declare global store
  useStoreValue($historyManager, historyManager);
  useStoreValue($actionManager, actionManager);
  useStoreValue($memoryManager, memoryManager);
  useStoreValue($layerManager, layerManager);
  useStoreValue($keyManager, keyManager);

  // declare global ui component
  useStoreValue($moveable, moveableRef);
  useStoreValue($selecto, selectoRef);
  useStoreValue($infiniteViewer, infiniteViewerRef);
  useStoreValue($horizontalGuides, horizontalGuidesRef);
  useStoreValue($verticalGuides, verticalGuidesRef);
  useStoreValue($editor, editorRef);

  // res
  const [isDirty, setIsDirty] = useState(false);
  const [showBackground, setShowBackground] = useState(true);
  const [showGrid, setShowGrid] = useState(true);
  const [layoutMargin, setLayoutMargin] = useState(0);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const [previewSelectedItem, setPreviewSelectedItem] = useState();

  const [previewSearchValue, setPreviewSearchValue] = useState("");

  const handleCloseLeft = () => setShowLeft(false);
  const handleCloseRight = () => setShowRight(false);

  // const zoomStore = useStoreValue($zoom);
  const [zoom, setZoom] = useStoreState($zoom);
  const layerStore = useStoreValue($layers);
  const setSearchResults = useStoreStateSetValue($searchResults);
  const setSearchResultsIndex = useStoreStateSetValue($searchResultsIndex);
  const showSellModal = useStoreStateValue($showSellModal);
  const showSellModalSetter = useStoreStateSetValue($showSellModal);
  const iframe = useStoreValue($iframeUrl);
  const setiframe = useStoreStateSetValue($iframeUrl);

  const setSelectedBoothAreaData = useStoreStateSetValue(
    $selectedBoothAreaData
  );

  const unitType = useStoreStateValue($unitType);

  const { isActive, onConfirm, resetConfirmation } = useBlockerConfirm(isDirty);
  const { confirm } = useConfirmModal({ confirmVariant: "danger" });

  const closeModal = () => {
    const userConfirmed = window.confirm("Kapatmak İstediğinize Emin misiniz?");

    if (userConfirmed) {
      setiframe("");
      showSellModalSetter(false);
    }
  };

  useEffect(() => {
    const showConfirm = async () => {
      const isSuccess = await confirm({
        title: "unsavedChanges",
        confirmLabel: "leavePage",
        message: <FormattedMessage id="app.common.unsavedChangesMessage" />,
      });
      if (isSuccess) {
        onConfirm();
      } else {
        resetConfirmation();
      }
    };
    if (isActive) {
      showConfirm();
    }
  }, [isActive, confirm, onConfirm, resetConfirmation]);

  const getJSXComponent = (type) => {
    let component = Booth;
    switch (type) {
      case LAYER_TYPES.booth:
        component = Booth;
        break;

      case LAYER_TYPES.carpet:
        component = Carpet;
        break;

      case LAYER_TYPES.otherSpace:
        component = OtherSpace;
        break;

      case LAYER_TYPES.blockSpace:
        component = BlockSpace;
        break;

      case LAYER_TYPES.icon:
        component = Icon;
        break;

      case LAYER_TYPES.rectangle:
      case LAYER_TYPES.circle:
        component = "div";
        break;

      case LAYER_TYPES.text:
        component = Text;
        break;

      default:
        component = Booth;
        break;
    }
    return component;
  };

  const setInitialLayers = useCallback(() => {
    if (initialData?.output?.length > 0) {
      const nLayers = initialData.output.map(
        ({ id, fpeData, innerText, title, type, lock, jsx, css }) => {
          const Component = getJSXComponent(type);
          const props = { ...jsx.props, style: css };
          let JSX;
          switch (type) {
            case "booth":
              JSX = (
                <Component
                  {...props}
                  isPreview={isPreview}
                  fpeData={fpeData}
                  onClick={
                    isPreview
                      ? () => {
                          const data = {
                            title,
                            mapId: initialData?.id,
                            exhibitor: fpeData?.exhibitors?.[0],
                            fpeData: fpeData,
                            layers: layerManager.layers,
                            additionalData: {
                              title,
                              width: jsx?.props?.style?.width,
                              height: jsx?.props?.style?.height,
                            },
                          };

                          setSelectedBoothAreaData(data);

                          if (fpeData?.exhibitors?.length === 1) {
                            setPreviewSearchValue("");
                            setPreviewSelectedItem({
                              elementId: id,
                              mapId: initialData?.id,
                              exhibitor: fpeData?.exhibitors?.[0],
                              layers: layerManager.layers,
                              additionalData: {
                                title,
                                width: jsx?.props?.style?.width,
                                height: jsx?.props?.style?.height,
                              },
                            });
                          } else if (fpeData?.exhibitors?.length > 1) {
                            setPreviewSelectedItem(null);
                            setPreviewSearchValue(`${title}`);
                          } else {
                            setPreviewSearchValue("");
                            setPreviewSelectedItem({
                              mapId: initialData?.id,
                              exhibitor: null,
                              layers: layerManager.layers,
                              additionalData: {
                                title,
                                width: jsx?.props?.style?.width,
                                height: jsx?.props?.style?.height,
                              },
                            });
                          }
                        }
                      : undefined
                  }
                />
              );
              break;
            case "text":
              JSX = (
                <Component
                  {...props}
                  innerText={innerText}
                  contentEditable={!isPreview}
                />
              );
              break;

            default:
              JSX = <Component {...props} />;
              break;
          }
          return {
            id,
            fpeData,
            innerText,
            title,
            type,
            lock,
            jsx: JSX,
            scope: [],
            item: new SceneItem(),
            ref: createRef(),
          };
        }
      );

      layerManager.setLayers(nLayers || []);
      layerStore.update(nLayers || []);
    }
  }, [
    layerManager,
    layerStore,
    initialData,
    isPreview,
    setSelectedBoothAreaData,
  ]);

  useEffect(() => {
    setInitialLayers();
    layerManager.calculateLayers();
  }, [layerManager, setInitialLayers]);

  const setLayersPromise = useStoreStateSetPromise($layers);
  const selectedLayersStore = useStoreValue($selectedLayers);
  const setSelectedLayersPromise = useStoreStateSetPromise($selectedLayers);

  const getSelectedLayers = useCallback(
    () => selectedLayersStore.value,
    [selectedLayersStore.value]
  );

  const setSelectedLayers = useCallback(
    (nextLayers, isRestore) => {
      const prevLayers = getSelectedLayers();

      if (isArrayEquals(prevLayers, nextLayers)) {
        return Promise.resolve(false);
      }
      return setSelectedLayersPromise(nextLayers).then((complete) => {
        if (!complete) {
          return false;
        }
        layerManager.calculateLayers();

        if (!isRestore) {
          const prevs = prevLayers;
          const nexts = nextLayers;

          historyManager.addHistory("selectTargets", { prevs, nexts });
        }

        selectoRef.current?.setSelectedTargets(
          layerManager.toTargetList(nextLayers).flatten()
        );
        actionManager.act("set.selected.layers");
        return true;
      });
    },
    [
      actionManager,
      getSelectedLayers,
      historyManager,
      layerManager,
      setSelectedLayersPromise,
    ]
  );

  const clearSearch = useCallback(() => {
    layerManager.layers.forEach((item) => {
      const nItem = item;

      if (isMobilVersion !== "1") {
        if (nItem.ref.current != undefined) {
          nItem.ref.current.style.cssText += "opacity:1"; //style sorunu
        }
      }
    });
    setPreviewSelectedItem(null);
    setSearchResultsIndex(0);
    setSearchResults([]);
  }, [
    isMobilVersion,
    layerManager.layers,
    setSearchResults,
    setSearchResultsIndex,
  ]);

  const highlightLayer = useCallback(
    (
      results,
      index,
      options = { opacity: 0.1, scroll: true, markAll: true }
    ) => {
      if (results.length > 0) {
        if (options.opacity) {
          layerManager?.layers?.forEach((item) => {
            const nItem = item;

            if (isMobilVersion !== "1") {
              if (nItem.ref.current != undefined) {
                nItem.ref.current.style.cssText += `opacity:${options.opacity}`; //style sorunu
              }
            }
          });
          results.forEach((item, i) => {
            const nItem = item;
            // console.log(nItem.ref.current);
            if (isMobilVersion != "1") {
              if (nItem.ref.current != undefined) {
                nItem.ref.current.style.cssText +=
                  i !== index && options.markAll ? "opacity:0.5" : "opacity:1"; //style sorunu
              }
            }
          });
        }

        if (options.scroll && isMobilVersion !== "1") {
          const frame = layerManager?.getFrame(results[index]);
          let x =
            Number(frame.properties.left) +
            Number(
              frame.properties.transform.translate.value[0].replace("px", "")
            );
          let y =
            Number(frame.properties.top) +
            Number(
              frame.properties.transform.translate.value[1].replace("px", "")
            );

          const frameWidth = isNumber(frame.properties.width)
            ? frame.properties.width
            : Number(frame.properties.width.replace("px", ""));
          const frameHeight = isNumber(frame.properties.height)
            ? frame.properties.height
            : Number(frame.properties.height.replace("px", ""));
          x -=
            infiniteViewerRef.current.containerElement.clientWidth / zoom / 2 -
            frameWidth / 2;
          y -=
            infiniteViewerRef.current.containerElement.clientHeight / zoom / 2 -
            frameHeight / 2;

          infiniteViewerRef.current.scrollTo(
            x,
            y,
            // zoom,
            { absolute: false, duration: 500 }
          );
        }
      } else {
        layerManager?.layers?.forEach((item) => {
          const nItem = item;
          if (nItem.ref.current != undefined) {
            nItem.ref.current.style.cssText += "opacity:1"; //style sorunu
          }
        });
      }
    },
    [isMobilVersion, layerManager, zoom]
  );

  const onInteraction = useCallback(() => {
    clearSearch();
  }, [clearSearch]);

  const onBlur = useCallback(
    (e) => {
      const { target } = e;

      if (!checkInput(target)) {
        return;
      }
      const parentTarget = getParentScenaElement(target);

      if (!parentTarget) {
        return;
      }

      if (!parentTarget.isContentEditable) {
        return;
      }

      const layer = layerManager.getLayerByElement(parentTarget);

      const nextText = parentTarget.innerText;

      historyManager.addHistory("changeText", {
        layer,
        prev: layer.innerText,
        next: nextText,
      });
      layer.innerText = nextText;
    },
    [historyManager, layerManager]
  );

  const changeLayers = useCallback(
    (nLayers, groups = layerManager.groups) => {
      layerManager.setLayers(nLayers, groups);
      layerManager.calculateLayers();
      return setLayersPromise(nLayers);
    },
    [layerManager, setLayersPromise]
  );

  const setLayers = useCallback(
    (nLayers, groups = layerManager.groups) => {
      layerManager.setLayers(nLayers, groups);
      return setLayersPromise(nLayers).then((complete) => {
        layerManager.calculateLayers();
        return complete;
      });
    },
    [layerManager, setLayersPromise]
  );

  const addLayer = useCallback(
    (style, type, data) => {
      const maxId =
        layerManager.layers.length > 0
          ? Math.max(
              ...layerManager.layers.map((o) =>
                Number(o.title) ? Number(o.title) : 0
              )
            )
          : 0;
      let title;
      let jsx;
      let fpeData;
      let innerText;

      const Component = getJSXComponent(type);

      switch (type) {
        case LAYER_TYPES.booth:
          title = maxId + 1;
          fpeData = { status: BOOTH_STATUS_TYPES.avail };
          jsx = (
            <Component
              title={title}
              style={{ ...DEFAULT_BOOTH_STYLE, ...style }}
              childStyle={{ ...DEFAULT_BOOTH_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.carpet:
          title = formatMessage({
            id: `app.common.${data.id || LAYER_TYPES.carpet}`,
          });
          jsx = (
            <Component
              title={title}
              style={{ ...DEFAULT_CARPET_STYLE, ...style }}
              childStyle={{ ...DEFAULT_CARPET_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.otherSpace:
          title = formatMessage({
            id: `app.common.${data.id || LAYER_TYPES.otherSpace}`,
          });
          jsx = (
            <Component
              title={title}
              style={{ ...DEFAULT_OTHER_SPACE_STYLE, ...style }}
              childStyle={{ ...DEFAULT_OTHER_SPACE_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.blockSpace:
          title = formatMessage({
            id: `app.common.${data.id || LAYER_TYPES.blockSpace}`,
          });
          jsx = (
            <Component
              title={title}
              style={{
                ...DEFAULT_BLOCK_SPACE_STYLE,
                ...style,
              }}
              childStyle={{ ...DEFAULT_BLOCK_SPACE_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.icon:
          title = formatMessage({
            id: `app.helpers.floorPlans.icons.${data.id}`,
          });
          jsx = (
            <Component
              id={data.id}
              style={{ ...DEFAULT_ICON_STYLE, ...style }}
            />
          );
          break;

        case LAYER_TYPES.rectangle:
          title = undefined;
          jsx = (
            <Component
              data-type={LAYER_TYPES.rectangle}
              style={{ ...DEFAULT_RECTANGLE_STYLE, ...style }}
            />
          );
          break;

        case LAYER_TYPES.circle:
          title = undefined;
          jsx = (
            <Component
              data-type={LAYER_TYPES.circle}
              style={{ ...DEFAULT_CIRCLE_STYLE, ...style }}
            />
          );
          break;

        case LAYER_TYPES.text:
          title = undefined;
          innerText = formatMessage({
            id: `app.common.${DEFAULT_TEXT_EMPTY_VALUE}`,
          });
          jsx = <Component style={{ ...DEFAULT_TEXT_STYLE, ...style }} />;
          break;

        default:
          jsx = (
            <Component
              title={title}
              style={style}
              childStyle={{ ...DEFAULT_BOOTH_CHILD_STYLE }}
            />
          );
          break;
      }

      const layer = createLayer({
        fpeData,
        innerText,
        title,
        type,
        scope: [],
        jsx,
        item: new SceneItem(),
        ref: createRef(),
      });

      // const prevs = layerManager.layers;
      const nexts = [...layerManager.layers, layer];

      setLayers(nexts);

      /* setLayers(nexts).then(() => {
      setTimeout(() => {
        setSelectedLayers([layer]);
      }, 1);
      historyManager.addHistory('addLayers', { prevs, nexts });
    }); */
    },
    [formatMessage, layerManager.layers, setLayers]
  );

  const duplicateLayer = useCallback(
    ({ style, type, moveable }) => {
      addLayer(style, type, { moveable });
    },
    [addLayer]
  );

  const removeLayers = useCallback(() => {
    const prevs = layerManager.layers;
    const selectedLayers = getSelectedLayers();

    const nexts = layerManager.layers.filter((layer) => {
      const founded = selectedLayers.find(
        (selectedLayer) => selectedLayer.id === layer.id
      );

      if (!founded) {
        return true;
      }

      return false;
    });

    setLayers(nexts);

    historyManager.addHistory("removeLayers", { prevs, nexts });
  }, [getSelectedLayers, historyManager, layerManager.layers, setLayers]);

  const getMoveable = () => moveableRef.current;

  const move = useCallback((deltaX, deltaY) => {
    getMoveable().request("draggable", { deltaX, deltaY }, true);
  }, []);

  const changeLayerOrder = useCallback(
    (isBackwards, isAll) => {
      const selectedLayers = getSelectedLayers();

      let prevIndex = 0;
      const nexts = layerManager.layers.filter((layer, index) => {
        const founded = selectedLayers.find(
          (selectedLayer) => selectedLayer.id === layer.id
        );

        if (!founded) {
          return true;
        }

        if (isBackwards) {
          prevIndex = index > 0 ? index - selectedLayers.length : 0;
        } else {
          prevIndex = index + selectedLayers.length;
        }

        return false;
      });

      if (isAll) {
        if (isBackwards) {
          prevIndex = 0;
        } else {
          prevIndex = nexts.length;
        }
      }

      nexts.splice(prevIndex, 0, ...selectedLayers);

      changeLayers(nexts);
    },
    [changeLayers, getSelectedLayers, layerManager.layers]
  );

  const bringForwardLayers = useCallback(() => {
    changeLayerOrder(false);
  }, [changeLayerOrder]);

  const sendBackwardsLayers = useCallback(() => {
    changeLayerOrder(true);
  }, [changeLayerOrder]);

  const unlockMap = useCallback(() => {
    const dataToSave = layerManager.layers.map((layer) => {
      const { id, fpeData, innerText, title, type, lock, jsx } = layer;
      const css = layerManager.getFrame(layer).toCSSObject();
      return {
        id,
        fpeData,
        innerText,
        title,
        type,
        lock,
        jsx,
        css,
      };
    });

    setIsDirty(false);
    onUnlock(dataToSave);
  }, [onUnlock, layerManager]);

  const bringToFrontLayers = useCallback(() => {
    changeLayerOrder(false, true);
  }, [changeLayerOrder]);

  const sendToBackLayers = useCallback(() => {
    changeLayerOrder(true, true);
  }, [changeLayerOrder]);

  const exportLayers = useCallback(() => {
    const dataToSave = layerManager.layers.map((layer) => {
      const { id, fpeData, innerText, title, type, lock, jsx } = layer;
      const css = layerManager.getFrame(layer).toCSSObject();
      var data = {
        id,
        fpeData,
        innerText,
        title,
        type,
        lock,
        jsx,
        css,
      };

      return data;
    });
    setIsDirty(false);
    onSave(dataToSave);
  }, [onSave, layerManager]);

  editorRef.current = useMemo(
    () => ({
      editorElementRef,
      historyManager,
      actionManager,
      memoryManager,
      layerManager,
      keyManager,
      moveableRef,
      selectoRef,
      viewportRef,
      changeLayers,
      setLayers,
      getSelectedLayers,
      setSelectedLayers,
      duplicateLayer,
      addLayer,
      removeLayers,
      bringForwardLayers,
      sendBackwardsLayers,
      bringToFrontLayers,
      sendToBackLayers,
      exportLayers,
      unlockMap,
      toggleBackground: () => {
        setShowBackground(!showBackground);
      },
      toggleGrid: () => {
        setShowGrid(!showGrid);
      },
      clearSearch,
      highlightLayer,
    }),
    [
      actionManager,
      addLayer,
      bringForwardLayers,
      bringToFrontLayers,
      changeLayers,
      duplicateLayer,
      getSelectedLayers,
      historyManager,
      keyManager,
      layerManager,
      memoryManager,
      removeLayers,
      sendBackwardsLayers,
      sendToBackLayers,
      setLayers,
      setSelectedLayers,
      showBackground,
      showGrid,
      exportLayers,
      clearSearch,
      highlightLayer,
    ]
  );

  useEffect(() => {
    const onUpdate = () => {
      setIsDirty(true);
      requestAnimationFrame(() => {
        actionManager.act("get.rect", {
          rect: moveableRef.current.getRect(),
        });
      });
    };
    actionManager.on("render.end", onUpdate);
    actionManager.on("changed.targets", onUpdate);
    actionManager.on("update.rect", onUpdate);

    /* actionManager.on('request.toogle.background', () => {
      const bgLayer = root.get($layers).find((item) => item.type === 'bg');
      const frame = layerManager.getFrame(bgLayer);
      const prev = frame.toCSSObject();
      const isVisible = prev.display !== 'none';

      frame.set('display', isVisible ? 'none' : 'block');

      bgLayer.ref.current.style.cssText += frame.toCSSText();
    }); */

    actionManager.on("select.all", (e) => {
      e.inputEvent?.preventDefault();
      const nLayers = root.get($layers);

      const childs = layerManager.selectSameDepthChilds(
        [],
        nLayers.map((layer) => layer.ref.current),
        []
      );

      setSelectedLayers(layerManager.toLayerGroups(childs));
    });
    actionManager.on("request.history.undo", (e) => {
      e.inputEvent?.preventDefault();
      historyManager.undo();
    });
    actionManager.on("request.history.redo", (e) => {
      e.inputEvent?.preventDefault();
      historyManager.redo();
    });

    actionManager.on("remove.targets", () => {
      removeLayers();
    });

    actionManager.on("move.up", (e) => {
      e.inputEvent?.preventDefault();
      move(0, -1);
    });

    actionManager.on("move.left", (e) => {
      e.inputEvent?.preventDefault();
      move(-1, 0);
    });
    actionManager.on("move.right", (e) => {
      e.inputEvent?.preventDefault();
      move(1, 0);
    });
    actionManager.on("move.down", (e) => {
      e.inputEvent?.preventDefault();
      move(0, 1);
    });

    // register key
    keyManager.toggleState(["shift"], $shift, keyChecker);
    keyManager.toggleState(["space"], $space, keyChecker);
    keyManager.toggleState(["meta"], $meta, keyChecker);
    keyManager.toggleState(["alt"], $alt, keyChecker);

    // action down
    keyManager.actionDown(["left"], "move.left");
    keyManager.actionDown(["right"], "move.right");
    keyManager.actionDown(["up"], "move.up");
    keyManager.actionDown(["down"], "move.down");
    // TODO: window key
    keyManager.actionDown(["meta", "a"], "select.all");

    // action up
    keyManager.actionUp(["delete"], "remove.targets");
    keyManager.actionUp(["backspace"], "remove.targets");

    keyManager.actionDown(["meta", "z"], "request.history.undo");
    keyManager.actionDown(["meta", "shift", "z"], "request.history.redo");

    // register default events
    const onResize = () => {
      horizontalGuidesRef.current.resize();
      verticalGuidesRef.current.resize();
    };
    const startId = requestAnimationFrame(() => {
      if (isMobilVersion !== "1") {
        onResize();
        infiniteViewerRef.current.scrollCenter();
      }
    });
    //console.log(typeof isMobilVersion);
    registerHistoryTypes(historyManager);
    if (isMobilVersion !== "1") {
      window.addEventListener("resize", onResize);
    }

    return () => {
      layerManager.set([], []);
      historyManager.clear();
      actionManager.off();
      keyManager.destroy();
      cancelAnimationFrame(startId);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const header = document.getElementsByTagName("header")[0];
    const footer = document.getElementsByClassName("footer-offset")[0];

    if (header && footer) {
      const footerStyle =
        footer.currentStyle || window.getComputedStyle(footer);

      const margin =
        header.clientHeight +
        parseInt(footerStyle.marginBottom.replace("px", ""), 10);
      setLayoutMargin(margin);
    }
  }, []);

  const canvasBgColor = theme === "dark" ? "#f2f2f2" : "#f2f2f2";

  const rulerBgColor = theme === "dark" ? "#363a3d" : "#f9fafc";
  const rulerTextColor = theme === "dark" ? "#c5c8cc" : "#677788";
  const rulerLineColor = theme === "dark" ? "#677788" : "#677788";
  const rulerSelectedBackgroundColor = theme === "dark" ? "#292e33" : "#cccccc";

  const viewportWidth = width || DEFAULT_VIEWPORT_WIDTH;
  const viewportHeight = height || DEFAULT_VIEWPORT_HEIGHT;

  const onInfiniteViewerZoom = useCallback(
    (value) => {
      let nValue = value / zoomScale;
      if (nValue <= ZOOM_MIN) {
        nValue = ZOOM_MIN;
      } else if (nValue >= ZOOM_MAX) {
        nValue = ZOOM_MAX;
      }

      setZoomStepper(nValue);
    },
    [zoomScale]
  );

  useEffect(() => {
    const calculatedZoom = zoomStepper * zoomScale;
    setZoom(calculatedZoom);
  }, [zoomScale, zoomStepper, setZoom]);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();

      let dataTransferData = e.dataTransfer.getData("text");
      dataTransferData = dataTransferData?.split("|");

      const infiniteViewer = infiniteViewerRef.current;
      const viewportElement = infiniteViewer.getViewport();
      const { left, top } = viewportElement.getBoundingClientRect();
      const { clientX, clientY } = e;
      const offsetPosition = [(clientX - left) / zoom, (clientY - top) / zoom];

      if (dataTransferData?.[0] === "sceneItem") {
        const [, type, id] = dataTransferData;

        let layerWidth;
        let layerHeight;
        switch (type) {
          case LAYER_TYPES.booth:
            layerWidth = DEFAULT_BOOTH_SIZE.width;
            layerHeight = DEFAULT_BOOTH_SIZE.height;
            break;

          case LAYER_TYPES.carpet:
            layerWidth = DEFAULT_CARPET_SIZE.width;
            layerHeight = DEFAULT_CARPET_SIZE.height;
            break;

          case LAYER_TYPES.otherSpace:
            layerWidth = DEFAULT_OTHER_SPACE_SIZE.width;
            layerHeight = DEFAULT_OTHER_SPACE_SIZE.height;
            break;

          case LAYER_TYPES.blockSpace:
            layerWidth = DEFAULT_BLOCK_SPACE_SIZE.width;
            layerHeight = DEFAULT_BLOCK_SPACE_SIZE.height;
            break;

          case LAYER_TYPES.icon:
            layerWidth = DEFAULT_ICON_SIZE.width;
            layerHeight = DEFAULT_ICON_SIZE.height;
            break;

          case LAYER_TYPES.text:
            layerWidth = DEFAULT_TEXT_SIZE.width;
            layerHeight = DEFAULT_TEXT_SIZE.height;
            break;

          default:
            layerWidth = DEFAULT_BOOTH_SIZE.width;
            layerHeight = DEFAULT_BOOTH_SIZE.height;
            break;
        }

        addLayer(
          {
            width: `${layerWidth}px`,
            height: `${layerHeight}px`,
            transform: `translate(${Math.round(
              offsetPosition[0] - layerWidth / 2
            )}px, ${Math.round(offsetPosition[1] - layerHeight / 2)}px)`,
          },
          dataTransferData[1],
          { id }
        );
        return;
      }
      if (ALLOW_DROP_FILES) {
        readFiles(e, offsetPosition).then((result) => {
          if (result.layers) {
            setLayers(
              [...layerManager.layers, ...result.layers],
              [...layerManager.groups, ...result.groups]
            );
          }
        });
      }
    },
    [layerManager.layers, layerManager.groups, setLayers, zoom, addLayer]
  );

  const editorMemo = useMemo(
    () => (
      <div
        tabIndex={0}
        role="button"
        ref={editorElementRef}
        className={`${prefix("editor")} position-relative w-100 h-100`}
        style={{ cursor: "default" }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={onDrop}
        onMouseDown={() => {
          onInteraction();
        }}
      >
		 
        {isPreview && (
          <div
            tabIndex={0}
            role="button"
            className="position-absolute d-flex d-lg-none align-items-center justify-content-center zi-1"
            style={{
              width: RULER_SIZE * 1.5,
              height: RULER_SIZE * 1.5,
              backgroundColor: rulerBgColor,
            }}
            onClick={() => {
              setShowLeft(true);
            }}
            onKeyDown={() => {}}
          >
            <i className="bi-list fs-1" />
          </div>
        )}
        <div
          tabIndex={0}
          role="button"
          className={`${prefix(
            "reset"
          )} position-absolute d-flex align-items-center justify-content-center zi-1`}
          style={{
            width: RULER_SIZE,
            height: RULER_SIZE,
            backgroundColor: rulerBgColor,
            borderRight: isPreview ? undefined : `1px solid ${rulerLineColor}`,
            borderBottom: isPreview ? undefined : `1px solid ${rulerLineColor}`,
            right: isPreview ? 0 : undefined,
          }}
          onClick={() => {
            infiniteViewerRef.current.scrollCenter({
              duration: 500,
              absolute: true,
            });
          }}
          onKeyDown={() => {}}
        >
          <div>
            <div className="position-absolute top-50 start-50 translate-middle">
              <i
                className="bi-arrows-angle-contract d-inline-block"
                style={{ transform: "rotate(45deg)" }}
              />
            </div>
            <div className="position-absolute top-50 start-50 translate-middle">
              <i
                className="bi-arrows-angle-contract d-inline-block"
                style={{ transform: "rotate(135deg)" }}
              />
            </div>
            <div className="position-absolute top-50 start-0 translate-middle">
              <i className="bi-dot" />
            </div>
			<div className="position-absolute top-50 end-50 translate-middle">
			<LanguageSwitcherPositionEnd dropup={false} variant="light" /></div>
          </div>
        </div>
        <GuidesManager
          ref={horizontalGuidesRef}
          type="horizontal"
          size={rulerSize}
          backgroundColor={rulerBgColor}
          textColor={rulerTextColor}
          lineColor={rulerLineColor}
          selectedBackgroundColor={rulerSelectedBackgroundColor}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
        />
        <GuidesManager
          ref={verticalGuidesRef}
          type="vertical"
          size={rulerSize}
          backgroundColor={rulerBgColor}
          textColor={rulerTextColor}
          lineColor={rulerLineColor}
          selectedBackgroundColor={rulerSelectedBackgroundColor}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
        />
        <InfiniteViewerManager
          ref={infiniteViewerRef}
          style={{
            width: `calc(100% - ${rulerSize}px)`,
            height: `calc(100% - ${rulerSize}px)`,
            top: rulerSize,
            left: rulerSize,
          }}
          zoomMin={ZOOM_MIN * zoomScale}
          zoomMax={ZOOM_MAX * zoomScale}
          onZoom={onInfiniteViewerZoom}
        >
          <Viewport
            ref={viewportRef}
            onBlur={onBlur}
            showGrid={showGrid}
            isPreview={isPreview}
            style={{
              width: viewportWidth,
              height: viewportHeight,
              backgroundColor: "#fff",
              backgroundImage:
                background && showBackground ? `url(${background})` : "none",
              backgroundSize: "cover",
            }}
          >
            {!isPreview && <MoveableManager ref={moveableRef} />}
          </Viewport>
        </InfiniteViewerManager>
        {!isPreview && <SelectoManager ref={selectoRef} />}
      </div>
    ),
    [
      onBlur,
      onDrop,
      rulerBgColor,
      rulerLineColor,
      rulerSelectedBackgroundColor,
      rulerTextColor,
      viewportWidth,
      viewportHeight,
      onInfiniteViewerZoom,
      zoomScale,
      showBackground,
      background,
      showGrid,
      isPreview,
      rulerSize,
      onInteraction,isNew
    ]
  );

  const bottomMemo = useMemo(
    () => (
      <div
        className="position-absolute"
        style={{ zIndex: 1, left: rulerSize + 10, bottom: 5, right: 10 }}
      >
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <ButtonGroup className="mb-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.zoomOut" />
                </Tooltip>
              }
            >
              <Button
                size="sm"
                variant="light"
                className="px-2"
                onClick={() => {
                  const nZoom =
                    ((Math.round(zoomStepper * 10) / 10 - ZOOM_STEP) * 100) /
                    100;

                  // const nZoom = zoom - ZOOM_STEP;

                  if (ZOOM_MIN <= nZoom) {
                    setZoomStepper(nZoom);
                  }
                }}
              >
                <i className="bi-zoom-out" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>1:1</Tooltip>}>
              <Button
                size="sm"
                variant="light"
                className="px-2"
                onClick={() => {
                  setZoomStepper(1);
                }}
              >
                {`${Math.round(zoomStepper * 100)}%`}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.zoomIn" />
                </Tooltip>
              }
            >
              <Button
                size="sm"
                variant="light"
                className="px-2"
                onClick={() => {
                  const nZoom =
                    ((Math.round(zoomStepper * 10) / 10 + ZOOM_STEP) * 100) /
                    100;
                  // const nZoom = zoom + ZOOM_STEP;
                  if (ZOOM_MAX >= nZoom) {
                    setZoomStepper(nZoom);
                  }
                }}
              >
                <i className="bi-zoom-in" />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
          <Button
            size="sm"
            as="div"
            variant="light"
            className="shadow-none mb-2"
            disabled
            style={{ cursor: "default" }}
            role="none"
          >
            <FormattedNumber value={Math.round(width * height)} />
            {unitType}
          </Button>
        </div>
      </div>
    ),
    [zoomStepper, height, unitType, width, rulerSize]
  );

  const sellModal = () => (
    <Modal
      show={showSellModal}
      onHide={closeModal}
      size={isMobilVersion === "1" ? "mw-100" : "xl"}
      className={isMobilVersion === "1" ? "mw-100 m-0" : "xl"}
      style={
        isMobilVersion === "1"
          ? {
              padding: 0,
              margin: 0,
              width: "100% !important",
              zIndex: "9999999999",
            }
          : {}
      }
    >
      <Modal.Header closeButton>
        <Modal.Title>Teklif Oluştur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={iframe.value}
          frameBorder="0"
          title="Teklif "
          style={{ width: "100%", height: "100vh" }}
        ></iframe>
      </Modal.Body>
    </Modal>
  ); 

  return (
    <div className="splitted-content-main position-relative border-bottom">
		
      {isPreview && initialData?.id && (
        <Offcanvas
          show={showLeft || isMobilVersion === "1"}
          onHide={handleCloseLeft}
          responsive="lg"
          className={
            isMobilVersion === "1"
              ? "w-100"
              : "splitted-content-small splitted-content-bordered d-flex flex-column h-100 bg-light"
          }
          style={{ minHeight: "auto" }}
        >
          <Offcanvas.Body className="p-0">
		
            <PreviewLeftToolbar
              layoutMargin={layoutMargin}
              mapId={initialData?.id}
              selectedItem={previewSelectedItem}
              setSelectedItem={setPreviewSelectedItem}
              searchValue={previewSearchValue}
              setSearchValue={setPreviewSearchValue}
              sellerData={initialData.sellerData}
			  isPreview = {isPreview}
			  isNew = {isNew}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {!isPreview && (
        <Offcanvas
          show={showLeft}
          onHide={handleCloseLeft}
          responsive="lg"
          className="splitted-content-mini splitted-content-bordered d-flex flex-column h-100 bg-light"
          style={{ minHeight: "auto" }}
        >
          <Offcanvas.Body>
            <SimpleBar
              style={{
                width: "100%",
                height: `calc(100vh - ${layoutMargin}px)`,
              }}
            >
              <LeftToolbar
                iconFillColor={theme === "dark" ? "#CBC8CC" : "#677788"}
              />
            </SimpleBar>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <div
        className="flex-grow-1 position-relative"
        style={{
          height: `calc(100vh - ${layoutMargin}px)`,
          backgroundColor: canvasBgColor,
        }}
      >
        <div className="d-flex flex-column w-100 h-100">
          {!isPreview && (
            <div className="bg-light px-3 border-bottom">
              <TopToolbar />
            </div>
          )}

          {isMobilVersion !== "1" && bottomMemo}
          {isMobilVersion !== "1" && editorMemo}
        </div>
      </div>
      {!isPreview && (
        <Offcanvas
          show={showRight}
          onHide={handleCloseRight}
          responsive="xl"
          placement="end"
          className="splitted-content-small splitted-content-bordered d-flex flex-column h-100 bg-light zi-1"
          style={{ minHeight: "auto" }}
        >
          <Offcanvas.Body>
            <SimpleBar
              style={{
                width: "100%",
                height: `calc(100vh - ${layoutMargin}px)`,
              }}
            >
              <RightToolbar data={initialData} />
            </SimpleBar>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {showSellModal && sellModal()}
    </div>
  );
}

EditorManager.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func,
  onUnlock: PropTypes.func,
  isPreview: PropTypes.bool,
  isRestricted: PropTypes.bool,
};

EditorManager.defaultProps = {
  width: 800,
  height: 600,
  background: null,
  data: null,
  onSave: () => {},
  onUnlock: () => {},
  isPreview: false,
  isRestricted: false,
};

export default EditorManager;
