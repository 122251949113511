 
import PropTypes from 'prop-types';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
} from 'react';
  
  export const Canvas = forwardRef(({ width, height, data: rgba }, ref) => {
	const canvasRef = useRef();
  
	useImperativeHandle(ref, () => canvasRef.current, []);
	useEffect(() => {
	  const canvasElement = canvasRef.current;
	  const context = canvasElement.getContext('2d');
	  const imageData = context.createImageData(width, height);
  
	  canvasElement.width = width;
	  canvasElement.height = height;
	  imageData.data.set(rgba);
	  context.putImageData(imageData, 0, 0);
	}, []);
  
	return <canvas ref={canvasRef} width={width} height={height} />;
  });
  
  Canvas.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	data: PropTypes.any.isRequired,
  };
  
  Canvas.displayName = 'Canvas';
  