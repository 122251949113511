import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
 import { protectedAreaBasePath } from '../../../router/RouteMapping';

function NavbarSingleLogoLight() {
  return (
    <Navbar.Brand as={Link} to={protectedAreaBasePath}>
      Logo
    </Navbar.Brand>
  );
}

export default NavbarSingleLogoLight;
