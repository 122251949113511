import PropTypes from 'prop-types';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import {
	DEFAULT_CARPET_CHILD_STYLE,
	DEFAULT_CARPET_STYLE,
	LAYER_TYPES,
} from '../consts';
  
  const Carpet = forwardRef(({ title, style, childStyle, ...props }, ref) => {
	const carpetRef = useRef();
  
	const [fontSize, setFontSize] = useState('2rem');
  
	useImperativeHandle(ref, () => carpetRef.current, []);
  
	useEffect(() => {
	  const carpet = carpetRef.current;
	  const observer = new ResizeObserver((entries) => {
		entries.forEach((entry) => {
		  if (entry.contentBoxSize) {
			const contentBoxSize = entry.contentBoxSize[0];
			const s =
			  contentBoxSize.inlineSize < contentBoxSize.blockSize
				? contentBoxSize.inlineSize
				: contentBoxSize.blockSize;
			const size = s / 60;
			setFontSize(`${size}rem`);
		  }
		});
	  });
  
	  observer.observe(carpet);
  
	  return () => {
		observer.disconnect();
	  };
	}, []);
  
	return (
	  <div
		ref={carpetRef}
		data-type={LAYER_TYPES.carpet}
		style={style}
		{...props}
	  >
		<div
		  className="id-container"
		  contentEditable={false}
		  style={{
			...childStyle,
			fontSize,
		  }}
		>
		  {title}
		</div>
	  </div>
	);
  });
  
  Carpet.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	style: PropTypes.objectOf(PropTypes.any),
	childStyle: PropTypes.objectOf(PropTypes.any),
  };
  
  Carpet.defaultProps = {
	style: DEFAULT_CARPET_STYLE,
	childStyle: DEFAULT_CARPET_CHILD_STYLE,
  };
  
  Carpet.displayName = 'Carpet';
  
  export default Carpet;
  