import React, { useContext, useState } from 'react';

import { Navbar, Container, Nav } from 'react-bootstrap';

import PropTypes from 'prop-types';

import NavbarSingleLogoLight from '../LayoutComponents/NavbarSingleLogoLight';
import SearchLight from '../LayoutComponents/SearchLight';
import DropdownNotificationsGhostLight from '../LayoutComponents/DropdownNotificationsGhostLight';

import LanguageSwitcherPositionEnd from '../LayoutComponents/LanguageSwitcherPositionEnd';

import DarkmodeSwitcherPositionEnd from '../LayoutComponents/DarkmodeSwitcherPositionEnd';

import DropdownUser from '../LayoutComponents/DropdownUser';

import NavbarNavMenu from '../LayoutComponents/NavbarNavMenu';
 
import { DashboardContext } from '../../../context/DashBoardContext';

import OffcanvasActivity from '../LayoutComponents/OffcanvasActivity';

function DoubleLineContainer({ menuData }) {
  const [showOffcanvasActivity, setShowOffcanvasActivity] = useState(false);

  const handleOffcanvasActivityClose = () => setShowOffcanvasActivity(false);
  // const handleOffcanvasActivityShow = () => setShowOffcanvasActivity(true);

  const { hasAccess } = useContext(DashboardContext);

  return (
    <>
      <Navbar
        as="header"
        expand="lg"
        className="navbar-bordered navbar-spacer-y-0 flex-lg-column"
        variant=""
      >
        <div className="navbar-dark w-100 bg-dark py-2">
          <Container>
            <div className="navbar-nav-wrap">
              <NavbarSingleLogoLight />
              <div className="navbar-nav-wrap-content-start">
                <div className="d-none">
                  <SearchLight />
                </div>
              </div>
              <div className="navbar-nav-wrap-content-end">
                <Nav as="ul">
                  <Nav.Item as="li" className="d-none d-md-inline-block">
                    <DropdownNotificationsGhostLight />
                  </Nav.Item>
                  {/* <Nav.Item as="li" className="d-none d-sm-inline-block">
                    <DropdownAppsGhostLight />
                  </Nav.Item> */}
                  {/* <Nav.Item as="li" className="d-none d-sm-inline-block">
                    <Button
                      variant="ghost-light"
                      className="btn-icon rounded-circle"
                      onClick={handleOffcanvasActivityShow}
                    >
                      <i className="bi-x-diamond" />
                    </Button>
                  </Nav.Item> */}
                  <Nav.Item>
                    <DarkmodeSwitcherPositionEnd dropup={false} />
                  </Nav.Item>
                  <Nav.Item>
                    <LanguageSwitcherPositionEnd dropup={false} />
                  </Nav.Item>
                  <Nav.Item>
                    <DropdownUser />
                  </Nav.Item>
                  <Nav.Item>
                    <Navbar.Toggle aria-controls="navbarDoubleLineContainerNavDropdown">
                      <span className="navbar-toggler-default">
                        <i className="bi-list" />
                      </span>
                      <span className="navbar-toggler-toggled">
                        <i className="bi-x" />
                      </span>
                    </Navbar.Toggle>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </Container>
        </div>

        {hasAccess && (
          <Container>
            <nav className="js-mega-menu flex-grow-1">
              <Navbar.Collapse id="navbarDoubleLineContainerNavDropdown">
                <NavbarNavMenu menuData={menuData} />
              </Navbar.Collapse>
            </nav>
          </Container>
        )}
      </Navbar>

      <OffcanvasActivity
        show={showOffcanvasActivity}
        onHide={handleOffcanvasActivityClose}
      />
    </>
  );
}

const MenuItemProps = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
};

const MenuItemShape = {
  ...MenuItemProps,
  submenu: PropTypes.arrayOf(PropTypes.shape(MenuItemProps)),
};

DoubleLineContainer.propTypes = {
  menuData: PropTypes.arrayOf(PropTypes.shape(MenuItemShape)).isRequired,
};

export default DoubleLineContainer;
