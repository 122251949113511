 
import { deepFlat, find } from '@daybrush/utils';
import { GroupManager, toTargetList } from '@moveable/helper';
import { useStoreStateValue } from '@scena/react-store';
import { createRef } from 'react';
import { Frame, SceneItem } from 'scenejs';
import { $layers } from '../stores/stores';

// let id = 0;

export function getNextId() {
  // id += 1;
  const id = new Date().valueOf();
  return `floorplan-id-${id}`;
}

export function createLayer(layerInfo = {}) {
  return {
    id: layerInfo.id || getNextId(),
    scope: [],
    item: new SceneItem(),
    jsx: layerInfo.jsx || <div />,
    ref: createRef(),
    ...layerInfo,
    title: layerInfo.title,
  };
}
export function createGroup(groupInfo = {}) {
  return {
    type: 'group',
    id: groupInfo.id ? groupInfo.id : getNextId(),
    scope: [],
    children: [],
    opacity: 1,
    display: 'block',
    ...groupInfo,
    title: groupInfo.title || '',
  };
}
export default class LayerManager extends GroupManager {
  groups = [];

  layers = [];

  #_targetGroupMap = {};

  #_groupMap = {};

  constructor(layers = [], groups = []) {
    super([], []);

    this.setLayers(layers, groups);
  }

  use() {
    return useStoreStateValue($layers);
  }

  setLayers(layers, groups = this.groups) {
    this.layers = layers;

    const groupLayers = this.layers.filter((layer) => layer.scope.length);
    const groupMap = {};
    const nextGroups = [];
    const prevGroupMap = {};
    const map = {
      '': {
        groupId: '',
        children: [],
      },
    };

    groups.forEach((group) => {
      prevGroupMap[group.id] = group;
    });

    groupLayers.forEach((layer) => {
      const { scope } = layer;

      scope.forEach((_, i) => {
        const groupId = scope[i];
        const parentId = scope[i - 1] || '';

        if (!map[groupId]) {
          map[groupId] = {
            groupId,
            children: [],
          };
          map[parentId].children.push(map[groupId]);
        }
        // parentId
        if (!groupMap[groupId]) {
          // new group
          const group =
            prevGroupMap[groupId] ||
            createGroup({
              id: groupId,
            });
          group.children = [];
          nextGroups.push(group);
          groupMap[groupId] = group;
          groupMap[parentId]?.children.push(group);
        }
      });
      map[scope[scope.length - 1] || ''].children.push(layer.ref);
      groupMap[scope[scope.length - 1] || '']?.children.push(layer);
    });

    this.groups = nextGroups.filter((group) => map[group.id]);

    this.#_targetGroupMap = map;
    this.#_groupMap = groupMap;
  }

  calculateLayers() {
    this.set(
      this.#_targetGroupMap[''].children,
      this.layers.map((layer) => layer.ref.current)
    );
  }

  compositeStyle(layer) {
    const isGroup = layer.type === 'group';
    const layerFrame = isGroup ? null : this.getFrame(layer);
    const { scope } = layer;
    const groupMap = this.#_groupMap;
    let display = isGroup
      ? layer.display
      : layerFrame?.get('display') || 'block';
    let opacity = isGroup ? layer.opacity : layerFrame?.get('opacity') ?? 1;
    let composited = false;

    function composite() {
      if (!composited) {
        return;
      }
      composited = true;
      [...scope].reverse().forEach((groupId) => {
        const group = groupMap[groupId];

        if (!group) {
          return;
        }

        if (group.display === 'none') {
          display = 'none';
        }
        opacity *= group.opacity;
      });
    }

    return {
      get opacity() {
        composite();
        return opacity;
      },
      get display() {
        if (display === 'none') {
          return display;
        }
        composite();
        return display;
      },
    };
  }

  compositeFrame(layer) {
    const isGroup = layer.type === 'group';
    const layerFrame = isGroup ? null : this.getFrame(layer);
    const nextFrame = isGroup ? new Frame() : layerFrame.clone();
    const { scope } = layer;
    const groupMap = this.#_groupMap;
    let display = layerFrame?.get('display') || 'block';
    let opacity = layerFrame?.get('opacity') ?? 1;

    [...scope].reverse().forEach((groupId) => {
      const group = groupMap[groupId];

      if (!group) {
        return;
      }

      if (group.display === 'none') {
        display = 'none';
      }
      opacity *= group.opacity;
    });

    nextFrame.set({
      opacity,
      display,
    });

    return nextFrame;
  }

  selectCompletedChilds(targets, added, removed, continueSelect) {
    this.calculateLayers();

    return super.selectCompletedChilds(targets, added, removed, continueSelect);
  }

  selectSubChilds(targets, target) {
    this.calculateLayers();

    return super.selectSubChilds(targets, target);
  }

  selectSameDepthChilds(targets, added, removed) {
    this.calculateLayers();

    return super.selectSameDepthChilds(targets, added, removed);
  }

  selectSingleChilds(targets, added, removed) {
    this.calculateLayers();

    return super.selectSingleChilds(targets, added, removed);
  }

  findChildren(parentScope = []) {
    const { length } = parentScope;
    const { layers } = this;
    const childrenLayers = layers.filter(({ scope }) =>
      parentScope.every((path, i) => path === scope[i])
    );

    let children = childrenLayers.map((layer) => {
      const { scope } = layer;
      const childLength = scope.length;

      if (length < childLength) {
        const groupId = scope[length];
        const group = createGroup({
          id: groupId,
          ...this.groups.find((g) => g.id === groupId),
          scope: scope.slice(0, length),
        });

        return group;
      }
      return layer;
    });

    children = children.filter((child, i, arr) => {
      if (child.type === 'group') {
        const { id: nId } = child;

        return !arr.find(
          (nextChild, j) =>
            j < i && nextChild.type === 'group' && nextChild.id === nId
        );
      }
      return true;
    });

    children.forEach((child) => {
      const nChild = child;
      if (nChild.type === 'group') {
        const childScope = [...nChild.scope, nChild.id];

        nChild.children = this.findChildren(childScope);
      }
    });

    return children;
  }

  getRefs() {
    return this.layers.map((layer) => layer.ref);
  }

  getElements() {
    return this.getRefs()
      .map((ref) => ref.current)
      .filter(Boolean);
  }

  getLayerByElement(element) {
    return find(this.layers, (layer) => layer.ref.current === element);
  }

  getCSSByElement(element) {
    return this.getFrame(this.getLayerByElement(element), 0).toCSSObject();
  }

  setCSS(layer, cssObject) {
    layer.item.set(0, cssObject);
  }

  setCSSByElement(element, cssObject) {
    const layer = this.getLayerByElement(element);

    if (!layer) {
      return;
    }
    this.setCSS(layer, cssObject);
  }

  getFrame(layer, time = 0) {
    const { item } = layer;

    if (!item.hasFrame(time)) {
      item.newFrame(time);
      if (layer.jsx?.props?.style) {
        this.setCSS(layer, layer.jsx?.props?.style);
      }
    }
    return item.getFrame(0);
  }

  toLayerGroups(targetList) {
    const childs = targetList.raw();
    const self = this;

    return childs.map((child) => {
      if (child.type === 'single') {
        return self.getLayerByElement(child.value);
      }
      return self.#_groupMap[child.id];
    });
  }

  toFlatten(layerGroups) {
    const result = [];

    layerGroups.forEach((layerGroup) => {
      if (layerGroup?.type === 'group') {
        result.push(...this.toFlatten(layerGroup.children));
      } else {
        result.push(layerGroup);
      }
    });
    return result;
  }

  toFlattenElement(layerGroups) {
    return this.toFlatten(layerGroups).map((layer) => layer?.ref?.current);
  }

  toTargetList(layerGroups) {
    const childs = layerGroups
      .map((layerGroup) => {
        if (layerGroup?.type === 'group') {
          return this.findArrayChildById(layerGroup.id);
        }
        return this.map.get(layerGroup?.ref.current);
      })
      .filter(Boolean);

    const result = toTargetList(childs);

    result.displayed = () => this.filterDisplayedTargets(result.raw());
    result.flattenDisplayed = () => deepFlat(result.displayed());

    return result;
  }

  filterDisplayedLayers(layerGroups) {
    return layerGroups.filter(
      (layerGroup) => this.compositeStyle(layerGroup).display !== 'none'
    );
  }

  filterDisplayedTargets(childs) {
    return childs
      .map((child) => {
        if (child.type === 'single') {
          const layer = this.getLayerByElement(child.value);

          return layer && this.compositeStyle(layer).display !== 'none'
            ? child.value
            : null;
        }
        const group = this.#_groupMap[child.id];

        return group && this.compositeStyle(group).display !== 'none'
          ? this.filterDisplayedTargets(child.value)
          : null;
      })
      .filter(Boolean);
  }
}
