/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import { ReactComponent as Column } from "../content/icons/column.svg";
import { ReactComponent as MeetingArea } from "../content/icons/meetingArea.svg";
import { ReactComponent as Conference } from "../content/icons/conference.svg";
import { ReactComponent as Registration } from "../content/icons/registration.svg";
import { ReactComponent as Food } from "../content/icons/food.svg";
import { ReactComponent as Coffee } from "../content/icons/coffee.svg";
import { ReactComponent as Entrance } from "../content/icons/entrance.svg";
import { ReactComponent as Exit } from "../content/icons/exit.svg";
import { ReactComponent as EmergencyExit } from "../content/icons/emergencyExit.svg";
import { ReactComponent as Elevator } from "../content/icons/elevator.svg";
import { ReactComponent as Escalator } from "../content/icons/escalator.svg";
import { ReactComponent as Stairs } from "../content/icons/stairs.svg";
import { ReactComponent as Handicap } from "../content/icons/handicap.svg";
import { ReactComponent as CoatCheck } from "../content/icons/coatCheck.svg";
import { ReactComponent as Info } from "../content/icons/info.svg";
import { ReactComponent as Medics } from "../content/icons/medics.svg";
import { ReactComponent as Restroom } from "../content/icons/restroom.svg";
import { ReactComponent as RestroomW } from "../content/icons/restroomW.svg";
import { ReactComponent as RestroomM } from "../content/icons/restroomM.svg";
import { ReactComponent as Sanitizer } from "../content/icons/sanitizer.svg";
import { ReactComponent as Charge } from "../content/icons/charge.svg";
import { ReactComponent as FireHose } from "../content/icons/fireHose.svg";
import { ReactComponent as ElectricalOutlet } from "../content/icons/electricalOutlet.svg";
import { ReactComponent as Ducts } from "../content/icons/ducts.svg";
import { ReactComponent as RectangleTable4 } from "../content/icons/rectangleTable4.svg";
import { ReactComponent as EntranceArrow } from "../content/icons/entranceArrow.svg";
import { ReactComponent as YouAreHere } from "../content/icons/youAreHere.svg";
import { ReactComponent as Truck } from "../content/icons/truck.svg";
import { ReactComponent as ForwardArrow } from "../content/icons/forward-arrow.svg";
import { ReactComponent as Left } from "../content/icons/left.svg";
import { ReactComponent as Right } from "../content/icons/right.svg";
import { ReactComponent as Up } from "../content/icons/up.svg";
import { ReactComponent as Down } from "../content/icons/down.svg";
import { ReactComponent as Ok } from "../content/icons/ok.svg";
import { ReactComponent as DiagonalDownRight } from "../content/icons/diagonal-down-right-arrow.svg";
import { ReactComponent as DiagonalLeftRight } from "../content/icons/diagonal-left-right-arrow.svg";
import { ReactComponent as DiagonalUpLeft } from "../content/icons/diagonal-up-left-arrow.svg";
import { ReactComponent as DiagonalUpRight } from "../content/icons/diagonal-up-right-arrow.svg";
import { ReactComponent as DownArrow } from "../content/icons/down-arrow.svg";
import { ReactComponent as UpArrow } from "../content/icons/up-arrow.svg";
import { ReactComponent as LeftArrow } from "../content/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../content/icons/right-arrow.svg";
import { ReactComponent as LeftDownArrow } from "../content/icons/left-down-arrow.svg";
import { ReactComponent as LeftDownArrow1 } from "../content/icons/left-down-arrow1.svg";
import { ReactComponent as UpRightArrow } from "../content/icons/up-right-arrow.svg";
import { ReactComponent as UpRightArrow1 } from "../content/icons/up-right-arrow1.svg";

const components = [
  {
    meetingArea: MeetingArea,
    conference: Conference,
    registration: Registration,
  },
  {
    food: Food,
    coffee: Coffee,
  },
  {
    entrance: Entrance,
    exit: Exit,
    emergencyExit: EmergencyExit,
    elevator: Elevator,
    escalator: Escalator,
    stairs: Stairs,
    handicap: Handicap,
    coatCheck: CoatCheck,
    column: Column,
    truck: Truck,
  },
  {
    info: Info,
    medics: Medics,
    restroom: Restroom,
    restroomW: RestroomW,
    restroomM: RestroomM,
    sanitizer: Sanitizer,
    charge: Charge,
    fireHose: FireHose,
    electricalOutlet: ElectricalOutlet,
    ducts: Ducts,
  },
  {
    rectangleTable4: RectangleTable4,
    entranceArrow: EntranceArrow,
    youAreHere: YouAreHere,
    forwardArrow: ForwardArrow,
    left: Left,
    right: Right,
    up: Up,
    down: Down,
    diagonalLeftRight: DiagonalLeftRight,
    diagonalDownRight: DiagonalDownRight,
    diagonalUpLeft: DiagonalUpLeft,
    diagonalUpRight: DiagonalUpRight,
    leftArrow: LeftArrow,
    rightArrow: RightArrow,
    upArrow: UpArrow,
    downArrow: DownArrow,
    leftDownArrow: LeftDownArrow,
    leftDownArrow1: LeftDownArrow1,
    upRightArrow: UpRightArrow,
    upRightArrow1: UpRightArrow1,

    ok: Ok,
  },
];

const Icon = forwardRef(({ id, ...props }, ref) => {
  const flatten = components.reduce((prev, v) => ({ ...prev, ...v }), {});
  const founded = Object.keys(flatten).find((key) => key === id);

  if (!founded) {
    return null;
  }

  const Component = flatten[founded];

  return Component ? <Component ref={ref} {...props} /> : null;
});

const layerIcons = components.map((group) => {
  const section = { items: [] };
  section.items = Object.keys(group).map((key) => ({
    id: key,
    Component: forwardRef(({ ...props }, ref) => (
      <Icon ref={ref} id={key} {...props} />
    )),
  }));
  return section;
});

export default layerIcons;
