import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
 import { yup } from 'lib/yup';
import { AuthContext } from '../../context/AuthContext';
import { Routes as AppRoutes } from '../../router/RouteMapping';
 import SmartForm from 'components/SmartForm/SmartForm';
import SmartImage from 'components/SmartImage';
import Constants from '../../constants';

const MAX_SUBMIT_WITHOUT_CAPTCHA = 3;

const defaultValues = {
  email: process.env.REACT_APP_DEFAULT_USER_EMAIL || '',
  password: process.env.REACT_APP_DEFAULT_USER_PASSWORD || '',
};

const ALLOW_SIGNUP = false;

function Login() {
  const { login } = useContext(AuthContext);

  const fields = [
    {
      cols: [
        {
          key: 'email',
          apiKey: 'login',
          schema: yup.string().email().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'password',
          type: 'password',
          schema: yup.string().required(),
          label: (
            <Form.Label className="w-100" htmlFor="signin-password">
              <span className="d-flex justify-content-between align-items-center">
                <span>
                  <FormattedMessage id="app.common.password" />
                </span>
                <Link
                  to={AppRoutes.auth.FORGOT_PASSWORD.path}
                  className="form-label-link mb-0"
                >
                  <FormattedMessage id="app.common.forgotPassword" />
                </Link>
              </span>
            </Form.Label>
          ),
        },
      ],
    },
  ];

  const onRequestSuccess = (data) => {
    const payload = {
      user: data.Result,
      token: data.Result.token,
      role:
        Constants.User.Types[data.Result.user_type]?.id ||
        Constants.User.Types[16].id,
    };
    login(payload);
  };

  return (
    <div
      className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
      style={{ maxWidth: '25rem' }}
    >
      <div className="text-center">
        <div className="mb-5">
          <h1 className="display-5">
            <FormattedMessage id="app.common.signIn" />
          </h1>
          {ALLOW_SIGNUP && (
            <p>
              <FormattedMessage id="app.common.dontHaveAccount" />
              <Link to={AppRoutes.auth.SIGN_UP.path} className="link ms-1">
                <FormattedMessage id="app.common.signUpHere" />
              </Link>
            </p>
          )}
        </div>

        {Object.keys(Constants.AuthVendors).length > 0 && (
          <>
            <div className="d-grid gap-2 mb-4">
              {Object.values(Constants.AuthVendors).map((vendor) => (
                <Button key={vendor.id} variant="white" size="lg">
                  <span className="d-flex justify-content-center align-items-center">
                    <SmartImage
                      src={vendor.icon.default}
                      darkSrc={vendor.icon.dark || null}
                      className="avatar avatar-xss me-2"
                    />
                    <FormattedMessage
                      id={`app.common.signInWith${capitalize(vendor.id)}`}
                    />
                  </span>
                </Button>
              ))}
            </div>

            <span className="divider-center text-muted text-uppercase mb-4">
              <FormattedMessage id="app.common.or" />
            </span>
          </>
        )}
      </div>

      <SmartForm
        fields={fields}
        requestUrl="/user/login"
        onRequestSuccess={onRequestSuccess}
        defaultValues={{ ...defaultValues, type: '' }}
        submitButtonText="signIn"
        withCaptcha={MAX_SUBMIT_WITHOUT_CAPTCHA}
      />
    </div>
  );
}

export default Login;
