import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { yup } from 'lib/yup';


import RequestLoading from 'components/RequestLoading';
import RequestResult from 'components/RequestResult';
import SmartForm from 'components/SmartForm/SmartForm';
import useAxiosQuery from 'hooks/useAxiosQuery';

function BusinessInfoStep({ onNext, onPrev }) {
  const [formData, setFormData] = useState({});
  const fields = [
    {
      cols: [
        {
          key: 'businessName',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'businessWebsite',
          schema: yup.string().customUrl().required(),
        },
      ],
    },
  ];

  const {
    isFetching: apiLoading,
    error: apiError,
    refetch: apiFetch,
  } = useAxiosQuery({
    url: `https://seo.calidigi.com/service/detail/?url=${formData.businessWebsite}`,
    preventFetch: true,
    onSuccess: (data) => {
      onNext(formData, data);
    },
    onError: () => {
      onNext();
    },
  });

  const onSubmit = (data) => {
    setFormData(data);
  };

  useEffect(() => {
    if (formData.businessWebsite) {
      apiFetch();
    }
  }, [apiFetch, formData]);
  return (
    <Row className="justify-content-sm-center py-10">
      <Col sm="8">
        <RequestLoading
          loading={apiLoading}
          size="lg"
          margin="5"
          message="app.accountSetup.fetchingWebsiteInfoMsg"
        />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && (
          <SmartForm
            fields={fields}
            onSubmit={onSubmit}
            isWizard
            onWizardPrev={onPrev}
            submitButtonText="next"
          />
        )}
      </Col>
    </Row>
  );
}

BusinessInfoStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

BusinessInfoStep.defaultProps = {
  onNext: () => { },
  onPrev: () => { },
};

export default BusinessInfoStep;
