import PropTypes from 'prop-types';
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
	DEFAULT_TEXT_EMPTY_VALUE,
	DEFAULT_TEXT_STYLE,
	LAYER_TYPES,
} from '../consts';
  
  const isOverflown = ({
	clientWidth,
	clientHeight,
	scrollWidth,
	scrollHeight,
  }) => scrollWidth > clientWidth || scrollHeight > clientHeight;
  
  const Text = forwardRef(
	(
	  {
		style,
		step,
		minSize,
		maxSize,
		unit,
		innerText,
		contentEditable,
		...props
	  },
	  ref
	) => {
	  const textRef = useRef();
	  const [fontSize, setFontSize] = useState();
	  const [isFocus, setIsFocus] = useState(false);
  
	  const { formatMessage } = useIntl();
  
	  useImperativeHandle(ref, () => textRef.current, []);
  
	  const resizeText = useCallback(() => {
		let i = minSize;
		let overflow = false;
  
		const text = textRef.current;
		if (!text?.innerText) {
		  return;
		}
  
		while (!overflow && i < maxSize) {
		  setFontSize(`${i}${unit}`);
		  overflow = text ? isOverflown(text) : false;
  
		  if (!overflow) i += step;
		}
  
		// revert to last state where no overflow happened
		setFontSize(`${i - step}${unit}`);
	  }, [step, minSize, maxSize, unit]);
  
	  useEffect(() => {
		const text = textRef.current;
  
		const observer = new ResizeObserver(resizeText);
  
		observer.observe(text);
		text.addEventListener('input', resizeText);
  
		return () => {
		  observer.disconnect();
		  text.removeEventListener('input', resizeText);
		};
	  }, [resizeText]);
  
	  const clearSelection = () => {
		if (window.getSelection) {
		  if (window.getSelection().empty) {
			// Chrome
			window.getSelection().empty();
		  } else if (window.getSelection().removeAllRanges) {
			// Firefox
			window.getSelection().removeAllRanges();
		  }
		} else if (document.selection) {
		  // IE?
		  document.selection.empty();
		}
	  };
  
	  const onFocus = () => {
		setIsFocus(true);
	  };
  
	  const onBlur = () => {
		setIsFocus(false);
		clearSelection();
		if (textRef.current.innerText === '') {
		  textRef.current.innerText = formatMessage({
			id: `app.common.${DEFAULT_TEXT_EMPTY_VALUE}`,
		  });
		  setTimeout(() => {
			resizeText();
		  }, 1);
		}
	  };
  
	  return (
		<div
		  ref={textRef}
		  data-type={LAYER_TYPES.text}
		  style={{
			...style,
			fontSize,
			cursor: isFocus ? 'text' : 'default',
			overflow: 'hidden',
		  }}
		  contentEditable={contentEditable}
		  suppressContentEditableWarning
		  spellCheck={false}
		  onFocus={onFocus}
		  onBlur={onBlur}
		  {...props}
		>
		  {innerText ||
			formatMessage({ id: `app.common.${DEFAULT_TEXT_EMPTY_VALUE}` })}
		</div>
	  );
	}
  );
  
  Text.propTypes = {
	style: PropTypes.objectOf(PropTypes.any),
	step: PropTypes.number,
	minSize: PropTypes.number,
	maxSize: PropTypes.number,
	unit: PropTypes.oneOf(['px', 'em', 'rem']),
	innerText: PropTypes.string,
	contentEditable: PropTypes.bool,
  };
  
  Text.defaultProps = {
	style: DEFAULT_TEXT_STYLE,
	step: 1,
	minSize: 1,
	maxSize: 512,
	unit: 'px',
	innerText: null,
	contentEditable: true,
  };
  
  Text.displayName = 'Text';
  
  export default Text;
  