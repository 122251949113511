import React from 'react';
import PropTypes from 'prop-types';
// import LogoPrimary from '../assets/images/svg/logos/logo.svg';
// import LogoWhite from '../assets/images/svg/logos/logo-white.svg';
import SmartImage from './SmartImage';

const brandName = process.env.REACT_APP_SITE_TITLE || '';

function Logo({ variant, ...props }) {
  if (variant) {
    return (
    //   <SmartImage
    //     src={variant === 'light' ? LogoWhite : LogoPrimary}
    //     alt={brandName}
    //     {...props}
    //   />
    <h1>Logo</h1>
    );
  }

  return (
    // <SmartImage
    //   src={LogoPrimary}
    //   darkSrc={LogoWhite}
    //   alt={brandName}
    //   {...props}
    // />
    <h1>Logo</h1>
  );
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['primary', 'light']),
};

Logo.defaultProps = {
  variant: null,
};

export default Logo;
