import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import SmartImage from 'components/SmartImage'; 
import Illustration from '../../../../assets/images/svg/illustrations/oc-collaboration.svg';
import IllustrationLight from '../../../../assets/images/svg/illustrations-light/oc-collaboration.svg';

function WelcomeStep({ onNext }) {
  return (
    <Row className="justify-content-sm-center text-center py-10">
      <Col sm="4">
        <SmartImage
          className="img-fluid mb-5"
          src={Illustration}
          darkSrc={IllustrationLight}
        />
        <h1>
          <FormattedMessage id="app.accountSetup.welcome.title" />
        </h1>
        <p>
          <FormattedMessage id="app.accountSetup.welcome.message" />
        </p>
        <Button
          onClick={() => {
            onNext();
          }}
        >
          <FormattedMessage id="app.accountSetup.welcome.button" />
        </Button>
      </Col>
    </Row>
  );
}

WelcomeStep.propTypes = {
  onNext: PropTypes.func,
};

WelcomeStep.defaultProps = {
  onNext: () => {},
};

export default WelcomeStep;
