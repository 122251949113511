//satış için
import { useStoreStateSetValue, useStoreStateValue } from "@scena/react-store";


import { useContext, useEffect, useState } from "react";
import {
	Badge,
	Button,
	Form,
	InputGroup,
	ListGroup
} from "react-bootstrap";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";

import { LangContext } from 'context/LangContext';


import useToast from "hooks/useToast";
import {
	$editor,
	$iframeUrl,
	$layerManager,
	$searchResults,
	$searchResultsIndex,
	$selectedBoothAreaData,
	$showSellModal,
	$unitType
} from "../stores/stores";

import RequestLoading from "components/RequestLoading";
import RequestResult from "components/RequestResult";
import useAxiosQuery from "hooks/useAxiosQuery";
import { DEFAULT_BOOTH_COLORS, LAYER_TYPES } from "../consts";

const createBg = (status) => {
	return DEFAULT_BOOTH_COLORS[status].background;
};


const tuyapService = "https://test.tuyap.online/";
const constGuid = "139";
const testGuid = "99999";
var innerUrl =
	"http://10.10.1.80/ARI/dist3/ARI/onayphp/DsTeklif/index.php?main=";
const mobileUrl =
	"https://forms.tuyap.online/TuyapMobil/Tuyap360/MobilDs/index.php?main=";

const onImageError = (e) => {
	e.target.src = PlaceHolderImg;
};

const socialMap = [
	{ icon: 'bi-linkedin', apiKey: 'Linkedin', url: 'https://www.linkedin.com/in/' },
	{ icon: 'bi-facebook', apiKey: 'Facebook', url: 'https://www.facebook.com/' },
	{ icon: 'bi-twitter', apiKey: 'Twitter', url: 'https://twitter.com/' },
	{ icon: 'bi-instagram', apiKey: 'Instagram', url: 'https://www.instagram.com/' },
	{ icon: 'bi-youtube', apiKey: 'Youtube', url: 'https://www.youtube.com/@' },
];

function SelectedItemView({ data, sellerData }) {
	const { showToast } = useToast();
	const { guid, DsFairId } = useParams();

	const { currentLanguage } = useContext(LangContext);

	console.log(data)

	const {
		data: apiData,
		isLoading: apiLoading,
		error: apiError,
	} = useAxiosQuery({
		url: "service/katilimci/detail",
		preventFetch: !data.exhibitor.value,
		params: {
			id: data.exhibitor.value,
			"mapid": guid,
			"FuarID": DsFairId,
			"Lang": currentLanguage,
			"stant": data.title,

		},
	});


	const foundedItems = [];
	data.layers.forEach((item) =>
		item.fpeData?.exhibitors?.forEach(
			(nItem) =>
				nItem.value.toString() === data.exhibitor.value.toString() &&
				foundedItems.push(item)
		)
	);

	const setIframe = useStoreStateSetValue($iframeUrl);

	const showSellModal = useStoreStateSetValue($showSellModal);

	const booth = useStoreStateValue($selectedBoothAreaData);

	const { isMobilVersion } = useParams();

	var budgetUrl = tuyapService + "index.html?firmaId=";
	if (sellerData.guid === testGuid) {
		budgetUrl = tuyapService + "ui-index.html?firmaId=";
	}
	// alert(sellerData.guid );

	const createBudget = (setIframe, sellerData) => {
		// console.log(booth?.layers);
		var result =
			booth &&
			booth.layers &&
			booth.layers.find((item) => {
				return (
					item.title &&
					booth.title &&
					item.title.toString() === booth.title.toString()
				);
			});

		var firmaId = booth?.exhibitor[0]?.value || booth?.exhibitor.value;
		var planId = result?.id
			? result.id.replace("floorplan-id-", "")
			: result?.id;
		var dimensions = booth?.additionalData;
		var sellerId = sellerData?.SellerId;

		// console.log(dimensions);
		try {
			var stantid = booth?.title;
			var id = stantid.split("-");
			var w = dimensions?.width.toString();
			var h = dimensions?.height.toString();
			if (typeof w === 'string' && w.includes("px")) {
				w = w.replace("px", "");
			}

			if (typeof h === 'string' && h.includes("px")) {
				h = h.replace("px", "");
			}
			var ext =
				firmaId +
				"&StantId=" +
				id[1] +
				"&w=" +
				w +
				"&h=" +
				h +
				"&mapId=" +
				data.mapId +
				"&SorumluId=" +
				sellerId +
				"&Token=" +
				sellerData?.TuyapToken +
				"&DSFuarId=" +
				sellerData?.DsFairId +
				"&BothId=" +
				planId +
				"&SalonId=" +
				id[0];
			var url = budgetUrl + ext;

			if (sellerData.isNew == 1) {
				setIframe(url);
				showSellModal(true);
			} else {
				if (isMobilVersion == 1) {
					innerUrl = mobileUrl;
				}
				budgetUrl = innerUrl + "connectTeklif&firmaId=" + ext;
				window.open(budgetUrl, "_blank");
			}
		} catch (error) {
			console.log(error);
			showToast({
				title: "Stant İsimlendirme Hatası",
				message: "Hatalı stant isimlendirmesi.İşlem yapılamıyor ",
			});
		}
	};
	var width = 0;
	var height = 0;
	var M2Value = 0;
	try {
		width = data?.additionalData?.width?.toString().replace("px", "");
		height = data?.additionalData?.height?.toString().replace("px", "");
		M2Value = Math.round(width) * Math.round(height);
	} catch (error) {
		showToast({
			title: "Stant metrekare Hatası",
			message: data.additionalData,
		});
	}

	function formatPhone(phone) {
		let yeniVeri = phone.replace(/\//g, '');
		// Eğer başlangıç "00" ise "+0" olarak değiştir
		if (yeniVeri.startsWith('00')) {
			yeniVeri = '+' + yeniVeri.substring(2);
		}
		return yeniVeri;
	}

	function formatWeb(web) {
		if (web.startsWith('w')) {
			web = 'http://' + web;
		}
		return web;
	}

	return (
		<div>
			<RequestLoading loading={apiLoading} size="lg" margin="5" />
			<RequestResult type="error" message={apiError} />

			<div className="d-flex flex-column px-3 mt-1">
				<h5>
					{!apiLoading && !apiError && apiData && (apiData.FullName)}
				</h5>


				{(booth?.fpeData?.status === "sold") && (
					<Button
						size="sm"
						variant="primary"
						className="d-block"
						onClick={() => createBudget(setIframe, sellerData)}
					>
						<i className="my-1 bi bi-box-arrow-up-right"> </i>
						Ek Sözleşme Oluştur
					</Button>
				)}

				{(booth?.fpeData?.status !== "sold" || booth?.fpeData?.status === "avail") && (
					<Button
						size="sm"
						variant="primary"
						className="d-block"
						onClick={() => createBudget(setIframe, sellerData)}
					>
						<i className="my-1 bi bi-box-arrow-up-right"> </i>
						Teklif Oluştur

					</Button>
				)}

				{apiData?.sozlesmeler && apiData.sozlesmeler.length > 0 && (
					<div className="mt-2">
						{apiData.sozlesmeler.map((item, index) => (
							<Button size="sm" key={index}
								type="button"
								variant="primary"
								className="d-block mb-1" target="_blank" href={item}>

								<i className="my-1 bi bi-eye-fill"></i>	Sözleşme Görüntüle  </Button>
						))}
					</div>
				)} 

				<div className="mt-3 d-flex align-items-center " data-status={booth?.fpeData?.status}>
					{foundedItems.map((item) => (
						<Badge
							key={item.id}
							bg=""
							className="me-1 mb-1 d-flex w-100"
							style={{ backgroundColor: createBg('avail') }}
						>
							<h5 className="d-flex m-1">
								Stant No : {` ${item.title}`}
							</h5>
						</Badge>
					))}
				</div>
				<div className="mt-3 d-flex align-items-center">
					<Badge
						key="asa"
						bg=""
						className="me-1 mb-1 d-flex w-100 "
						style={{ backgroundColor: createBg('avail') }}
					>
						<div className="d-flex">
							<h5 className="d-flex m-1">
								Stant Bilgileri : {`${width}m x ${height}m = ${M2Value}M2`}
							</h5>
						</div>
					</Badge>
				</div>
				{data?.fpeData?.user?.label && (
					<div className="mt-3 d-flex align-items-center">
						<Badge
							key="asa"
							bg=""
							className="me-1 mb-1 d-flex w-100"
							style={{ backgroundColor: createBg('avail') }}
						>
							<div className="d-flex">
								<h5 className="d-flex m-1" >
									Temsilci :
									{data?.fpeData?.user?.label
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")}
								</h5>
							</div>
						</Badge>
					</div>
				)}
				{!apiLoading && !apiError && apiData && (
					<div className="d-flex flex-column px-1 mt-1">

						{apiData.CatLabels && apiData.CatLabels.length > 0 && (
							<div className="mt-2">
								{apiData.CatLabels.map((item, index) => (
									<Badge bg="secondary" className="me-1 mb-1" key={index}>
										{item.grup}
									</Badge>
								))}
							</div>
						)}

						<div className="mt-3">
							{apiData.FirmLogo && (
								<span className="avatar avatar-xl float-start me-2">
									<img
										src={apiData.FirmLogo}
										className="avatar-img"
										onError={onImageError}
									/>
								</span>
							)}
							<div dangerouslySetInnerHTML={{ __html: apiData.About }} />
						</div>
						<hr />
						<div>
							<ListGroup variant="flush">
								{apiData.FirmAddress && (
									<ListGroup.Item className="d-flex px-0">
										<i className="bi-geo-alt me-2" />
										<span>{apiData.FirmAddress}</span>
									</ListGroup.Item>
								)}
								{apiData.Phone && (
									<ListGroup.Item className="d-flex px-0">
										<i className="bi-telephone me-2" />
										<a
											href={`tel:${formatPhone(apiData.Phone)}`}
											target="_blank"
											rel="noreferrer"
										>
											{formatPhone(apiData.Phone)}
										</a>
									</ListGroup.Item>
								)}
								{apiData.Web && (
									<ListGroup.Item className="d-flex px-0">
										<i className="bi-globe me-2" />
										<a
											href={`${formatWeb(apiData.Web)}`}
											target="_blank"
											rel="noreferrer"
										>
											{apiData.Web}
										</a>
									</ListGroup.Item>
								)}
							</ListGroup>
						</div>
						<hr />
						<div>
							{socialMap.map((item) => {
								if (apiData[item.apiKey]) {
									return (
										<a
											key={item.apiKey}
											href={item.url + apiData[item.apiKey]}
											target="_blank"
											rel="noreferrer"
											className="me-2"
										>
											<i className={`${item.icon} fs-1`} />
										</a>
									);
								}
								return null;
							})}
						</div>
					</div>)}
				<hr />
			</div>
		</div>
	);


}

function SelectedEmptyItemView({ data, unitType, sellerData, setIframe }) {
	const showSellModal = useStoreStateSetValue($showSellModal);
	const { showToast } = useToast();
	const { isMobilVersion } = useParams();

	// console.log(data);

	var width = 0;
	var height = 0;
	var M2Value = 0;
	try {
		width = data?.additionalData?.width?.toString().replace("px", "");
		height = data?.additionalData?.height?.toString().replace("px", "");
		M2Value = Math.round(width) * Math.round(height);
	} catch (error) {
		showToast({
			title: "Stant metrekare Hatası",
			message: "Alan hesaplanamıyor " + data,
			autohide: false,
			type: "danger",
		});
	}

	return (
		<div className="d-flex flex-column px-3 mt-1">
			<Badge
				bg=""
				className="me-1 mb-1 d-flex w-100"
				style={{
					backgroundColor: createBg("avail"),
				}}
			>
				<h5 className="d-flex" style={{ margin: "4px" }}>
					Stant No : {` ${data?.additionalData?.title}`}
				</h5>
			</Badge>

			<div className="mt-3 d-flex align-items-center">
				<Badge
					key="asa"
					bg=""
					className="me-1 mb-1 d-flex w-100"
					style={{
						backgroundColor: createBg("avail"),
					}}
				>
					<div className="d-flex">
						<h5 className="d-flex" style={{ margin: "4px" }}>
							Stant Ölçüleri :{`${width}m x ${height}m = ${M2Value}M2`}
						</h5>
					</div>
				</Badge>
			</div>
			{data?.additionalData && (
				<Button
					size="sm"
					variant="danger"
					className="d-block mt-3"
					onClick={() =>
						addPortFoyLink(
							data,
							sellerData,
							setIframe,
							showSellModal,
							showToast,
							isMobilVersion
						)
					}
				>
					Portföye Ekleme Talebi Oluştur
				</Button>
			)}
		</div>
	);
}

const addPortFoyLink = (
	data,
	sellerData,
	setIframe,
	showSellModal,
	showToast,
	isMobilVersion
) => {
	var bothid = data?.title;
	var result = data?.layers.find((item) => {
		if (item.title == undefined) {
			return false;
		}
		if (bothid.toString().length === 1 || item.title.toString().length === 1) {
			return false;
		}
		return item.title.toString().includes(bothid.toString());
	});

	var planId = result?.id ? result.id.replace("floorplan-id-", "") : result?.id;
	try {
		var stantid = data.additionalData.title;
		var id = stantid.split("-");
		var w = data.additionalData?.width;
		var h = data.additionalData?.height;


		if (typeof w === 'string' && w.includes("px")) {
			w = w.replace("px", "");
		}

		if (typeof h === 'string' && h.includes("px")) {
			h = h.replace("px", "");
		}

		var ext =
			"StantId=" +
			id[1] +
			"&w=" +
			w +
			"&h=" +
			h +
			"&mapId=" +
			data.mapId +
			"&SorumluId=" +
			sellerData?.SellerId +
			"&Token=" +
			sellerData?.TuyapToken +
			"&DSFuarId=" +
			sellerData?.DsFairId +
			"&BothId=" +
			planId +
			"&SalonId=" +
			id[0];
		var url = tuyapService + "portfoy.html?" + ext;

		if (sellerData.guid === constGuid) {
			setIframe(url);
			showSellModal(true);
		} else {
			if (isMobilVersion == 1) {
				innerUrl = mobileUrl;
			}
			url = innerUrl + "connectPortfoy&" + ext;
			window.open(url, "_blank");
		}
	} catch (error) {
		console.log(error);
		showToast({
			title: "Stant isimlendirme Hatası",
			message: "Hatalı stant isimlendirmesi.İşlem yapılamıyor ",
			autohide: false,
			type: "danger",
		});
	}
};
function PreviewLeftToolbar({
	layoutMargin,
	mapId,
	searchValue,
	setSearchValue,
	sellerData
}) {
	const { formatMessage } = useIntl();

	const layerManager = useStoreStateValue($layerManager);
	const editorRef = useStoreStateValue($editor);
	const unitType = useStoreStateValue($unitType);
	const setSearchResults = useStoreStateSetValue($searchResults);
	const setSearchResultsIndex = useStoreStateSetValue($searchResultsIndex);
	const [listItems, setListItems] = useState([]);

	const handleSearch = (text) => {
		setSearchValue(text);

		const foundedItems = layerManager.layers.filter((item) => {
			const title = `${item.title}`.toLowerCase();

			const txt = `${text}`.toLowerCase();

			let isFounded = false;

			if (title.indexOf(txt) > -1) {
				isFounded = true;
			} else {
				if (item.fpeData && item.fpeData.exhibitors) {
					const filteredExhibitors = item.fpeData.exhibitors.filter(
						(exhibitor) => `${exhibitor.label}`.toLowerCase().indexOf(txt) > -1
					);

					if (filteredExhibitors.length > 0) {
						isFounded = true;
					}
				}
			}

			return isFounded;
		});

		setListItems(foundedItems);
	};

	const onMouseOut = () => {
		editorRef.current.clearSearch();
	};

	const searchLayers = (text, options) => {
		editorRef.current.setSelectedLayers([]);
		if (!text) {
			editorRef.current.clearSearch();
			return;
		}

		const foundedItems = layerManager.layers.filter((item) => {
			const isFounded =
				`${item.title}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1 ||
				item.fpeData?.exhibitors?.filter(
					(nItem) =>
						`${nItem.label}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1
				).length > 0;
			return isFounded;
		});

		setSearchResultsIndex(0);
		setSearchResults(foundedItems);
		editorRef.current.highlightLayer(foundedItems, 0, options);
	};

	const onMouseOver = (item) => {
		searchLayers(item.title, { opacity: 0.1, scroll: false, markAll: false });
	};

	const setSelectedBoothAreaData = useStoreStateSetValue(
		$selectedBoothAreaData
	);

	const areaData = useStoreStateValue($selectedBoothAreaData);

	const onClick = (item, exhibitor) => {
		searchLayers(item.title, { opacity: 1, scroll: true });

		const data = {

			title: item.title,
			mapId,
			exhibitor,
			layers: layerManager.layers,
			additionalData: {
				title: item.title,
				width: item.jsx?.props?.style?.width,
				height: item.jsx?.props?.style?.height,
			},
			fpeData: {
				status: item.fpeData?.status,
				user: item.fpeData?.user,
			},
		};

		setSelectedBoothAreaData(data);
	};
	const setIframe = useStoreStateSetValue($iframeUrl);

	function gotoBack() {
		window.history.back();
		// if (isMobilVersion) {
		//   window.history.back();
		//   https://forms.tuyap.online/TuyapMobil/Tuyap360/MobilDs/index.php?main=expomapTeklifOlustur
		// }
	}

	useEffect(() => {
		setListItems(layerManager.layers);
	}, [layerManager.layers]);

	useEffect(() => {
		handleSearch(searchValue);
	}, [searchValue]);
	return (
		<>
			<div className="position-relative overflow-hidden w-100">
				{areaData ? (
					<SimpleBar
						style={{
							width: "100%",
							height: `calc(100vh - ${layoutMargin}px)`,
						}}
					>
						<>
							<div className="d-flex align-items-center justify-content-end m-2">
								<Button
									variant="ghost-secondary"
									size="sm"
									className="btn-icon btn-no-focus rounded-circle me-1"
									onClick={() => {
										setSelectedBoothAreaData(null);
									}}
								>
									<i className="bi-x-lg" />
								</Button>
							</div>
							{areaData?.exhibitor ? (
								<SelectedItemView data={areaData} sellerData={sellerData} />
							) : (
								<SelectedEmptyItemView
									unitType={unitType}
									data={areaData}
									sellerData={sellerData}
									setIframe={setIframe}
								/>
							)}
						</>
					</SimpleBar>
				) : (
					<>
						<div className="d-flex justify-content-between aling-items-center">
							<img
								src="https://forms.tuyap.online/TuyapMobil/assets/images/logo-light-ds.png"
								style={{ width: "170px", objectFit: "contain" }}
								alt=""
							/>

							<span
								className="d-flex align-items-center"
								onClick={() => gotoBack()}
								style={{ color: "#065542" }}
							>
								<i className="bi-arrow-left-circle-fill fs-1 p-1"></i>
								<span style={{ paddingRight: "10px" }}>Geri Dön</span>
							</span>
						</div>
						<div className="d-flex p-2">
							<InputGroup className="input-group-merge input-group-borderless w-90">
								<InputGroup.Text className="input-group-prepend">
									<i className="bi-search" />
								</InputGroup.Text>
								<Form.Control
									type="search"
									placeholder={formatMessage({
										id: "app.common.search",
									})}
									value={searchValue}
									onChange={(e) => {
										setSearchValue(e.target.value);
									}}
								/>
							</InputGroup>
							<div className="d-flex align-items-center justify-content-end m-2">
								<Button
									variant="ghost-secondary"
									size="sm"
									className="btn-icon btn-no-focus rounded-circle me-1"
									onClick={() => {
										setSelectedBoothAreaData(null);
									}}
								>
									<i className="bi-x-lg" />
								</Button>
							</div>
						</div>
						<SimpleBar
							style={{
								width: "100%",
								height: `calc(100vh - ${layoutMargin + 57}px)`,
							}}
						>
							{listItems.length === 0 && (
								<RequestResult
									type="secondary"
									message="app.common.noResultsFound"
									className="mx-2"
								/>
							)}
							<ListGroup>
								{listItems.map((layer) => {
									if (layer.type === LAYER_TYPES.booth) {
										return layer.fpeData?.exhibitors?.length > 0 ? (
											layer.fpeData.exhibitors.map((exhibitor) => (
												<ListGroup.Item
													key={`${layer.id}_${exhibitor?.value}`}
													onMouseOut={onMouseOut}
													onMouseOver={() => {
														onMouseOver(layer);
													}}
													onClick={() => {
														onMouseOut();
														onClick(layer, exhibitor);
													}}
													style={{ cursor: "pointer" }}
												>
													<div className="d-flex align-items-start justify-content-between flex-column">
														<div>
															<b>Firma : </b>
															{exhibitor.label}
														</div>
														<div>
															<b>Temsilci : </b>
															{layer.fpeData?.user?.label}
														</div>
														<div>
															<b>Stant No : </b>
															<Badge
																bg=""
																style={{
																	backgroundColor: createBg(
																		layer.fpeData.status
																	),
																}}
															>
																{layer.title}
															</Badge>
														</div>
													</div>
												</ListGroup.Item>
											))
										) : (
											<ListGroup.Item
												key={`${layer.id}`}
												onMouseOut={onMouseOut}
												onMouseOver={() => {
													onMouseOver(layer);
												}}
												onClick={() => {
													onMouseOut();
													onClick(layer, null);
												}}
												style={{ cursor: "pointer" }}
											>
												<div className="d-flex align-items-start justify-content-between flex-column">
													<div>
														<b>Stant No : </b>
														<Badge
															bg=""
															style={{
																backgroundColor: createBg(layer.fpeData.status),
															}}
														>
															{layer.title}
														</Badge>
													</div>
												</div>
											</ListGroup.Item>
										);
									}
									if (
										searchValue &&
										layer.type === LAYER_TYPES.booth &&
										(!layer.fpeData?.exhibitors ||
											layer.fpeData?.exhibitors?.length === 0)
									) {
										return (
											<ListGroup.Item
												key={layer.id}
												onMouseOut={onMouseOut}
												onMouseOver={() => {
													onMouseOver(layer);
												}}
												onClick={() => {
													onMouseOut();
													onClick(layer);
												}}
												style={{ cursor: "pointer" }}
											>
												<div className="d-flex align-items-center justify-content-between">
													<div>{layer.title}</div>
												</div>
											</ListGroup.Item>
										);
									}
									return null;
								})}
							</ListGroup>
						</SimpleBar>
					</>
				)}
			</div>
		</>
	);
}

export default PreviewLeftToolbar;
