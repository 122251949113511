import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import SmartImage from './SmartImage';
import  Illustration from '../assets/images/svg/illustrations/oc-browse.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-browse.svg';
import Utils from '../utils';
import PlaceHolderImg from '../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function FormDropzoneInput({
  disabled,
  onChange,
  options,
  isInvalid,
  isValid,
}) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles);

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: options.multiple,
    accept: options.accept,
    maxFiles: options.maxFiles,
    disabled,
    onDrop,
  });

  const thumbs = files.map((file) => (
    <div key={file.name} className="col-4 mt-3 dz-image-preview">
      <div className="dz-preview dz-file-preview h-100 p-2">
        <div className="d-flex justify-content-end dz-close-icon d-none">
          <small className="bi-x" data-dz-remove />
        </div>
        <div className="dz-details d-flex mb-0">
          <div className="dz-img flex-shrink-0 ratio ratio-1x1">
            <Image
              fluid
              className="dz-img-inner h-100"
              style={{ objectFit: 'cover' }}
              data-dz-thumbnail
              src={file.preview}
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
              onError={onImageError}
            />
          </div>
          <div className="dz-file-wrapper flex-grow-1">
            <h6 className="dz-filename text-wrap">
              <span className="dz-title text-primary" data-dz-name>
                {file.name}
              </span>
            </h6>
            <div className="dz-size" data-dz-size>
              {Utils.String.humanFileSize(file.size)}
            </div>
          </div>
        </div>
        <div className="dz-progress progress d-none">
          <div
            className="dz-upload progress-bar bg-success"
            role="progressbar"
            style={{ width: 0 }}
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
            data-dz-uploadprogress
          />
        </div>
        <div className="d-flex align-items-center d-none">
          <div className="dz-success-mark">
            <span className="bi-check-lg" />
          </div>
          <div className="dz-error-mark">
            <span className="bi-x-lg" />
          </div>
          <div className="dz-error-message">
            <small data-dz-errormessage />
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div
      {...getRootProps()}
      className={`form-control js-dropzone dz-dropzone dz-dropzone-card row mx-0 ${
        isInvalid ? 'is-invalid' : ''
      } ${isValid ? 'is-valid' : ''} ${isDragActive ? 'border-primary' : ''}`}
    >
      <input {...getInputProps()} />
      <div className="dz-message col-12">
        <SmartImage
          className="avatar avatar-xl avatar-4x3 mb-3"
          src={Illustration}
          darkSrc={IllustrationLight}
        />

        <h5>
          <FormattedMessage id="app.common.dragAndDropYourFilesHere" />
        </h5>

        <p className="mb-2">
          <FormattedMessage id="app.common.or" />
        </p>

        <Button
          as="span"
          variant="white"
          size="sm"
          className={disabled ? 'disabled' : ''}
        >
          <FormattedMessage id="app.common.browseFiles" />
        </Button>
      </div>
      {thumbs}
    </div>
  );
}

FormDropzoneInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    multiple: PropTypes.bool,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxFiles: PropTypes.number,
  }),
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
};

FormDropzoneInput.defaultProps = {
  disabled: false,
  onChange: () => {},
  options: { multiple: false, accept: undefined, maxFiles: undefined },
  isInvalid: null,
  isValid: null,
};

export default FormDropzoneInput;
