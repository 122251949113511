import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
  import Constants from '../../../../constants';
  import { yup } from 'lib/yup';
 

    import SmartForm from 'components/SmartForm/SmartForm';
    
function BusinessContactInfoStep({ onNext, onPrev, defaultValues }) {
  const formRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fields = [
    {
      cols: [
        {
          key: 'address',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'city',
          schema: yup.string().required(),
        },
        {
          key: 'state',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: Constants.States.map(({ name, abbreviation }) => ({
                  label: name,
                  value: abbreviation,
                })),
              },
            },
          },
          schema: yup.string().required(),
        },
        {
          key: 'zip',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'phone',
          schema: yup.string().required(),
        },
      ],
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitted(true);
    onNext(data);
  };

  useEffect(() => {
    if (!isSubmitted) {
      formRef.current.reset(defaultValues);
    }
  }, [isSubmitted, defaultValues]);

  return (
    <Row className="justify-content-sm-center py-10">
      <Col sm="8">
        <SmartForm
          ref={formRef}
          fields={fields}
          onSubmit={onSubmit}
          isWizard
          onWizardPrev={onPrev}
          submitButtonText="next"
          defaultValues={defaultValues}
        />
      </Col>
    </Row>
  );
}

BusinessContactInfoStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  defaultValues: PropTypes.objectOf(PropTypes.any),
};

BusinessContactInfoStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  defaultValues: null,
};

export default BusinessContactInfoStep;
