import React, {
  useReducer,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import flatpickr from 'flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr';

import moment from 'moment';
import 'moment/locale/tr';

import { AuthContext } from './AuthContext';

import Utils from '../utils';

import messagesEn from '../lang/en.json';
import messagesTr from '../lang/tr.json';

import termsEn from '../lang/terms-en.json';
import termsTr from '../lang/terms-tr.json';

const messages = {
  en: { ...messagesEn, ...termsEn },
  tr: { ...messagesTr, ...termsTr },
};

const momentLangs = {
  tr: 'tr',
};

const flatpickrLangs = {
  tr: Turkish,
};

flatpickr.l10ns.default.rangeSeparator = ' - ';

const languages = {
  en: 'English',
  tr: 'Türkçe',
  /* fr: 'Français',
  ru: 'P??????',
  tr: 'Türkçe',
  cn: '??', */
};

const getDefaultLang = () => {
  const navLang =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  const shortLang = navLang.trim().split(/-|_/)[0];
  const founded = Object.keys(messages).find((key) => key === shortLang);

  return founded || 'en';
};

const defaultLocale = getDefaultLang();

const LangContext = createContext();

const initialState = {
  lang: defaultLocale,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      localStorage.setItem('lang', action.payload.lang);
      document.documentElement.lang = action.payload.lang;

      moment.locale(momentLangs[action.payload.lang] || 'en');
      flatpickr.localize(flatpickrLangs[action.payload.lang] || null);

      return {
        ...state,
        lang: action.payload.lang,
      };
    default:
      return state;
  }
};

function LangProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const lang = localStorage.getItem('lang');

    dispatch({
      type: 'SET',
      payload: {
        lang: lang || 'en',
      },
    });

    /* if (user && user.Country) {
      const l = user.Country.toLowerCase();
      dispatch({
        type: "SET",
        payload: {
          lang: l,
        },
      });
      setLoading(false);
      return;
    } */
    // setLoading(false);
  }, [user]);

  const changeLanguage = useCallback(
    (lang) => {
      dispatch({
        type: 'SET',
        payload: {
          lang,
        },
      });
    },
    [dispatch]
  );

  const value = useMemo(
    () => ({
      languages,
      currentLanguage: state.lang,
      changeLanguage,
    }),
    [state, changeLanguage]
  );

  return (
    <LangContext.Provider value={value}>
      <IntlProvider
        messages={Utils.Object.getFlattenObject(
          messages[state.lang] ? messages[state.lang] : messages[defaultLocale]
        )}
        locale={state.lang}
        defaultLocale={defaultLocale}
        onError={(err) => {
          if (err.code === 'MISSING_TRANSLATION') {
            // eslint-disable-next-line
            console.log(err.message);
            return;
          }
          throw err;
        }}
      >
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
}

LangProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LangContext, LangProvider };
