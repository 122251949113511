import React from "react";
import { FormattedMessage } from "react-intl";

import packageJson from "../../../package.json";
import Main from "../Theme/Footer/Main";

const brandLegalName =
  process.env.REACT_APP_SITE_LEGAL || process.env.REACT_APP_SITE_TITLE || "";

function DashboardFooter() {
  return (
    <Main>
      <>
        © {brandLegalName}.
        <span className="d-none d-sm-inline-block">
          <span className="ms-1">{new Date().getFullYear()}</span>
          <span className="ms-1">
            <FormattedMessage id="app.common.allRightsReserved" />
          </span>
        </span>
      </>
    </Main>
  );
}

export default DashboardFooter;
