import React, {
    // useState,
    useReducer,
    useEffect,
    createContext,
    useContext,
    useMemo,
    useCallback,
  } from 'react';
  import PropTypes from 'prop-types';
  
  import { AuthContext } from './AuthContext';
  
  import Utils from '../utils';
  
  const themeMap = {
    default: '/theme.css',
    dark: '/theme-dark.css',
  };
  
  const insertionPoint = null;
  const id = 'current-theme-style';
  const attr = 'data-theme';
  
  const getSystemTheme = () => {
    let theme = 'default';
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      theme = 'dark';
    }
  
    return theme;
  };
  
  const systemTheme = getSystemTheme();
  
  const ThemeContext = createContext();
  
  const initialState = {
    theme: null,
  };
  
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET':
        localStorage.setItem('theme', action.payload.theme);
        return {
          ...state,
          theme: action.payload.theme,
        };
      default:
        return state;
    }
  };
  
  function ThemeProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    // const [loading, setLoading] = useState(true);
  
    const { user } = useContext(AuthContext);
  
    const insertStyle = useCallback((linkElement) => {
      if (insertionPoint || insertionPoint === null) {
        const insertionPointElement = Utils.Dom.isElement(insertionPoint)
          ? insertionPoint
          : Utils.Dom.findCommentNode(insertionPoint);
  
        if (!insertionPointElement) {
          document.head.appendChild(linkElement);
          return;
        }
  
        const { parentNode } = insertionPointElement;
        if (parentNode) {
          parentNode.insertBefore(linkElement, insertionPointElement.nextSibling);
        }
      } else {
        document.head.appendChild(linkElement);
      }
    }, []);
  
    const switcher = useCallback(
      (theme) => {
        if (theme === state.theme) return;
  
        let nTheme = theme;
  
        if (nTheme === 'auto') {
          nTheme = systemTheme;
        }
  
        // setLoading(true);
  
        const linkElement = Utils.Dom.createLinkElement({
          type: 'text/css',
          rel: 'stylesheet',
          id: `${id}_temp`,
          href: themeMap[nTheme],
          onload: () => {
            const previousStyle = document.getElementById(id);
            if (previousStyle) {
              previousStyle.remove();
            }
  
            const nextStyle = document.getElementById(`${id}_temp`);
            if (nextStyle) {
              nextStyle.setAttribute('id', id);
            }
          },
        });
  
        insertStyle(linkElement);
  
        dispatch({
          type: 'SET',
          payload: {
            theme,
          },
        });
  
        document.body.setAttribute(attr, theme);
        // setLoading(false);
      },
      [insertStyle, state.theme]
    );
  
    const value = useMemo(
      () => ({
        themeState: state.theme,
        theme: state.theme === 'auto' ? systemTheme : state.theme,
        switcher,
      }),
      [state.theme, switcher]
    );
  
    useEffect(() => {
      let theme = localStorage.getItem('theme');
  
      if (!theme) {
        theme = 'auto';
      }
  
      if (theme !== 'auto' && theme !== 'dark') {
        theme = 'default';
      }
  
      switcher(theme);
    }, [switcher, user]);
  
    useEffect(() => {
      Object.keys(themeMap).forEach((theme) => {
        const themeAssetId = `theme-prefetch-${theme}`;
        if (!document.getElementById(themeAssetId)) {
          const stylePrefetch = document.createElement('link');
          stylePrefetch.rel = 'prefetch';
          stylePrefetch.type = 'text/css';
          stylePrefetch.id = themeAssetId;
          stylePrefetch.href = themeMap[theme];
  
          insertStyle(stylePrefetch);
        }
      });
    }, [insertStyle]);
  
    return (
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    );
  }
  
  ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  export { ThemeContext, ThemeProvider };
  