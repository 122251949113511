import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/css/theme.scss';
// import './assets/css/theme-dark.scss';
import App from './App';
 
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

 