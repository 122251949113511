//ikincil önem
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Moveable from 'react-moveable';
import {
  // useStoreState,
  useStoreStateValue,
  useStoreValue,
} from '@scena/react-store';
import { getContentElement } from '../utils/utils';
import { DimensionViewable } from './ables/DimensionViewable';
import { ControlViewable } from './ables/ControlViewable';
import {
  $actionManager,
  $layerManager,
  $editor,
  $historyManager,
  $horizontalGuidelines,
  $infiniteViewer,
  $layers,
  $memoryManager,
  $selectedTool,
  $selectedLayers,
  $selecto,
  $verticalGuidelines,
  $zoom,
  $pointer,
  $groupOrigin,
  $unitType,
} from '../stores/stores';
import { $alt, $shift } from '../stores/keys';
import { LAYER_TYPES } from '../consts';

const SNAP_DIRECTIONS = {
  top: true,
  left: true,
  right: true,
  center: true,
  middle: true,
  bottom: true,
};

export const MoveableManager = forwardRef(({ isRestricted }, ref) => {
  const isShift = useStoreStateValue($shift);
  // const isMeta = useStoreStateValue($meta);
  const altStore = useStoreValue($alt);

  // const [selectedTool, setSelectedTool] = useStoreState($selectedTool);
  const selectedTool = useStoreStateValue($selectedTool);
  const pointer = useStoreStateValue($pointer);

  const verticalGuidelines = useStoreStateValue($verticalGuidelines);
  const horizontalGuidelines = useStoreStateValue($horizontalGuidelines);
  const zoom = useStoreStateValue($zoom);
  const groupOrigin = useStoreStateValue($groupOrigin);
  const unitType = useStoreStateValue($unitType);

  const layers = useStoreStateValue($layers);
  const selectedLayers = useStoreStateValue($selectedLayers);

  const infiniteViewerRef = useStoreStateValue($infiniteViewer);
  const selectoRef = useStoreStateValue($selecto);
  const editorRef = useStoreStateValue($editor);

  const actionManager = useStoreStateValue($actionManager);
  const historyManager = useStoreStateValue($historyManager);
  const layerManager = useStoreStateValue($layerManager);
  const memoryManager = useStoreStateValue($memoryManager);

  const targetList = layerManager.toTargetList(selectedLayers);
  const selectedTargets = targetList.displayed();
  const visibleLayers = layerManager.filterDisplayedLayers(layers);
  const flattenSelectedLayers = layerManager.toFlatten(selectedLayers);
  const elementGuidelines = [
    '.floorplan-viewport',
    ...visibleLayers
      .filter((layer) => !flattenSelectedLayers.includes(layer))
      .map((layer) => layer.ref),
  ];

  const hasLocked = isRestricted || selectedLayers.find((item) => item.lock);

  const disableDimensionViewable =
    !(selectedTargets.length === 1) ||
    !(
      selectedTargets[0].dataset.type === LAYER_TYPES.booth ||
      selectedTargets[0].dataset.type === LAYER_TYPES.carpet ||
      selectedTargets[0].dataset.type === LAYER_TYPES.otherSpace ||
      selectedTargets[0].dataset.type === LAYER_TYPES.blockSpace ||
      selectedTargets[0].dataset.type === LAYER_TYPES.rectangle ||
      selectedTargets[0].dataset.type === LAYER_TYPES.circle
    );

  const disableControlViewable =
    hasLocked ||
    !(selectedTargets.length === 1) ||
    !(
      selectedTargets[0].dataset.type === LAYER_TYPES.booth ||
      selectedTargets[0].dataset.type === LAYER_TYPES.carpet ||
      selectedTargets[0].dataset.type === LAYER_TYPES.otherSpace ||
      selectedTargets[0].dataset.type === LAYER_TYPES.blockSpace
    );

  // console.log('isRestricted:', isRestricted);

  return (
    <Moveable
      ables={[DimensionViewable, ControlViewable]}
      ref={ref}
      target={selectedTargets}
      props={{
        dimensionViewable: !disableDimensionViewable,
        controlViewable: !disableControlViewable,
      }}
      draggable={!isRestricted && !hasLocked}
      useAccuratePosition
      useResizeObserver
      useMutationObserver
      origin={false}
      rotateAroundControls
      pinchable={['rotatable']}
      unitType={unitType}
      zoom={1 / zoom}
      edge
      throttleResize={1}
      clippable={selectedTool === 'crop'}
      passDragArea={selectedTool === 'text'}
      checkInput={selectedTool === 'text'}
      throttleDragRotate={isShift ? 45 : 0}
      throttleRotate={isShift ? 15 : 0}
      keepRatio={
        isShift ||
        selectedTargets.find((item) => item.dataset.type === LAYER_TYPES.icon)
      }
      resizable={pointer === 'move' && !hasLocked}
      scalable={pointer === 'scale' && !hasLocked}
      rotatable={!hasLocked}
      defaultGroupOrigin={groupOrigin}
      groupableProps={{
        keepRatio: true,
        clippable: false,
      }}
      snappable
      snapThreshold={1}
      snapGridWidth={1}
      snapGridHeight={1}
      snapDirections={SNAP_DIRECTIONS}
      elementSnapDirections={SNAP_DIRECTIONS}
      snapGap={false}
      snapRenderThreshold={1}
      isDisplayInnerSnapDigit
      snapDistFormat={(v) => `${v}${unitType}`}
      roundable={false}
      isDisplayShadowRoundControls
      roundPadding={10}
      roundClickable
      verticalGuidelines={verticalGuidelines}
      horizontalGuidelines={horizontalGuidelines}
      elementGuidelines={elementGuidelines}
      clipArea
      clipVerticalGuidelines={[0, '50%', '100%']}
      clipHorizontalGuidelines={[0, '50%', '100%']}
      clipTargetBounds
      defaultClipPath={memoryManager.get('crop') || 'inset'}
      scrollContainer={() => infiniteViewerRef.current.getContainer()}
      scrollThreshold={30}
      scrollThrottleTime={30}
      getScrollPosition={() => {
        const { current } = infiniteViewerRef;

        return [
          current.getScrollLeft({ absolute: true }),
          current.getScrollTop({ absolute: true }),
        ];
      }}
      onChangeTargets={() => {
        actionManager.act('changed.targets');
      }}
      onBeforeResize={(e) => {
        e.setFixedDirection(altStore.value ? [0, 0] : e.startFixedDirection);
      }}
      onDuplicate={(e) => {
        editorRef.current.duplicateLayer(e);
      }}
      onClick={(e) => {
        const target = e.inputTarget;

        if (e.isDouble && target.isContentEditable) {
          // setSelectedTool('text');
          const el = getContentElement(target);

          if (el) {
            el.focus();
          }
        } else if (e.isTrusted) {
          selectoRef.current.clickTarget(e.inputEvent, e.inputTarget);
        }
      }}
      onClickGroup={(e) => {
        if (!e.moveableTarget) {
          editorRef.current.setSelectedLayers([]);
          return;
        }
        if (e.isDouble) {
          const nextChilds = layerManager.selectSubChilds(
            selectedTargets,
            e.moveableTarget
          );

          editorRef.current.setSelectedLayers(
            layerManager.toLayerGroups(nextChilds)
          );
        } else if (e.isTrusted) {
          selectoRef.current.clickTarget(e.inputEvent, e.moveableTarget);
        }
      }}
      onRenderStart={(e) => {
        e.datas.prevData = layerManager.getCSSByElement(e.target);
      }}
      onRender={(e) => {
        e.datas.isRender = true;
        e.target.style.cssText += e.cssText;
        // actionManager.requestTrigger("render.ing");
        layerManager.setCSSByElement(e.target, e.cssText);
      }}
      onRenderEnd={(e) => {
        if (!e.datas.isRender) {
          return;
        }
        actionManager.requestAct('render.end');

        const layer = layerManager.getLayerByElement(e.target);

        if (!layer) {
          return;
        }

        historyManager.addHistory('render', {
          infos: [
            {
              layer,
              prev: e.datas.prevData,
              next: layerManager.getFrame(layer).toCSSObject(),
            },
          ],
        });
      }}
      onRenderGroupStart={(e) => {
        e.datas.prevDatas = e.targets.map((target) =>
          layerManager.getCSSByElement(target)
        );
      }}
      onRenderGroup={(e) => {
        e.datas.isRender = true;

        e.events.forEach((ev) => {
          const nEv = ev;
          nEv.target.style.cssText += nEv.cssText;
          layerManager.setCSSByElement(nEv.target, nEv.cssText);
        });
        // actionManager.requestTrigger("render.group.ing", e);
      }}
      onRenderGroupEnd={(e) => {
        if (!e.datas.isRender) {
          return;
        }
        actionManager.requestAct('render.end');
        const { prevDatas } = e.datas;
        const infos = e.targets.map((target, i) => {
          const layer = layerManager.getLayerByElement(target);

          return {
            layer,
            prev: prevDatas[i],
            next: layerManager.getFrame(layer).toCSSObject(),
          };
        });

        historyManager.addHistory('render', {
          infos,
        });
      }}
    />
  );
});

MoveableManager.displayName = 'MoveableManager';

MoveableManager.propTypes = {
  isRestricted: PropTypes.bool,
};

MoveableManager.defaultProps = {
  isRestricted: false,
};
