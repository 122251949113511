import PropTypes from 'prop-types';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import {
	DEFAULT_BOOTH_CHILD_STYLE,
	DEFAULT_BOOTH_STYLE,
	LAYER_TYPES,
} from '../consts';
  
  const Booth = forwardRef(
	({ title, style, childStyle, isPreview, fpeData, ...props }, ref) => {
	  const boothRef = useRef();
  
	  const [iconSize, setIconSize] = useState(0);
	  const [width, setWidth] = useState(0);
	  const [fontSize, setFontSize] = useState(0);
  
	  useImperativeHandle(ref, () => boothRef.current, []);
  
	  useEffect(() => {
		const booth = boothRef.current;
		const observer = new ResizeObserver((entries) => {
		  entries.forEach((entry) => {
			if (entry.contentBoxSize) {
			  const contentBoxSize = entry.contentBoxSize[0];
  
			  let w = contentBoxSize.inlineSize;
			  const h = contentBoxSize.blockSize;
			  setWidth(w);
  
			  const s = w < h ? w : h;
  
			  setFontSize(s * 0.005);
  
			  const minSize = 10;
			  const maxSize = 20;
  
			  if (w < minSize) {
				w = minSize;
			  } else if (w > maxSize) {
				w = maxSize;
			  }
  
			  setIconSize(w * 0.1);
			}
		  });
		});
  
		observer.observe(booth);
  
		return () => {
		  observer.disconnect();
		};
	  }, []);
  
	  return (
		<div
		  ref={boothRef}
		  data-type={LAYER_TYPES.booth}
		  style={style}
		  {...props}
		>
		  <svg
			width="100%"
			height="100%"
			style={{ pointerEvents: 'none', position: 'absolute' }}
		  >
			<rect
			  width="100%"
			  height="100%"
			  style={{ fill: 'transparent', strokeWidth: 0.2, stroke: 'black' }}
			/>
		  </svg>
		  {!isPreview && (
			<div
			  className="exhibitors-mark"
			  style={{
				display: fpeData?.exhibitors?.length > 0 ? 'block' : 'none',
				pointerEvents: 'none',
				position: 'absolute',
				width: iconSize,
				height: iconSize,
				top: 0,
				right: 0,
				transform: `translate(-${iconSize * 0.1}px, ${iconSize * 0.1}px)`,
			  }}
			>
			  <svg
				style={{ pointerEvents: 'none', position: 'absolute' }}
				viewBox="0 0 24 24"
			  >
				<path
				  fill="white"
				  d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"
				/>
			  </svg>
			</div>
		  )}
		  <div
			className="id-container"
			contentEditable={false}
			style={{
			  ...childStyle,
			  fontSize: `${fontSize}rem`,
			  // lineHeight: `${fontSize}rem`,
			  textAlign: 'center',
			}}
		  >
			{isPreview && fpeData?.exhibitors?.length > 0 ? (
			  <div className="h-100 w-100 d-flex flex-column justify-content-between">
				<div
				  className={
					fpeData?.exhibitors?.length > 1
					  ? ''
					  : 'd-flex flex-column align-items-center justify-content-center flex-grow-1'
				  }
				  style={{
					overflow: 'hidden',
					width,
					padding: `${iconSize / 2}px`,
				  }}
				>
				  {fpeData?.exhibitors?.map((item, index) => (
					<div
					  key={item.value}
					  style={
						fpeData?.exhibitors?.length > 1
						  ? {
							  overflow: 'hidden',
							  textOverflow: 'ellipsis',
							  whiteSpace: 'nowrap',
							  marginBottom: 1,
							}
						  : {}
					  }
					>
					  {`${
						fpeData?.exhibitors?.length > 1 ? `${index + 1}-` : ''
					  } ${item.label}`}
					</div>
				  ))}
				</div>
  
				<div
				  style={{
					padding: iconSize / 2,
				  }}
				>
				  <div>...</div>
				  {title}
				</div>
			  </div>
			) : (
			  <div
				style={{
				  padding: iconSize / 2,
				}}
			  >
				{title}
			  </div>
			)}
		  </div>
		</div>
	  );
	}
  );
  
  Booth.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	style: PropTypes.objectOf(PropTypes.any),
	childStyle: PropTypes.objectOf(PropTypes.any),
	isPreview: PropTypes.bool,
	fpeData: PropTypes.objectOf(PropTypes.any),
  };
  
  Booth.defaultProps = {
	style: DEFAULT_BOOTH_STYLE,
	childStyle: DEFAULT_BOOTH_CHILD_STYLE,
	isPreview: false,
	fpeData: null,
  };
  
  Booth.displayName = 'Booth';
  
  export default Booth;
  