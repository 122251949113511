import { useStoreStateValue, useStoreValue } from "@scena/react-store";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAction } from "../hooks/useAction";
import {
	$actionManager, $editor, $historyManager, $layerManager,
	$selectedFlattenLayers
} from "../stores/stores";

import Tooltip from "components/Tooltip";
import DataSection from "./partials/DataSection";
import ExhibitorsSection from "./partials/ExhibitorSection";
import TextSection from "./partials/TextSection";
import TransformSection from "./partials/TransformSection";
import UserSection from "./partials/UserSelection";

const getStatusMessageId = (status) => {
  switch (status) {
    case 1:
      return "app.common.active";
    case 2:
      return "app.common.locked";
    default:
      return "app.common.inactive";
  }
};

function RightToolbar({ isRestricted, data, onSave }) {
  useAction("render.end");

  const actionManager = useStoreStateValue($actionManager);
  const historyManager = useStoreStateValue($historyManager);
  const layerManager = useStoreStateValue($layerManager);
  const selectedLayersStore = useStoreValue($selectedFlattenLayers);
  const selectedLayers = useStoreStateValue($selectedFlattenLayers);
  const editorRef = useStoreStateValue($editor);

  const onChangeProperties = ({ properties, datas, others }) => {
    const nSelectedLayers = selectedLayersStore.value;

    if (selectedLayers.length < 1) {
      return;
    }

    historyManager.addHistory("render", {
      infos: nSelectedLayers.map((layer) => {
        const nLayer = layer;
        const frame = layerManager.getFrame(layer);
        const prev = frame.toCSSObject();

        if (!nLayer.ref.current) {
          return {};
        }

        others?.forEach(({ property, value }) => {
          nLayer[property] = value;
          if (property === "title") {
            const idContainer =
              nLayer?.ref?.current?.querySelector(".id-container");
            if (idContainer) {
              idContainer.textContent = value;
            }
          }
        });

        datas?.forEach(({ property, value }) => {
          nLayer.fpeData[property] = value;
          if (property === "exhibitors") {
            const exhibitorsMark =
              nLayer?.ref?.current?.querySelector(".exhibitors-mark");
            if (exhibitorsMark) {
              exhibitorsMark.style.display =
                value?.length > 0 ? "block" : "none";
            }
          }
        });

        properties?.forEach(({ property, value }) => {
          frame.set(property, value);
        });

        nLayer.ref.current.style.cssText += frame.toCSSText();

        return {
          layer,
          prev,
          next: frame.toCSSObject(),
        };
      }),
    });

    actionManager.act("render.end");
    editorRef.current.setSelectedLayers([]);
    editorRef.current.setSelectedLayers(nSelectedLayers);
  };

  const nSelectedLayers = selectedLayersStore.value;
  let partialMenu = false;
  if (
    isRestricted &&
    nSelectedLayers?.length > 0 &&
    nSelectedLayers.find((item) => item.type !== LAYER_TYPES.booth)
  ) {
    partialMenu = true;
  }

  return (
    <div className="d-flex flex-column h-100 p-3">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <h4 className="mb-3">
          <Tooltip
            content={<FormattedMessage id={getStatusMessageId(data.status)} />}
          >
            <span
              className={`ms-1 legend-indicator bg-${
                data.status === 1 ? "success" : "danger"
              }`}
            />
          </Tooltip>
          {data.title ? (
            data.title
          ) : (
            <>
              <FormattedMessage id="app.common.title" />{" "}
              <FormattedMessage id="app.common.n/a" />
            </>
          )}
        </h4>
        <Badge bg="soft-dark" className="text-body">
          {data.calendar_title ? (
            data.calendar_title
          ) : (
            <>
              <FormattedMessage id="app.common.event" />{" "}
              <FormattedMessage id="app.common.n/a" />
            </>
          )}
        </Badge>
        <button type="button" onClick={onSave} className="d-none" />
      </div>
      <hr />
      <div className="d-flex justify-content-center flex-column align-items-center mb-3">
        <h5>
          <FormattedMessage id="app.common.itemProperties" />
        </h5>
        {selectedLayers.length < 1 && (
          <div className="flex-grow mt-5 text-center">
            <FormattedMessage id="app.common.selectItemToViewProperties" />
          </div>
        )}
        {partialMenu && (
          <div className="flex-grow mt-5 text-center">
            <FormattedMessage id="app.common.editorItemPermissionMessage" />
          </div>
        )}
      </div>

      {!partialMenu && (
        <>
          <DataSection onChange={onChangeProperties} />
		  <h3>Grup Fuarlar</h3>
          <TextSection onChange={onChangeProperties} />
          <ExhibitorsSection onChange={onChangeProperties} />
          <UserSection onChange={onChangeProperties} />
          <TransformSection />
        </>
      )}
    </div>
  );
}

RightToolbar.propTypes = {
  isRestricted: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func,
};

RightToolbar.defaultProps = {
  isRestricted: false,
  data: null,
  onSave: () => {},
};

export default RightToolbar;
