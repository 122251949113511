//satış için
import Constants from '../constants';

export const publicAreaBasePath = '';
export const protectedAreaBasePath = '/dashboard';
export const authBasePath = '/auth';

 
export const Routes = {
  public: {
    FLOOR_PLAN: {
      title: 'floorPlan',
      path: `${publicAreaBasePath}/p/:guid/:SellerId/:TuyapToken/:DsFairId/:isMobilVersion`, 
      componentName: 'FloorPlan',
    },
    NOT_FOUND: { title: 'notFound', path: '/404', componentName: 'NotFound' },
    UNAUTHORIZED: {
      title: 'unauthorized',
      path: '/unauthorized',
      componentName: 'Unauthorized',
    },
  },
  auth: {
    LOGIN: {
      title: 'login',
      path: `${authBasePath}/login`,
      componentName: 'Login',
    },
    FORGOT_PASSWORD: {
      title: 'forgotPassword',
      path: `${authBasePath}/forgot-password`,
      componentName: 'ForgotPassword',
    },
    
  },
  protected: {
    OVERVIEW: {
      title: 'dashboard',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/overview`,
      componentName: 'Overview',
      hidden: true,
    },
    FLOOR_PLANS: {
      title: 'floorPlans',
      icon: 'bi-layout-wtf',
      path: `${protectedAreaBasePath}/floor-plans`,
      componentName: 'FloorPlans',
    },
    FLOOR_PLAN_EDITOR: {
      title: 'floorPlanEditor',
      icon: 'bi-layout-wtf',
      path: `${protectedAreaBasePath}/floor-plans/:id/edit`,
      componentName: 'FloorPlanEditor',
      hidden: true,
      roles: [Constants.User.Types[15].id],
    },
    ACCOUNT_SETUP: {
      title: 'accountSetup',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/account-setup`,
      componentName: 'AccountSetup',
      hidden: true,
    },
    ACCOUNT: {
      title: 'account',
      icon: 'bi-person',
      path: `${protectedAreaBasePath}/account`,
      componentName: 'Account',
      hidden: true,
      children: {
        ACCOUNT_SETTINGS: {
          title: 'settings',
          path: `${protectedAreaBasePath}/account/settings`,
          componentName: 'AccountSettings',
        }
        
      },
    },
    EXHIBITORS: {
      title: 'exhibitors',
      icon: 'bi-briefcase',
      path: `${protectedAreaBasePath}/exhibitors`,
      componentName: 'Exhibitors',
    },
    EXHIBITORS_DETAIL: {
      title: 'exhibitors',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/exhibitors-detail/:id`,
      componentName: 'ExhibitorsDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/exhibitors-detail/:id/info`,
          componentName: 'ExhibitorsDetailInfo',
        },
        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/exhibitors-detail/:id/calendar`,
          componentName: 'ExhibitorsDetailCalendar',
        },
        GALLERY: {
          title: 'gallery',
          path: `${protectedAreaBasePath}/exhibitors-detail/:id/gallery`,
          componentName: 'ExhibitorsDetailGallery',
        },
        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/exhibitors-detail/:id/notes`,
          componentName: 'ExhibitorsDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/exhibitors-detail/:id/files`,
          componentName: 'ExhibitorsDetailFiles',
        },
        FLOOR_PLANS: {
          title: 'floorPlans',
          path: `${protectedAreaBasePath}/exhibitors-detail/:id/floor-plans`,
          componentName: 'ExhibitorsDetailFloorPlans',
        },
      },
    },
    CALENDAR: {
      title: 'calendar',
      icon: 'bi-calendar-event',
      path: `${protectedAreaBasePath}/calendar`,
      componentName: 'Calendar',
    },
    FILE_MANAGER: {
      title: 'fileManager',
      icon: 'bi-files',
      path: `${protectedAreaBasePath}/file-manager`,
      componentName: 'FileManager',
    },

    USERS: {
      title: 'users',
      icon: 'bi-people',
      path: `${protectedAreaBasePath}/users`,
      componentName: 'Users',
      roles: [Constants.User.Types[15].id],
    },
    
  },
};
