import React, { useContext, useState } from "react";

import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { lowerCase } from "lodash";
import useAxiosQuery from "hooks/useAxiosQuery";
import useAxiosMutation from "hooks/useAxiosMutation";
import useToast from "hooks/useToast";
import Editor from "./Editor/Editor";
import RequestLoading from "components/RequestLoading";
import RequestResult from "components/RequestResult";
import { AuthContext } from "../../../context/AuthContext";
import Constants from "../../../constants";

function FloorPlanEditor() {
  const { id } = useParams();
  const [apiData, setApiData] = useState();
  const { user } = useContext(AuthContext);
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [jsonParseError, setJsonParseError] = useState();

  const { isLaoding: apiLoading, error: apiError } = useAxiosQuery({
    url: "service/maps/select",
    preventFetch: !id,
    params: {
      id,
    },
    onSuccess: async (data) => {
      const { output, ...rest } = data;

      let outputObject;
      if (output) {
        try {
          outputObject = JSON.parse(output);
        } catch (e) {
          setJsonParseError(e);
        }
      }
      setApiData({
        output: outputObject,
        ...rest,
      });
    },
  });

  const { isLoading: apiUpdateLoading, mutate: apiUpdateFetch } =
    useAxiosMutation({
      url: "/service/maps/update",
      params: { id },
    });

  const { isLoading: apiUnlockLoading, mutate: apiLockFetch } =
    useAxiosMutation({
      url: "/service/maps/unlock",
      params: { id },
    });

  const onUnlock = () => {
    apiLockFetch({
      onSuccess: () => {
        showToast({
          type: "success",
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasUpdatededSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: "app.common.floorPlan" })),
              }}
            />
          ),
        });
      },
      onError: (error) => {
        showToast({
          type: "danger",
          autohide: true,
          title: <FormattedMessage id="app.common.error" />,
          message: (
            <>
              <FormattedMessage id="app.common.somethingWentWrong" />
              <RequestResult
                type="error"
                message={error}
                className="mt-2 p-2"
              />
            </>
          ),
        });
      },
    });
  };

  const onSave = (dataToSave) => {
    const req = { output: JSON.stringify(dataToSave) };
    apiUpdateFetch(req, {
      onSuccess: () => {
        showToast({
          type: "success",
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasUpdatededSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: "app.common.floorPlan" })),
              }}
            />
          ),
        });
      },
      onError: (error) => {
        showToast({
          type: "danger",
          autohide: true,
          title: <FormattedMessage id="app.common.error" />,
          message: (
            <>
              <FormattedMessage id="app.common.somethingWentWrong" />
              <RequestResult
                type="error"
                message={error}
                className="mt-2 p-2"
              />
            </>
          ),
        });
      },
    });
  };

  return (
    <>
      {(apiLoading || apiError || jsonParseError) && (
        <div className="content container">
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError || jsonParseError} />
        </div>
      )}
      {!apiLoading && !apiError && !jsonParseError && apiData && (
        <div className="position-relative">
          {apiUpdateLoading && (
            <div>
              <div className="position-absolute w-100 h-100 bg-dark opacity-75 zi-99" />
              <div className="position-absolute w-100 h-100 d-flex justify-content-center zi-999">
                <div className="w-50 text-center mt-5">
                  <Spinner animation="border" variant="white" />
                </div>
              </div>
            </div>
          )}
          <Editor
            width={Number(apiData.width)}
            height={Number(apiData.height)}
            background={apiData.image}
            data={apiData}
            onSave={onSave}
            onUnlock={onUnlock}
            isRestricted={user.user_type === Constants.User.Types[17].id}
          />
        </div>
      )}
    </>
  );
}

export default FloorPlanEditor;
