import React, {
    useMemo,
    useState,
    forwardRef,
    useImperativeHandle,
  } from 'react';
  import PropTypes from 'prop-types';
  import { Table, Alert, Card, Row, Col } from 'react-bootstrap';
  import Select from 'react-select';
  import { useTable } from 'react-table';
  import { FormattedMessage } from 'react-intl';
  import Pagination from './Pagination';
   import RequestLoading from '../RequestLoading';
  import RequestResult from '../RequestResult';
  import useAxiosQuery from 'hooks/useAxiosQuery';
  const perPageValues = [10, 15, 20, 25];
  
  const SmartTable = forwardRef(
    (
      { columns, requestUrl, requestParams, filters, hoverable, verticalAligned },
      ref
    ) => {
      const [currentPage, setCurrentPage] = useState(1);
      const [itemPerPage, setItemPerPage] = useState(perPageValues[0]);
  
      const columnsMemo = useMemo(() => columns, [columns]);
  
      const {
        data: apiData,
        isLoading: apiLoading,
        error: apiError,
        refetch: apiFetch,
      } = useAxiosQuery({
        url:"service/"+ requestUrl,
        dataWithCount: true,
        params: {
          ...requestParams,
          page: currentPage,
          quantity: itemPerPage,
          ...filters,
        },
      });
  
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
          columns: columnsMemo,
          data: apiData?.rows || [],
          manualPagination: true,
        });
  
      const onPageChange = (pageNumber) => {
        if (pageNumber !== currentPage) {
          setCurrentPage(pageNumber);
        }
      };
  
      const onPerPageChange = (perPage) => {
        if (perPage !== itemPerPage) {
          setCurrentPage(1);
          setItemPerPage(perPage);
        }
      };
  
      useImperativeHandle(ref, () => ({
        reload: () => {
          apiFetch();
        },
      }));
  
      return (
        <>
          <Card.Body
            className={
              !apiLoading && !apiError && apiData?.rows.length > 0 ? 'p-0' : ''
            }
          >
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            <RequestResult type="error" message={apiError} />
            {!apiLoading && !apiError && apiData?.rows.length === 0 && (
              <Alert variant="secondary">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bi-exclamation-triangle-fill" />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <FormattedMessage id="app.common.noResultsFound" />
                  </div>
                </div>
              </Alert>
            )}
          </Card.Body>
  
          {!apiLoading && !apiError && apiData?.rows.length > 0 && (
            <div className="table-responsive" style={{ minHeight: 340 }}>
              <Table
                {...getTableProps()}
                borderless
                hover={hoverable}
                className={`table-thead-bordered card-table dataTable no-footer ${
                  verticalAligned ? 'table-align-middle' : ''
                }`}
              >
                <thead className="thead-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              cell.column.wrap ? '' : 'text-nowrap'
                            } ${
                              cell.column.textAlign
                                ? `text-${cell.column.textAlign}`
                                : ''
                            }`}
                            style={{
                              minWidth: cell.column.minWidth || undefined,
                            }}
                          >
                            {cell.column.disableNullControl ||
                            cell.value ||
                            cell.value === 0 ? (
                              cell.render('Cell')
                            ) : (
                              <FormattedMessage id="app.common.n/a" />
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
          <Card.Footer>
            <Row className="justify-content-center justify-content-sm-between align-items-sm-center">
              <Col className="col-sm mb-2 mb-sm-0">
                <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                  <span className="me-2">
                    <FormattedMessage id="app.common.showing" />:
                  </span>
  
                  <Select
                    menuPlacement="top"
                    options={perPageValues.map((x) => ({
                      value: x,
                      label: x,
                    }))}
                    defaultValue={{
                      value: perPageValues[0],
                      label: perPageValues[0],
                    }}
                    value={{ value: itemPerPage, label: itemPerPage }}
                    onChange={(item) => {
                      onPerPageChange(item.value);
                    }}
                    className="react-select-custom-container"
                    classNamePrefix="react-select-custom"
                    isSearchable={false}
                  />
  
                  <span className="text-secondary mx-2">
                    <FormattedMessage id="app.common.of" />
                  </span>
                  {apiData?.count}
                </div>
              </Col>
  
              <Col className="col-sm-auto">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <Pagination
                    currentPage={currentPage}
                    totalRows={apiData?.count}
                    pageChangeHandler={onPageChange}
                    rowsPerPage={itemPerPage}
                  />
                </div>
              </Col>
            </Row>
          </Card.Footer>
        </>
      );
    }
  );
  
  SmartTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    requestUrl: PropTypes.string.isRequired,
    requestParams: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any),
    hoverable: PropTypes.bool,
    verticalAligned: PropTypes.bool,
  };
  
  SmartTable.defaultProps = {
    requestParams: {},
    filters: {},
    hoverable: false,
    verticalAligned: true,
  };
  export default SmartTable;
  