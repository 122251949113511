import React from 'react';
import { Row, Col } from 'react-bootstrap';
 
import PageHeader from 'components/PageHeader';
import SmartFileManager from 'components/SmartFileManager';

const baseURL = process.env.REACT_APP_BASE_URL || '';

function FileManager() {
  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="fileManager"
        breadcrumbData={{
          current: 'fileManager',
        }}
      />
      <Row>
        <Col>
          <SmartFileManager
            paths={{
              folders: {
                list: 'service/directories/list',
                create: 'service/directories/insert',
                delete: 'service/directories/delete',
              },
              files: {
                list: "service/files/list",
                create: "/files/insert",
                delete: "service/files/delete",
                preview: "service/files/",
                download: `service/file/download?filename=`,
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default FileManager;
