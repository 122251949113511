import React from 'react';
import SmartCalendar from 'components/SmartCalendar/SmartCalendar';
import PageHeader from 'components/PageHeader';
 
function Calendar() {
  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="calendar"
        breadcrumbData={{
          current: 'calendar',
        }}
      />
      <SmartCalendar
        paths={{
          list: 'service/calendar/list',
          create: '/calendar/insert',
          delete: '/calendar/delete',
          update: '/calendar/update',
        }}
        withRadiusFilter={false}
        // withCategoriesFilter={{ requestUrl: '/categories/list' }}
      />
    </div>
  );
}

export default Calendar;
