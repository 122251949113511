import PropTypes from 'prop-types';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import {
	DEFAULT_OTHER_SPACE_CHILD_STYLE,
	DEFAULT_OTHER_SPACE_STYLE,
	LAYER_TYPES,
} from '../consts';
  
  const OtherSpace = forwardRef(({ title, style, childStyle, ...props }, ref) => {
	const otherSpaceRef = useRef();
  
	const [fontSize, setFontSize] = useState('2rem');
  
	useImperativeHandle(ref, () => otherSpaceRef.current, []);
  
	useEffect(() => {
	  const otherSpace = otherSpaceRef.current;
	  const observer = new ResizeObserver((entries) => {
		entries.forEach((entry) => {
		  if (entry.contentBoxSize) {
			const contentBoxSize = entry.contentBoxSize[0];
			const s =
			  contentBoxSize.inlineSize < contentBoxSize.blockSize
				? contentBoxSize.inlineSize
				: contentBoxSize.blockSize;
			const size = s / 60;
			setFontSize(`${size}rem`);
		  }
		});
	  });
  
	  observer.observe(otherSpace);
  
	  return () => {
		observer.disconnect();
	  };
	}, []);
  
	return (
	  <div
		ref={otherSpaceRef}
		data-type={LAYER_TYPES.otherSpace}
		style={style}
		{...props}
	  >
		<svg
		  width="100%"
		  height="100%"
		  style={{ pointerEvents: 'none', position: 'absolute' }}
		>
		  <rect
			width="100%"
			height="100%"
			style={{ fill: 'transparent', strokeWidth: 0.2, stroke: 'black' }}
		  />
		</svg>
		<div
		  className="id-container"
		  contentEditable={false}
		  style={{
			...childStyle,
			fontSize,
		  }}
		>
		  {title}
		</div>
	  </div>
	);
  });
  
  OtherSpace.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	style: PropTypes.objectOf(PropTypes.any),
	childStyle: PropTypes.objectOf(PropTypes.any),
  };
  
  OtherSpace.defaultProps = {
	style: DEFAULT_OTHER_SPACE_STYLE,
	childStyle: DEFAULT_OTHER_SPACE_CHILD_STYLE,
  };
  
  OtherSpace.displayName = 'OtherSpace';
  
  export default OtherSpace;
  