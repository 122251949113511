import React, {
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
    useContext,
  } from 'react';
  import {
    Form,
    Row,
    Col,
    Button,
    Spinner,
    Modal,
    InputGroup,
  } from 'react-bootstrap';
  import PropTypes from 'prop-types';
  import { useForm, Controller } from 'react-hook-form';
  import { useEffectOnce } from 'react-use';
  import { yupResolver } from '@hookform/resolvers/yup';
  import axios from 'axios';
  import qs from 'qs';
  import { FormattedMessage, useIntl } from 'react-intl';
  import ReactSelect from 'react-select';
  // import Flatpickr from 'react-flatpickr';
  import { camelCase, upperFirst } from 'lodash';
  import PhoneInput from 'react-phone-number-input/input';
  import moment from 'moment';
  import { yup } from 'lib/yup';
  import { axiosApiInstance } from 'lib/axios';
   import FormError from '../FormError';
  import RequestResult from '../RequestResult';
  import FormPasswordInput from '../FormPasswordInput';
  import ReCaptcha from '../ReCaptcha';
  import FormCodeInput from '../FormCodeInput';
  import DateRangePicker from '../DateRangePicker';
  import FormDropzoneInput from '../FormDropzoneInput';
  import FormDropzoneInputAlt from '../FormDropzoneInputAlt';
  import FormImageInput from '../FormImageInput';
  import Flatpickr from '../Flatpickr';
  import Nouislider from '../Nouislider';
  import Utils from '../../utils';
  import { AuthContext } from '../../context/AuthContext';
  import { isArray } from '../../utils/object';
  
  function WrapperComponent({ isModal, children }) {
    if (isModal) {
      return <Modal.Body>{children}</Modal.Body>;
    }
  
    return children;
  }
  
  WrapperComponent.propTypes = {
    isModal: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  };
  
  const SmartForm = forwardRef(
    (
      {
        fields,
        requestUrl,
        requestType,
        requestParams,
        fetchOnStart,
        onRequestSuccess,
        onRequestError,
        onSubmit,
        size,
        submitButtonText,
        cancelButtonText,
        clearButtonText,
        prevButtonText,
        withCaptcha,
        defaultValues,
        isModal,
        isWizard,
        isFilterForm,
        onModalHide,
        onWizardPrev,
        disableApiKey,
        infoMessage,
        isHorizontal,
        loading,
        footerAlign,
        readOnly,
      },
      ref
    ) => {
      const { formatMessage } = useIntl();
      const [requestLoading, setRequestLoading] = useState(loading);
      const [requestError, setRequestError] = useState(null);
      const [requestSuccess, setRequestSuccess] = useState(null);
      const [captchaIsVisible, setCaptchaIsVisible] = useState(false);
      const [captchaValue, setCaptchaValue] = useState(null);
      const [hideFooter, setHideFooter] = useState(fetchOnStart);
      const { token: userToken } = useContext(AuthContext);
  
      const schema = {};
      const emptyFields = {};
      fields.forEach((row) => {
        row.cols.forEach((col) => {
          schema[col.key] = col.schema;
          emptyFields[col.key] = null;
        });
      });
  
      const {
        register,
        watch,
        control,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors, isSubmitted, submitCount },
      } = useForm({
        resolver: yupResolver(yup.object(schema).required()),
        defaultValues,
      });
  
      const watchAllFields = watch();
  
      const source = axios.CancelToken.source();
  
      const isFieldVisible = (visibility) => {
        if (visibility) {
          const { field, is } = visibility;
          if (!field || is === undefined) {
            return true;
          }
  
          const check = typeof is === 'function' ? is : (value) => value === is;
          if (check(watchAllFields[field])) {
            return true;
          }
          return false;
        }
        return true;
      };
  
      const handleOnSubmit = (formData) => {
        if (captchaIsVisible && !captchaValue) {
          return;
        }
  
        if (onSubmit) {
          onSubmit(formData);
          return;
        }
  
        const reqData = {};
        fields.forEach((row) => {
          row.cols.forEach((col) => {
            // remove readOnly, disabled and local usage data, like passwordConfirm
            if (
              !col.options?.controller?.props?.disabled &&
              !col.options?.controller?.props?.readOnly &&
              !col.local &&
              col.type !== 'hidden' &&
              isFieldVisible(col.visibility)
            ) {
              const key = !disableApiKey ? col.apiKey || col.key : col.key;
              reqData[key] = formData[col.key];
            }
          });
        });
  
        let postReq = { ...requestParams, ...reqData };
  
        if (userToken) {
          postReq.token = userToken;
        }
  
        let hasFileType = false;
        fields.forEach((row) => {
          if (
            row.cols.find(
              (col) =>
                col.type === 'file' ||
                col.type === 'image' ||
                col.type === 'dropzone' ||
                col.type === 'dropzoneAlt'
            )
          ) {
            hasFileType = true;
          }
        });
        if (hasFileType) {
          postReq = Utils.Object.toFormdata(postReq);
        } else {
          postReq = qs.stringify(postReq);
        }
  
        if (requestUrl) {
          setRequestLoading(true);
          setRequestError(null);
  
          axiosApiInstance[requestType]("service/"+requestUrl, postReq, {
            cancelToken: source.token,
          })
            .then(({ data }) => {
              setRequestLoading(false);
              if (data.status) {
                onRequestSuccess(data);
                reset();
              } else {
                setRequestError(`errors.api.${data.Message}`);
                onRequestError(`errors.api.${data.Message}`);
                setHideFooter(false);
              }
            })
            .catch((err) => {
              if (!axios.isCancel(err)) {
                setRequestError(`errors.network.${err.message}`);
                setRequestLoading(false);
                setHideFooter(false);
              }
            });
        }
      };
  
      const getLabel = ({ type, key, label, visibility }) => {
        if (!isFieldVisible(visibility)) {
          return null;
        }
  
        switch (type) {
          case 'hidden':
          case 'checkbox':
          case 'switch':
          case 'code':
          case 'dropzone':
          case 'dropzoneAlt':
            return null;
  
          default:
            if (label) {
              return label;
            }
  
            return (
              <Form.Label
                htmlFor={key}
                column={isHorizontal}
                sm={isHorizontal ? 3 : undefined}
              >
                <FormattedMessage
                  id={`app.common.${key}`}
                  defaultMessage={upperFirst(key)}
                />
              </Form.Label>
            );
        }
      };
  
      const getError = ({ type, key }) => {
        switch (type) {
          case 'checkbox':
          case 'switch':
            return null;
  
          default:
            return <FormError error={errors[key]} className="order-last" />;
        }
      };
  
      const getPlaceholder = (key, type) => {
        let prefix = 'enter';
        switch (type) {
          case 'flatpickr':
          case 'dateRangePicker':
          case 'react-select':
            prefix = 'select';
            break;
  
          default:
            prefix = 'enter';
            break;
        }
  
        return formatMessage({
          id: `app.common.${prefix}${upperFirst(camelCase(key))}`,
          defaultMessage: formatMessage({
            id: `app.common.${key}`,
            defaultMessage: upperFirst(key),
          }),
        });
      };
  
      const getControl = (
        { type, key, label, options, visibility },
        index,
        rowIndex
      ) => {
        if (!isFieldVisible(visibility)) {
          return null;
        }
  
        switch (type) {
          case 'textarea':
            return (
              <Form.Control
                as="textarea"
                disabled={requestLoading}
                isInvalid={isSubmitted && !!errors[key]}
                isValid={isSubmitted && !errors[key]}
                type={type}
                size={size}
                id={key}
                placeholder={getPlaceholder(key)}
                readOnly={readOnly}
                {...options?.controller?.props}
                {...register(key)}
              />
            );
  
          case 'password':
            return (
              <FormPasswordInput
                disabled={requestLoading}
                isInvalid={isSubmitted && !!errors[key]}
                isValid={isSubmitted && !errors[key]}
                placeholder={getPlaceholder(key)}
                id={key}
                register={register}
              />
            );
  
          case 'checkbox':
            return (
              <Form.Check id={key}>
                <Form.Check.Input
                  disabled={requestLoading}
                  isInvalid={isSubmitted && !!errors[key]}
                  isValid={isSubmitted && !errors[key]}
                  {...register(key)}
                />
                <Form.Check.Label className="d-flex align-items-center">
                  {label}
                </Form.Check.Label>
                <FormError error={errors[key]} />
              </Form.Check>
            );
  
          case 'switch':
            return (
              <Form.Check id={key} type={type} className="mt-1">
                <Form.Check.Input
                  disabled={requestLoading}
                  isInvalid={isSubmitted && !!errors[key]}
                  isValid={isSubmitted && !errors[key]}
                  className="mt-n1"
                  {...register(key)}
                />
                <Form.Check.Label className="d-flex align-items-center">
                  {label}
                </Form.Check.Label>
                <FormError error={errors[key]} />
              </Form.Check>
            );
  
          case 'code':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange } }) => (
                  <FormCodeInput
                    ref={options?.controller?.ref}
                    allowedCharacters={options?.contoller?.allowedCharacters}
                    rowClass={`gx-2 gx-sm-3 ${
                      isSubmitted && errors[key] ? 'is-invalid' : ''
                    } ${isSubmitted && !errors[key] ? 'is-valid' : ''}`}
                    validated={isSubmitted}
                    colClass="mb-1"
                    inputClass="form-control-single-number"
                    length={options?.controller?.length}
                    defaultValue={defaultValues[key] || undefined}
                    autoFocus={!defaultValues[key]}
                    onChange={onChange}
                    disabled={requestLoading}
                  />
                )}
              />
            );
  
          case 'react-select':
            return (
              <Controller
                control={control}
                name={key}
                // defaultValue={getVal(defaultValues[key])}
                render={({ field: { onChange, value } }) => {
                  const defaultGetOptionValue = (obj) => obj.value;
                  const getOptionValue =
                    options?.controller?.props?.getOptionValue ||
                    defaultGetOptionValue;
                  const getVal = (val) => {
                    let valueObject = options?.controller?.props?.isMulti
                      ? []
                      : null;
                    const opts = options?.controller?.props?.options || [];
                    if ((val || val === 0) && opts.length > 0) {
                      valueObject = options?.controller?.props?.isMulti
                        ? opts.filter((x) => val.includes(getOptionValue(x)))
                        : opts.find((x) => getOptionValue(x) === val);
                    }
                    return valueObject;
                  };
                  const handleOnChange = (valueObject) => {
                    let val = null;
                    if (valueObject) {
                      val = options?.controller?.props?.isMulti
                        ? valueObject.map((item) => getOptionValue(item))
                        : getOptionValue(valueObject);
                    }
                    onChange(val);
                  };
                  return (
                    <ReactSelect
                      className={`react-select-custom-container react-select-custom-form-item react-select-custom-${size} ${
                        isSubmitted && errors[key] ? 'is-invalid' : ''
                      } ${isSubmitted && !errors[key] ? 'is-valid' : ''} ${
                        readOnly
                          ? 'react-select-custom-transparentWithBorder'
                          : ''
                      }`}
                      styles={{
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          zIndex: 999 - index * 10 + rowIndex * 1,
                        }),
                      }}
                      classNamePrefix="react-select-custom"
                      value={getVal(value)}
                      onChange={handleOnChange}
                      // defaultValue={getVal(defaultValues[key]) || undefined}
                      isDisabled={requestLoading || readOnly}
                      placeholder={getPlaceholder(key, type)}
                      components={
                        readOnly
                          ? {
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }
                          : undefined
                      }
                      {...options?.controller?.props}
                    />
                  );
                }}
              />
            );
  
          case 'flatpickr':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange, value } }) => (
                  <Flatpickr
                    size={size}
                    disabled={requestLoading}
                    isInvalid={isSubmitted && !!errors[key]}
                    isValid={isSubmitted && !errors[key]}
                    onChange={onChange}
                    value={value}
                    formatter={options?.controller?.formatter}
                    isModal={isModal}
                    placeholder={getPlaceholder(key, type)}
                    options={options?.controller}
                    /* options={{
                          static: isModal,
                          nextArrow:
                            '<i class="bi-chevron-right flatpickr-custom-arrow"></i>',
                          prevArrow:
                            '<i class="bi-chevron-left flatpickr-custom-arrow"></i>',
                          dateFormat: Constants.DateFormats.APP.Flatpickr.Common,
                          defaultDate: defaultValues[key]
                            ? moment(defaultValues[key]).toDate()
                            : undefined,
                          ...options?.controller?.options,
                        }}
                        // defaultValue={defaultValues[key] || undefined}
                        value={value ? moment(value).toDate() : undefined}
                        placeholder={getPlaceholder(key, type)}
                        onChange={([date]) => {
                          onChange(formatter(date));
                        }} */
                  />
                )}
              />
            );
  
          case 'dateRangePicker':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange, value } }) => {
                  const defaultFormatter = (v) => v;
                  const formatter =
                    options?.controller?.formatter || defaultFormatter;
                  return (
                    <InputGroup
                      className={`input-group-merge input-group-${size} ${
                        isSubmitted && errors[key] ? 'is-invalid' : ''
                      } ${isSubmitted && !errors[key] ? 'is-valid' : ''}`}
                    >
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          parentEl: isModal ? '.modal' : undefined,
                          ...(defaultValues[key]
                            ? {
                                initialDates: {
                                  startDate: defaultValues[key][0],
                                  endDate: defaultValues[key][1],
                                },
                              }
                            : {}),
                          ...options?.controller?.initialSettings,
                        }}
                        size={size}
                        placeholder={getPlaceholder(key, type)}
                        value={
                          value
                            ? [
                                moment(value[0]).toDate(),
                                moment(value[1]).toDate(),
                              ]
                            : null
                        }
                        onChange={(dates) => {
                          onChange(formatter(dates));
                        }}
                        disabled={requestLoading}
                      />
                      {value && (
                        <InputGroup.Text
                          as="button"
                          type="button"
                          className="input-group-append"
                          disabled={requestLoading}
                          onClick={() => {
                            onChange(null);
                          }}
                        >
                          <i className="bi-x-lg" />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  );
                }}
              />
            );
  
          case 'dropzone':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange } }) => (
                  <FormDropzoneInput
                    disabled={requestLoading}
                    isInvalid={isSubmitted && !!errors[key]}
                    isValid={isSubmitted && !errors[key]}
                    options={options?.controller?.options}
                    onChange={onChange}
                  />
                )}
              />
            );
  
          case 'dropzoneAlt':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange } }) => (
                  <FormDropzoneInputAlt
                    disabled={requestLoading}
                    isInvalid={isSubmitted && !!errors[key]}
                    isValid={isSubmitted && !errors[key]}
                    options={options?.controller?.options}
                    onChange={onChange}
                  />
                )}
              />
            );
  
          case 'image':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange, value } }) => (
                  <FormImageInput
                    disabled={requestLoading}
                    isInvalid={isSubmitted && !!errors[key]}
                    isValid={isSubmitted && !errors[key]}
                    size={options?.controller?.size}
                    variant={options?.controller?.variant}
                    defaultValue={defaultValues[key] || undefined}
                    onChange={onChange}
                    value={value}
                    readOnly={readOnly}
                  />
                )}
              />
            );
  
          case 'phone':
            return (
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    className={`form-control ${
                      isSubmitted && errors[key] ? 'is-invalid' : ''
                    } ${isSubmitted && !errors[key] ? 'is-valid' : ''}`}
                    placeholder={getPlaceholder(key)}
                    onChange={onChange}
                    value={value}
                    readOnly={readOnly}
                    disabled={requestLoading}
                  />
                )}
              />
            );
  
          case 'slider':
            return (
              <Controller
                control={control}
                name={key}
                defaultValue={
                  defaultValues[key] || options?.controller?.props?.start
                }
                render={({ field: { onChange } }) => (
                  <div
                    className={`form-control p-0 m-0 bg-transparent mt-4 ${
                      isSubmitted && errors[key] ? 'is-invalid' : ''
                    } ${isSubmitted && !errors[key] ? 'is-valid' : ''}`}
                  >
                    <Nouislider
                      className="range-slider-ui"
                      onChange={(v) => {
                        if (isArray(options?.controller?.props?.start)) {
                          onChange(v);
                          return;
                        }
                        onChange(v[0]);
                      }}
                      tooltips
                      {...options?.controller?.props}
                    />
                  </div>
                )}
              />
            );
  
          default:
            return (
              <Form.Control
                disabled={requestLoading}
                isInvalid={isSubmitted && !!errors[key]}
                isValid={isSubmitted && !errors[key]}
                type={type || 'text'}
                size={size}
                id={key}
                placeholder={getPlaceholder(key)}
                readOnly={readOnly}
                {...register(key)}
                {...options?.controller?.props}
              />
            );
        }
      };
  
      const getControlGroup = (child, error, { prefix, suffix }) => {
        if (prefix || suffix) {
          return (
            <InputGroup>
              {prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
              {child}
              {error}
              {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
            </InputGroup>
          );
        }
        return (
          <>
            {child}
            {error}
          </>
        );
      };
  
      const getFooterClass = () => {
        let cName = 'd-grid';
        if (
          isModal ||
          isFilterForm ||
          isHorizontal ||
          isWizard ||
          footerAlign === 'end'
        ) {
          cName = 'd-flex justify-content-end gap-3 ms-auto';
        } else if (footerAlign === 'start') {
          cName = 'd-flex justify-content-start gap-3';
        }
  
        return cName;
      };
  
      useImperativeHandle(ref, () => ({
        cancelRequest: () => {
          source.cancel('Request cancelled');
        },
        clearErrors: () => {
          clearErrors();
        },
        reset: (values, options) => {
          reset({ ...emptyFields, ...values }, options);
        },
        setRequestError: (val) => {
          setRequestError(val);
        },
        setRequestSuccess: (val) => {
          setRequestSuccess(val);
        },
        setRequestLoading: (val) => {
          setRequestLoading(val);
        },
        returnToInitialState: () => {
          source.cancel('Request cancelled');
          clearErrors();
          reset(emptyFields);
          setRequestError(null);
          setRequestLoading(false);
        },
        submit: () => {
          handleSubmit(handleOnSubmit)();
        },
      }));
  
      useEffect(() => {
        if (captchaIsVisible || requestLoading) {
          return;
        }
        if (
          withCaptcha &&
          typeof withCaptcha === 'number' &&
          submitCount >= withCaptcha
        ) {
          setRequestError(null);
          reset(
            {},
            {
              keepSubmitCount: true,
            }
          );
          setCaptchaIsVisible(true);
        } else if (withCaptcha && typeof withCaptcha === 'boolean') {
          setCaptchaIsVisible(true);
        }
      }, [requestLoading, captchaIsVisible, submitCount, withCaptcha, reset]);
  
      useEffectOnce(() => {
        if (fetchOnStart && !isSubmitted) {
          handleSubmit(handleOnSubmit)();
        }
        return () => {
          source.cancel('Component got unmounted');
        };
      });
  
      return (
        <WrapperComponent isModal={isModal}>
          <Form
            noValidate
            onSubmit={handleSubmit(handleOnSubmit)}
            // className="was-validated"
          >
            <RequestResult type="error" message={requestError} className="mb-5" />
            <RequestResult
              type="success"
              message={requestSuccess}
              className="mb-5"
            />
            {infoMessage && <div className="mb-5">{infoMessage}</div>}
            {fields.map((row, index) => (
              <Row
                key={`${index.toString()}`}
                className={isHorizontal ? 'mb-4' : ''}
              >
                {row.cols.map((col, rowIndex) =>
                  isHorizontal ? (
                    <React.Fragment key={col.key}>
                      {getLabel(col)}
                      <Col sm="9">
                        {getControlGroup(
                          getControl(col, index, rowIndex),
                          getError(col),
                          col
                        )}
                      </Col>
                    </React.Fragment>
                  ) : (
                    <Col key={col.key} sm={12 / row.cols.length}>
                      <div
                        className={`${
                          !isFieldVisible(col.visibility) || col.type === 'hidden'
                            ? ''
                            : 'mb-4'
                        }`}
                      >
                        {getLabel(col)}
                        {getControlGroup(
                          getControl(col, index, rowIndex),
                          getError(col),
                          col
                        )}
                      </div>
                    </Col>
                  )
                )}
              </Row>
            ))}
            {captchaIsVisible && (
              <div className="mb-5 text-center">
                <ReCaptcha
                  onChange={(value) => {
                    setCaptchaValue(value);
                  }}
                />
                <Form.Control
                  hidden
                  isInvalid={isSubmitted && !captchaValue}
                  isValid={isSubmitted && captchaValue}
                />
                <FormError
                  error={{ message: { key: 'errors.other.captchaInvalid' } }}
                />
              </div>
            )}
  
            {!hideFooter && !readOnly && (
              <div className={getFooterClass()}>
                {isWizard && (
                  <Button
                    variant="secondary"
                    size={size}
                    disabled={requestLoading}
                    onClick={onWizardPrev}
                  >
                    <FormattedMessage
                      id={`app.common.${prevButtonText}`}
                      defaultMessage={prevButtonText}
                    />
                  </Button>
                )}
  
                {isModal && (
                  <Button
                    variant="secondary"
                    size={size}
                    disabled={requestLoading}
                    onClick={onModalHide}
                  >
                    <FormattedMessage
                      id={`app.common.${cancelButtonText}`}
                      defaultMessage={cancelButtonText}
                    />
                  </Button>
                )}
                {isFilterForm && (
                  <Button
                    variant="white"
                    size={size}
                    disabled={requestLoading}
                    onClick={() => {
                      reset(emptyFields);
                      if (onSubmit) {
                        onSubmit({});
                      }
                    }}
                  >
                    <FormattedMessage
                      id={`app.common.${clearButtonText}`}
                      defaultMessage={clearButtonText}
                    />
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  size={size}
                  disabled={requestLoading}
                >
                  {requestLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-1" />
                      <FormattedMessage id="app.common.loading" />
                    </>
                  ) : (
                    <FormattedMessage
                      id={`app.common.${submitButtonText}`}
                      defaultMessage={submitButtonText}
                    />
                  )}
                </Button>
              </div>
            )}
          </Form>
        </WrapperComponent>
      );
    }
  );
  
  SmartForm.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    requestUrl: PropTypes.string,
    requestType: PropTypes.oneOf(['post', 'get']),
    requestParams: PropTypes.objectOf(PropTypes.any),
    fetchOnStart: PropTypes.bool,
    onRequestSuccess: PropTypes.func,
    onRequestError: PropTypes.func,
    onSubmit: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'lg']),
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    clearButtonText: PropTypes.string,
    prevButtonText: PropTypes.string,
    withCaptcha: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    defaultValues: PropTypes.objectOf(PropTypes.any),
    isModal: PropTypes.bool,
    isFilterForm: PropTypes.bool,
    isWizard: PropTypes.bool,
    onModalHide: PropTypes.func,
    onWizardPrev: PropTypes.func,
    disableApiKey: PropTypes.bool,
    infoMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isHorizontal: PropTypes.bool,
    loading: PropTypes.bool,
    footerAlign: PropTypes.oneOf(['end', 'start']),
    readOnly: PropTypes.bool,
  };
  
  SmartForm.defaultProps = {
    requestUrl: null,
    requestType: 'post',
    requestParams: {},
    fetchOnStart: false,
    onRequestSuccess: () => {},
    onRequestError: () => {},
    onSubmit: null,
    size: 'lg',
    submitButtonText: 'submit',
    cancelButtonText: 'cancel',
    clearButtonText: 'clear',
    prevButtonText: 'previousStep',
    withCaptcha: false,
    defaultValues: {},
    isModal: false,
    isFilterForm: false,
    isWizard: false,
    onModalHide: () => {},
    onWizardPrev: () => {},
    disableApiKey: false,
    infoMessage: null,
    isHorizontal: false,
    loading: false,
    footerAlign: null,
    readOnly: false,
  };
  
  export default SmartForm;
  