//satış için
import { useState } from "react";
import { useParams } from "react-router-dom";
import Editor from "../dashboard/FloorPlanEditor/Editor/Editor";

import RequestLoading from "components/RequestLoading";
import RequestResult from "components/RequestResult";
import useAxiosQuery from "hooks/useAxiosQuery";

function FloorPlan() {
  const { guid, SellerId, TuyapToken, DsFairId, isMobilVersion } = useParams();
  const [apiData, setApiData] = useState();
  const [jsonParseError, setJsonParseError] = useState();

  const { isLaoding: apiLoading, error: apiError } = useAxiosQuery({
    url: !isNaN(parseInt(guid))? "service/tuyapData/getwithmapid" : "service/map/get",
    params: {
      guid,
      mapid: guid,
    },
    onSuccess: async (data) => {
      const { output, ...rest } = data;

      let outputObject;
      if (output) {
        try {
          outputObject = JSON.parse(output);
        } catch (e) {
          setJsonParseError(e);
        }
      }
      setApiData({
        output: outputObject,
        sellerData: {
          TuyapToken: TuyapToken,
          SellerId,
          DsFairId,
          guid,
          isMobilVersion,
		  isNew:rest.IsNew
        },
        ...rest,
      });
    },
  });

  return (
    <>
      {(apiLoading || apiError || jsonParseError) && (
        <div className="content container">
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError || jsonParseError} />
        </div>
      )}
      {!apiLoading && !apiError && !jsonParseError && apiData && (
        <div className="position-relative">
          <Editor
            width={Number(apiData.width)}
            height={Number(apiData.height)}
            background={apiData.image}
            data={apiData}
            isPreview
			isNew={apiData.IsNew}
          />
        </div>
      )}
    </>
  );
}

export default FloorPlan;
