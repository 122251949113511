import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Guides from '@scena/react-guides';
import { useStoreState, useStoreStateValue } from '@scena/react-store';
import {
  $horizontalGuidelines,
  $verticalGuidelines,
  $zoom,
  $unitType,
} from '../stores/stores';
import { useAction } from '../hooks/useAction';
 
export const GuidesManager = forwardRef(
  (
    {
      type,
      size,
      backgroundColor,
      textColor,
      lineColor,
      selectedBackgroundColor,
      viewportWidth,
      viewportHeight,
    },
    ref
  ) => {
    const isHorizontal = type === 'horizontal';
    const [guidelines, setGuidelines] = useStoreState(
      isHorizontal ? $horizontalGuidelines : $verticalGuidelines
    );
    const zoom = useStoreStateValue($zoom);
    const unitType = useStoreStateValue($unitType);

    const result = useAction('get.rect');
    const rect = result?.rect;
    let unit = 10;

    if (zoom < 10) {
      unit = Math.floor(10 / zoom) * 10;
    }

    let selectedRanges;

    if (rect && rect.width && rect.height) {
      selectedRanges = [
        isHorizontal
          ? [rect.left, rect.left + rect.width]
          : [rect.top, rect.top + rect.height],
      ];
    } else {
      selectedRanges = [
        isHorizontal ? [0, viewportWidth] : [0, viewportHeight],
      ];
    }

    return (
      <Guides
        ref={ref}
        type={type}
        className="position-absolute"
        style={
          isHorizontal
            ? {
                width: `calc(100% - ${size}px)`,
                left: size,
                top: 0,
                height: size,
                zIndex: 1,
                transform: 'translateZ(1px)',
              }
            : {
                height: `calc(100% - ${size}px)`,
                left: 0,
                top: size,
                width: size,
                zIndex: 1,
                transform: 'translateZ(1px)',
              }
        }
        // snapThreshold={1}
        snaps={[0, ...guidelines]}
        displayDragPos
        guidePosFormat={(value) => `${value}${unitType}`}
        dragPosFormat={(value) => `${value}${unitType}`}
        zoom={zoom}
        unit={unit}
        selectedRanges={selectedRanges}
        onChangeGuides={useCallback(
          (e) => {
            setGuidelines(e.guides);
          },
          [setGuidelines]
        )}
        backgroundColor={backgroundColor}
        textColor={textColor}
        lineColor={lineColor}
        selectedBackgroundColor={selectedBackgroundColor}
      />
    );
  }
);

GuidesManager.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  lineColor: PropTypes.string,
  selectedBackgroundColor: PropTypes.string,
  viewportWidth: PropTypes.number.isRequired,
  viewportHeight: PropTypes.number.isRequired,
};

GuidesManager.defaultProps = {
  type: 'horizontal',
  size: 30,
  backgroundColor: null,
  textColor: null,
  lineColor: null,
  selectedBackgroundColor: null,
};

GuidesManager.displayName = 'GuidesManager';
