import React, { useContext } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import DefaultProfileImage from '../../../assets/images/default-profile-img.jpg';
import { Routes as AppRoutes } from '../../../router/RouteMapping';

function DropdownUser() {
  const { logout, user } = useContext(AuthContext);

  const onImageError = (e) => {
    e.target.src = DefaultProfileImage;
  };

  return (
    <Dropdown autoClose="outside" align="end">
      <Dropdown.Toggle
        as="a"
        role="button"
        bsPrefix="navbar-dropdown-account-wrapper"
      >
        <div className="avatar avatar-sm avatar-circle">
          <Image
            src={user?.pp || DefaultProfileImage}
            className="avatar-img"
            onError={onImageError}
          />
          <span className="avatar-status avatar-sm-status avatar-status-success" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as="div"
        className="navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
        data-bs-dropdown-animation
        style={{
          width: '16rem',
        }}
      >
        <>
          <Dropdown.ItemText as="div">
            <div className="d-flex align-items-center">
              <div className="avatar avatar-sm avatar-circle">
                <Image
                  src={user?.pp || DefaultProfileImage}
                  className="avatar-img"
                  onError={onImageError}
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h5 className="mb-0">{`${user?.name} ${user?.surname}`}</h5>
                <p className="card-text text-body">{user?.email}</p>
              </div>
            </div>
          </Dropdown.ItemText>

          <Dropdown.Divider />

          <Dropdown align="end" className="d-none">
            <Dropdown.Toggle
              as="a"
              role="button"
              className="navbar-dropdown-submenu-item dropdown-item"
            >
              Set status
            </Dropdown.Toggle>
            <Dropdown.Menu
              renderOnMount
              className="navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu"
            >
              <Dropdown.Item href="#!">
                <span className="legend-indicator bg-success me-1" /> Available
              </Dropdown.Item>
              <Dropdown.Item href="#!">
                <span className="legend-indicator bg-danger me-1" /> Busy
              </Dropdown.Item>
              <Dropdown.Item href="#!">
                <span className="legend-indicator bg-warning me-1" /> Away
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#!">Reset status</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown.Item
            as={Link}
            to={AppRoutes.protected.ACCOUNT.children.ACCOUNT_SETTINGS.path}
          >
            <FormattedMessage id="app.common.profileAndAccount" />
          </Dropdown.Item>

          <Dropdown.Divider className="d-none" />

          <Dropdown.Item className="d-none">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar avatar-sm avatar-dark avatar-circle">
                  <span className="avatar-initials">HS</span>
                </div>
              </div>
              <div className="flex-grow-1 ms-2">
                <h5 className="mb-0">
                  Htmlstream{' '}
                  <span className="badge bg-primary rounded-pill text-uppercase ms-1">
                    PRO
                  </span>
                </h5>
                <span className="card-text">hs.example.com</span>
              </div>
            </div>
          </Dropdown.Item>

          <Dropdown.Divider className="d-none" />

          <Dropdown align="end" className="d-none">
            <Dropdown.Toggle
              as="a"
              role="button"
              className="navbar-dropdown-submenu-item dropdown-item"
            >
              Customization
            </Dropdown.Toggle>
            <Dropdown.Menu
              renderOnMount
              className="navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu"
            >
              <Dropdown.Item href="#!">Invite people</Dropdown.Item>
              <Dropdown.Item href="#!">
                Analytics
                <i className="bi-box-arrow-in-up-right" />
              </Dropdown.Item>
              <Dropdown.Item href="#!">
                Customize Front
                <i className="bi-box-arrow-in-up-right" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown.Item className="d-none">Manage team</Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item onClick={logout}>
            <FormattedMessage id="app.common.signOut" />
          </Dropdown.Item>
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownUser;
