import React, {
    useRef,
    useEffect,
    // useState,
    useContext,
    forwardRef,
    Children,
    cloneElement,
    useImperativeHandle,
    useCallback,
  } from 'react';
  import PropTypes from 'prop-types';
  import { Button, Form } from 'react-bootstrap';
  import moment from 'moment';
  import { useIntl } from 'react-intl';
  import * as BSDateRangePicker from 'bootstrap-daterangepicker';
   import jQuery from "../lib/jquery"
  import Constants from '../constants';
  import { LangContext } from '../context/LangContext';
  
  /* const defaults = {
    prevArrow: '<i class="bi-chevron-left daterangepicker-custom-arrow"></i>',
    nextArrow: '<i class="bi-chevron-right daterangepicker-custom-arrow"></i>',
  }; */
  
  const DRPicker = forwardRef((props, ref) => {
    const pickerRef = useRef();
    const bSDateRangePicker = useRef();
  
    const handleCallback = useCallback(
      (...args) => {
        if (typeof props.onCallback === 'function') {
          props.onCallback(...args);
        }
      },
      [props]
    );
  
    const setStartDate = (dateOrString) => {
      bSDateRangePicker.current.setStartDate(dateOrString);
    };
  
    const setEndDate = (dateOrString) => {
      bSDateRangePicker.current.setEndDate(dateOrString);
    };
  
    const init = useCallback(() => {
      if (bSDateRangePicker.current) {
        bSDateRangePicker.current.remove();
      }
      bSDateRangePicker.current = new BSDateRangePicker(
        pickerRef.current,
        props.initialSettings,
        handleCallback
      );
    }, [handleCallback, props.initialSettings]);
  
    useImperativeHandle(ref, () => ({
      setStartDate,
      setEndDate,
      reload: init,
    }));
  
    useEffect(() => {
      jQuery(() => {
        init();
      });
  
      return () => {
        if (bSDateRangePicker.current) {
          bSDateRangePicker.current.remove();
        }
      };
    }, [init]);
  
    const childElement = Children.only(props.children);
  
    return cloneElement(childElement, {
      ref: pickerRef,
    });
  });
  
  DRPicker.propTypes = {
    children: PropTypes.node.isRequired,
    initialSettings: PropTypes.objectOf(PropTypes.any),
    onCallback: PropTypes.func,
  };
  
  DRPicker.defaultProps = {
    initialSettings: {},
    onCallback: () => {},
  };
  
  function DateRangePicker({
    type,
    size,
    variant,
    value,
    initialSettings,
    onChange,
    placeholder,
    disabled,
  }) {
    const pickerRef = useRef();
    const { formatMessage } = useIntl();
    const { currentLanguage } = useContext(LangContext);
  
    const dateChange = (startDate, endDate) => {
      onChange([startDate.toDate(), endDate.toDate()]);
    };
  
    const onCallback = (startDate, endDate) => {
      dateChange(startDate, endDate);
    };
  
    const iSettings = {
      locale: {
        cancelLabel: formatMessage({ id: 'app.common.cancel' }),
        applyLabel: formatMessage({ id: 'app.common.apply' }),
        customRangeLabel: formatMessage({ id: 'app.common.customRange' }),
      },
      ranges: {
        /* [formatMessage({ id: 'app.common.today' })]: [moment(), moment()],
    [formatMessage({ id: 'app.common.yesterday' })]: [
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days'),
    ], */
        [formatMessage({ id: 'app.common.lastNDays' }, { n: 7 })]: [
          moment().subtract(6, 'days'),
          moment(),
        ],
        [formatMessage({ id: 'app.common.lastNDays' }, { n: 30 })]: [
          moment().subtract(29, 'days'),
          moment(),
        ],
        [formatMessage({ id: 'app.common.lastNDays' }, { n: 90 })]: [
          moment().subtract(89, 'days'),
          moment(),
        ],
        [formatMessage({ id: 'app.common.thisMonth' })]: [
          moment().startOf('month'),
          moment().endOf('month'),
        ],
        [formatMessage({ id: 'app.common.lastMonth' })]: [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        [formatMessage({ id: 'app.common.lastNMonths' }, { n: 3 })]: [
          moment().subtract(3, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      },
      timePicker24Hour: currentLanguage !== 'en',
      ...initialSettings,
      ...(initialSettings.initialDates
        ? {
            startDate: moment(initialSettings.initialDates.startDate),
            endDate: moment(initialSettings.initialDates.endDate),
          }
        : {}),
      ...(value
        ? {
            startDate: moment(value[0]),
            endDate: moment(value[1]),
          }
        : {}),
    };
  
    /* const onShow = () => {
      console.log('onShow');
      const prevArrow = document.querySelector('.daterangepicker .prev');
      const nextArrow = document.querySelector('.daterangepicker .next');
      prevArrow.innerHTML = defaults.prevArrow;
      nextArrow.innerHTML = defaults.nextArrow;
    }; */
  
    useEffect(() => {
      pickerRef.current.reload();
    }, [currentLanguage]);
  
    return (
      <DRPicker
        ref={pickerRef}
        initialSettings={iSettings}
        // onShow={onShow}
        onCallback={onCallback}
      >
        {type === 'input' ? (
          <Form.Control
            className="daterangepicker-custom-input"
            value={
              value
                ? `${moment(value[0]).format(
                    iSettings.timePicker
                      ? Constants.DateFormats.APP.Moment.CommonWithTime
                      : Constants.DateFormats.APP.Moment.Common
                  )} - ${moment(value[1]).format(
                    iSettings.timePicker
                      ? Constants.DateFormats.APP.Moment.CommonWithTime
                      : Constants.DateFormats.APP.Moment.Common
                  )}`
                : ''
            }
            placeholder={placeholder}
            readOnly
            disabled={disabled}
          />
        ) : (
          <Button
            size={size}
            variant={variant}
            className="dropdown-toggle"
            disabled={disabled}
          >
            <i className="bi-calendar-week" />
            <span className="ms-1">
              {value
                ? `${moment(value[0]).format(
                    iSettings.timePicker
                      ? Constants.DateFormats.APP.Moment.CommonWithTime
                      : Constants.DateFormats.APP.Moment.Common
                  )} - ${moment(value[1]).format(
                    iSettings.timePicker
                      ? Constants.DateFormats.APP.Moment.CommonWithTime
                      : Constants.DateFormats.APP.Moment.Common
                  )}`
                : ''}
            </span>
          </Button>
        )}
      </DRPicker>
    );
  }
  
  DateRangePicker.propTypes = {
    type: PropTypes.oneOf(['button', 'input']),
    size: PropTypes.oneOf(['sm', 'lg']),
    variant: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    ),
    onChange: PropTypes.func,
    initialSettings: PropTypes.objectOf(PropTypes.any),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  };
  
  DateRangePicker.defaultProps = {
    type: 'input',
    size: 'sm',
    variant: 'white',
    value: undefined,
    onChange: () => {},
    initialSettings: {},
    placeholder: '',
    disabled: false,
  };
  
  export default DateRangePicker;
  