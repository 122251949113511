export const Palette = [
    '#377dff',
    '#00c9db',
    '#fd7f6f',
    '#b2e061',
    '#bd7ebe',
    '#ffb55a',
    '#ffee65',
    '#beb9db',
    '#fdcce5',
    '#8bd3c7',
  ];
  