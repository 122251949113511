import React, { useRef, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useEffectOnce } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'react-bootstrap';
 import { yup } from 'lib/yup';
import { axiosApiInstance } from 'lib/axios';
import SmartForm from 'components/SmartForm/SmartForm';
import SmartImage from 'components/SmartImage';
import RequestResult from 'components/RequestResult';
import UnlockImage from '../../assets/images/svg/illustrations/oc-unlock.svg';
import UnlockImageLight from '../../assets/images/svg/illustrations-light/oc-unlock.svg';

const CODE_LENGTH = 4;

function VerifyCode() {
  const source = axios.CancelToken.source();

  const [email] = useState('asd@asd.com');
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const formRef = useRef();
  const codeInputRef = useRef();

  const fields = [
    {
      cols: [
        {
          key: 'verificationCode',
          type: 'code',
          options: {
            controller: {
              allowedCharacters: 'numeric',
              length: CODE_LENGTH,
              ref: codeInputRef,
            },
          },
          schema: yup.string().min(CODE_LENGTH).required(),
        },
      ],
    },
  ];

  const onRequestSuccess = (data) => {
    console.log(data);
  };

  const block = true;

  const resend = () => {
    formRef.current.returnToInitialState();
    codeInputRef.current.clear();

    if (block) {
      return;
    }

    setRequestError(null);
    setRequestLoading(true);

    axiosApiInstance
      .post(
        '/user/resend-verification',
        qs.stringify({
          email,
        }),
        {
          cancelToken: source.token,
        }
      )
      .then(({ data }) => {
        setRequestLoading(false);
        if (data.status) {
          console.log(data);
        } else {
          setRequestError(`errors.api.${data.Message}`);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setRequestError(`errors.network.${err.message}`);
          setRequestLoading(false);
        }
      });
  };

  useEffectOnce(() => () => {
    source.cancel('Component got unmounted');
  });

  return (
    <div
      className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
      style={{ maxWidth: '25rem' }}
    >
      <div className="text-center">
        <div className="mb-4">
          <SmartImage
            className="avatar avatar-xxl avatar-4x3"
            src={UnlockImage}
            darkSrc={UnlockImageLight}
          />
        </div>

        <h1 className="display-5">
          <FormattedMessage id="app.common.twoStepVerification" />
        </h1>

        <p className="mb-1">
          <FormattedMessage id="app.auth.verifyCode.sentEmailInfo" />
        </p>

        <p className="mb-5">
          <FormattedMessage id="app.auth.verifyCode.enterCodeInfo" />
        </p>
      </div>

      <RequestResult
        type="error"
        message={requestError}
        className="mb-5 mt-5 text-start"
      />

      <SmartForm
        ref={formRef}
        fields={fields}
        onRequestSuccess={onRequestSuccess}
        submitButtonText="verify"
      />

      <p className="d-flex justify-content-center align-items-center mt-4">
        <FormattedMessage id="app.common.didntReceiveACode" />
        <Button
          variant=""
          className="ms-1 p-0 fw-normal"
          disabled={requestLoading}
          onClick={resend}
        >
          {requestLoading ? (
            <>
              <Spinner animation="border" size="sm" className="me-1" />
              <FormattedMessage id="app.common.loading" />
            </>
          ) : (
            <FormattedMessage id="app.common.resend" />
          )}
        </Button>
      </p>
    </div>
  );
}

export default VerifyCode;
