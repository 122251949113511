import React from "react";
import PropTypes from "prop-types";
// import { useStoreState } from '@scena/react-store';
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
// import { $pointer, $rect, $selectedTool } from '../stores/stores';
import layerIcons from "../layerIcons";
import { LAYER_TYPES } from "../consts";

const icons = layerIcons.map((group) => {
  const section = { type: "icon", items: [] };
  section.items = group.items.map(({ id, Component }) => ({
    type: LAYER_TYPES.icon,
    id,
    titleKey: id,
    icon: Component,
    draggable: true,
  }));
  return section;
});

const defaultSections = [
  /* {
    type:"mode",
    items: [
      {
        id:"selectionMode",
        titleKey: 'selectionMode',
        tool: 'pointer',
        pointer: 'move',
        icon: 'bi-cursor',
      },
      {
        id: 'grabMode',
        titleKey: 'grabMode',
        tool: 'pointer',
        pointer: 'move',
        icon: 'bi-hand-index',
      },
      {
        id: 'scaleMode',
        titleKey: 'scaleMode',
        tool: 'pointer',
        pointer: 'scale',
        icon: 'bi-bounding-box',
      },
      {
        id: 'clipMode',
        titleKey: 'clipMode',
        tool: 'crop',
        pointer: 'move',
        icon: 'bi-crop',
      },
    ],
  }, */
  {
    type: "custom",
    items: [
      {
        type: LAYER_TYPES.booth,
        id: LAYER_TYPES.booth,
        titleKey: "booth",
        icon: "bi-bootstrap",
        bg: "bg-primary",
        style: { width: 32, height: 32, fontSize: "1.5rem" },
        draggable: true,
      },
      {
        type: LAYER_TYPES.carpet,
        id: LAYER_TYPES.carpet,
        titleKey: "carpet",
        icon: "bi-c-square",
        bg: "bg-dark-red",
        style: { width: 32, height: 32, fontSize: "1.5rem" },
        draggable: true,
      },
      {
        type: LAYER_TYPES.otherSpace,
        id: LAYER_TYPES.otherSpace,
        titleKey: "otherSpace",
        icon: "bi-slash-square",
        bg: "bg-success",
        style: { width: 32, height: 32, fontSize: "1.5rem" },
        draggable: true,
      },
      {
        type: LAYER_TYPES.blockSpace,
        id: LAYER_TYPES.blockSpace,
        titleKey: "blockSpace",
        icon: "bi-x-square",
        bg: "bg-danger",
        style: { width: 32, height: 32, fontSize: "1.5rem" },
        draggable: true,
      },
    ],
  },
  {
    type: "text",
    items: [
      {
        type: LAYER_TYPES.text,
        id: LAYER_TYPES.text,
        titleKey: "text",
        icon: "bi-fonts",
        style: { fontSize: "1.3rem" },
        draggable: true,
      },
    ],
  },
  {
    type: "shape",
    items: [
      /* {
        id: 'line',
        titleKey: 'line',
        icon: 'bi-slash-lg',
        draggable: true,
      }, */
      {
        type: LAYER_TYPES.rectangle,
        id: LAYER_TYPES.rectangle,
        titleKey: "rectangle",
        icon: "bi-square",
        draggable: true,
      },
      {
        type: LAYER_TYPES.circle,
        id: LAYER_TYPES.circle,
        titleKey: "circle",
        icon: "bi-circle",
        draggable: true,
      },
    ],
  },
  ...icons,
];

function LeftToolbar({ isRestricted, iconFillColor }) {
  /* const [selectedTool, setSelectedTool] = useStoreState($selectedTool);
  const [pointer, setPointer] = useStoreState($pointer);
  const [rect, setRect] = useStoreState($rect);

  const onSelect = (id) => {
    const flatten = sections.reduce((prev, v) => [...prev, ...v.items], []);
    const foundedItem = flatten.find((item) => item.id === id);

    if (!foundedItem) {
      return;
    }

    if (foundedItem.tool) {
      setSelectedTool(foundedItem.tool);
    }

    if (foundedItem.pointer) {
      setPointer(foundedItem.pointer);
    }

    if (foundedItem.rect) {
      setRect(foundedItem.rect);
    }
  }; */

  const sections = isRestricted
    ? [
        {
          type: "custom",
          items: [
            {
              type: LAYER_TYPES.booth,
              id: LAYER_TYPES.booth,
              titleKey: "booth",
              icon: "bi-bootstrap",
              bg: "bg-primary",
              style: { width: 32, height: 32, fontSize: "1.5rem" },
              draggable: true,
            },
          ],
        },
      ]
    : defaultSections;

  const onDragStart = (e, type, id) => {
    const size = 42;
    const dragTarget = e.currentTarget.cloneNode(true);
    if (
      type === LAYER_TYPES.rectangle ||
      type === LAYER_TYPES.circle ||
      type === LAYER_TYPES.text
    ) {
      const biSize = 14;
      const biIcon = dragTarget.querySelector("i");
      if (biIcon) {
        biIcon.style.position = "absolute";
        biIcon.style.display = "flex";
        biIcon.style.alignItems = "center";
        biIcon.style.justifyContent = "center";
        biIcon.style.backgroundColor = iconFillColor;
        biIcon.style.borderRadius = "50%";
        biIcon.style.width = `${size - biSize}px`;
        biIcon.style.height = `${size - biSize}px`;
        biIcon.style.top = `${(size - biSize * 2) / 2}px`;
        biIcon.style.left = `${(size - biSize * 2) / 2}px`;
      }
    }
    dragTarget.style.position = "absolute";
    dragTarget.style.pointerEvents = "none";
    dragTarget.style.width = `${size}px`;
    dragTarget.style.height = `${size}px`;
    dragTarget.style.top = "0";
    dragTarget.style.left = "0";
    document.body.appendChild(dragTarget);
    e.dataTransfer.setDragImage(dragTarget, size / 2, size / 2);

    setTimeout(() => {
      dragTarget.remove();
    });

    e.dataTransfer.clearData();
    e.dataTransfer.setData("text/plain", `sceneItem|${type}|${id}`);
    // e.dataTransfer.effectAllowed = 'copy';
  };

  const getNavLink = ({ id, type, draggable, icon: Icon, bg, style }) => (
    <Nav.Link
      bsPrefix="nav-icon"
      eventKey={id}
      // active={sectionIndex > 0 ? false : undefined}
      className={typeof Icon === "string" && "rounded"}
      active={false}
      style={draggable ? { cursor: "move" } : {}}
      title={null}
      onDragStart={
        draggable
          ? (e) => {
              onDragStart(e, type, id);
            }
          : undefined
      }
    >
      {typeof Icon === "string" ? (
        <div
          className={`d-flex align-items-center justify-content-center rounded ${bg}`}
          style={style || {}}
        >
          <i className={`${Icon} ${bg ? "text-light" : ""}`} />
        </div>
      ) : (
        <div style={{ transform: "scale(0.6)" }}>
          <Icon
            style={{ width: "100%", height: "100%", fill: iconFillColor }}
          />
        </div>
      )}
    </Nav.Link>
  );

  return (
    <>
      {sections.map((section, sectionIndex) => (
        <Nav
          key={`${sectionIndex.toString()}`}
          as="ul"
          className="flex-column nav-compact-icon nav-compact-icon-circle justify-content-center"
          // defaultActiveKey={section.items[0].id}
          // onSelect={onSelect}
        >
          {section.items.map((item, index) => (
            <Nav.Item key={`${index.toString()}`} as="li">
              {item.titleKey ? (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      <FormattedMessage
                        id={`app.${
                          item.type !== "icon"
                            ? "common"
                            : "helpers.floorPlans.icons"
                        }.${item.titleKey}`}
                      />
                    </Tooltip>
                  }
                >
                  {getNavLink(item)}
                </OverlayTrigger>
              ) : (
                getNavLink(item)
              )}
            </Nav.Item>
          ))}
          {sectionIndex < sections.length - 1 && (
            <hr className="hr w-100 my-1" />
          )}
        </Nav>
      ))}
    </>
  );
}

LeftToolbar.propTypes = {
  iconFillColor: PropTypes.string.isRequired,
  isRestricted: PropTypes.bool,
};

LeftToolbar.defaultProps = {
  isRestricted: false,
};

export default LeftToolbar;
